import React, { Fragment } from "react";
import { Hero, HomeContent } from "../components";

import { Grid, Container, withStyles, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NavBar, Footer, Loading } from "../components";
import CustomizedAccordions from '../components/FAQ';
import LandingCard from '../components/LandingCard';

//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import Alert from 'react-bootstrap/Alert'
import { useAuth0 } from "@auth0/auth0-react";
import styles from '../styles/Landing.module.css';

import {useTranslation} from "react-i18next";
const CustomButton = withStyles({
  root:{
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: 'var(--azulclaro)',
    color: 'var(--blanco)',
    height: '110px',
    borderRadius: '50px 50px 50px 50px',
    fontFamily: 'LucidaGrandeR',
    fontSize: '25px',
    bottom: '40px',
    '&:hover':{
      backgroundColor: '#0069d9',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  }
})(Button);
//Custom breakpoints
const theme = createMuiTheme({
  breakpoints: {
    values:{
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1250,
      xl: 1920,
    }
  },
})

const CallNowBtn = withStyles(theme => ({
  root:{
    marginLeft:'30px',
    backgroundColor: 'var(--azulPrincipal)',
    borderRadius: '42px',
    width: '294px',
    height: '84px',
    color: 'var(--blanco)',
    fontFamily: 'LucidaGrandeR',
    fontSize: '18px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    right: '30px',
    top: '15px',
    textDecoration: 'none',
    '&:hover':{
      backgroundColor: 'var(--azulPrincipal)',
    },
    "@media (max-width: 700px)":{
      width:'250px',
      height:'60px',
      fontSize:'15px',
      marginBottom:'30px'
    }
  }
}))(Button);

const Answer2 = () => {

  const problems = [ "Allergies", "Constipation","Cough", "Diarrhea", "Ear problems", "Fever", "Flu", "Headache", "Insect bites", "Nausea/vomiting", "Pinkeye", "Rash", "Respiratory problems", "Sore throats", "Urinary Tract Infections", "Vaginal infections and/or itching", "and more"]

  return(
    <ul
      style={{
        height: 150,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      <li>Allergies</li>
      <li>Constipation</li>
      <li>Cough</li>
      <li>Diarrhea</li>
      <li>Ear problems</li>
      <li>Fever</li>
      <li>Headache</li>
      <li>Insect bites</li>
      <li>Nausea/vomiting</li>
      <li>Pinkeye</li>
      <li>Rash</li>
      <li>Respiratory problems</li>
      <li>Sore throats</li>
      <li>Urinary Tract Infections</li>
      <li>Vaginal infections and/or itching</li>
      <li>and more</li>
    </ul>
  )
}



const Home = () => {
  const [t, i18n] =useTranslation("home");

	const { isLoading, isAuthenticated, getIdTokenClaims, user } = useAuth0();
	if (isLoading) {
	    return <Loading />;
	  }

	const showFaq = () => {
	    var acc = document.getElementsByClassName('accordion');
	    let i;
	    console.log(acc);
	    let len = acc.length;
	    for(i=0; i < len; i++){
	      acc[i].addEventListener('click', function(){
	        console.log('object');
	        acc.classList.toggle('active');
	        var panel = this.nextElementSibling;
	        if(panel.style.maxHeight){
	          panel.style.maxHeigth = null;
	        }else{
	          panel.style.maxHeigth = panel.scrollHeight + 'px'
	        }
	      })
	    }
	}

	return(
		      
    <main className={styles.principalHome}>
      <div className={styles.btnLogoutHome}><NavBar /></div>
      
      <Alert variant="warning" className={styles.alert}>{t("home.title.alert")}</Alert>
      <div className={styles.heroMobileContent}>
        <div className={styles.heroText}>
          {t("home.title")}  
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.heroDesktopContent}>
          <div className={styles.heroText}>
           {t("home.title")} 
          </div>
        </div>
      </div>
      <div className={styles.ourprocess}>
            <h1 className={styles.titleProcess}>{t("home.title.our.proces")}</h1>
            {/* <div className={styles.process}> */}
            <Grid container direction="row" justify="space-around">
              <Grid item className={styles.gridItem} xs={10} lg={3} md={6}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <img src="/icons/Proceso1-62x.png" width="230" height="230" alt=""/>
                  </Grid>
                  <Grid item>
                    <h1 className={styles.titleProcesses}>{t("home.subtitle.regist.platform")}</h1>
                  </Grid>
                  <Grid item className={styles.up} md={8} sm={8} g={10}>
                    <p>{t("home.cont.regist.platform")}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={styles.gridItem} xs={10} lg={3} md={6}>
                <Grid container direction="column" alignItems="center" >
                  <Grid item>
                    <img src="/icons/portapapeles.png" className={styles.portapapeles} width="65" height="70" alt=""/>
                  </Grid>
                  <Grid item>
                    <h1 className={styles.titleProcesses}>{t("home.subtitle.medical.form")}</h1>
                  </Grid>
                  <Grid item className={styles.parrafoProcess} md={8} sm={8} g={10}>
                    <p>{t("home.cont.medical.form")}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={styles.gridItem} xs={10} lg={3} md={6}>
                <Grid container direction="column" alignItems="center" >
                  <Grid item>
                    <img src="/icons/Proceso1-42x.png" width="230" height="230" alt=""/>
                  </Grid>
                  <Grid item>
                    <h1 className={styles.titleProcesses}>{t("home.subtitle.pay.request")}</h1>
                  </Grid>
                  <Grid item className={styles.parrafoProcess} md={8} sm={8} g={10}>
                    <p>{t("home.cont.pay.request")}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={styles.gridItem} xs={10} lg={3} md={6}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <img src="/icons/Proceso1-52x.png" width="230" height="230" alt=""/>
                  </Grid>
                  <Grid item>
                    <h1 className={styles.titleProcesses}>{t("home.subtitle.start.consultation")}</h1>
                  </Grid>
                  <Grid item className={styles.parrafoProcess} md={8} sm={8} g={10}>
                    <p>{t("home.cont.start.consultation")}</p>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            {/* </div> */}
          </div>

          <div className={styles.service}>
            <Grid item className={styles.conditions}>
              <LandingCard />
              <p className={styles.parrafo}>{t("home.cont.buy.home.terms")}<span style={{'font-weight': 'bold', 'color': '#E01C1C', margin:0}}>{t("home.cont.buy.home.911")}</span>{t("home.cont.buy.home.terms2")}</p>
            </Grid>
          </div>

          <div className={styles.aboutus}>
              <Grid container className={styles.aboutContainer} lg={12}>
                <h1>{t("home.subtitle.about")}</h1>
                <Grid item >
                  <p>{t("home.cont.about")}</p>
                  <img src="/girl.png" alt="doctorAvailable"  height="" className={styles.aboutUsGirl}/>
                </Grid>
              </Grid>
          </div>

          <div className={styles.faq}>
            <h1 className={styles.titulofaq}>{t("home.subtitle.faq")}</h1>
            <div className={styles.accordionContainer}>
              <CustomizedAccordions />
            </div>
          </div> 

          <Footer />
    </main>

  );
} 

export default Home;
