//Header
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./logout-button-2";
import {useTranslation} from "react-i18next";
import styles from '../styles/Header.module.css';

const Header = () => {
    const [t, i18n] = useTranslation("home");
    const [showWizard,updateView] = useState(false);
    const [linkShow,updateLinks] = useState( {
        register:{

        },
        payment:"",
        schedule:""
    })

    const location = useLocation();
    console.log(location.pathname);
    let newRoute = location.pathname

    const { isAuthenticated, logout, login } = useAuth0();

    const callLogout = () => {
        logout( `${window.location.origin}/`)
        //login();
    }

    useEffect(() => {
        if(newRoute == "/callback"){
            updateView(false)
        }
        else {
            updateView(true)
            console.log("ARRIVING ")
            if(newRoute == "/user/register"){
                updateLinks({
                    register:"",
                    payment:"isDisabled",
                    schedule:"isDisabled"
                })
            }
            if(newRoute == "/payment"){
                updateLinks({
                    register:"isDisabled",
                    payment:"",
                    schedule:"isDisabled"
                })       
            }
            if( newRoute == "/book"){
                updateLinks({
                    register:"isDisabled",
                    payment:"isDisabled",
                    schedule:""
                })                
            }
        }
    }, [newRoute])

    return ( 
        <div className={styles.headerpay}>
            
            <div className={styles.headerpayshadow}>
                <img src="/LogoBlanco.png" alt="logo"/>
                {(showWizard ? <div className={styles.hideBread}>
                    <h1 className={styles.title}>
                        <span>
                            <Link href="/user/register" className={styles.btnProcess}>
                                <a className={linkShow.register}>{t("header.title.Register")}</a>
                            </Link>
                        </span> &nbsp;  {">"} &nbsp;
                        <span>
                            <Link href="/payment" className={styles.btnProcess}>
                                <a className={linkShow.payment}>{t("header.title.Payment")}</a>
                            </Link>
                        </span> &nbsp;  {">"} &nbsp;
                        <span>
                            <Link href="/book" className={styles.btnProcess}>
                                <a className={linkShow.schedule}>{t("header.title.Schedule_call")}</a>
                            </Link>
                        </span>
                    </h1>
                </div>
                : null)}
                <LogoutButton />
                <div className={styles.trad}>
                    <button onClick={() => i18n.changeLanguage("es")} >ESPAÑOL</button>
                    <button onClick={() => i18n.changeLanguage("en")} >ENGLISH</button> 
                </div>
                
                <div className={styles.showBread}>
                    {(() => {
                        if (window.location.href.indexOf("register") > -1){
                            return(
                                <div><span>{t("header.title.Register")}</span> > {t("header.title.Payment")} > {t("header.title.Schedule_call")}</div>
                            )
                        }
                        if (window.location.href.indexOf("payment") > -1){
                            return(
                                <div>{t("header.title.Register")} > <span>{t("header.title.Payment")}</span> > {t("header.title.Schedule_call")}</div>
                            )
                        }
                        if (window.location.href.indexOf("book") > -1){
                            return(
                                <div>{t("header.title.Register")} > {t("header.title.Payment")} > <span>{t("header.title.Schedule_call")}</span></div>
                            )
                        }
                    })()}
                </div>
            
            </div>
        </div>
     );
}
 
export default Header;