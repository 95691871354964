import React,{ useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BasicInfo from '../components/BasicInfo';

import styles from '../styles/Service.module.css';
import CreateIcon from '@material-ui/icons/Create';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Grid, Button, AppBar, Toolbar, Drawer, TextField, Checkbox, FormControlLabel} from '@material-ui/core';
import { fetchMedInfoDoctor, fetchUserInfoDoctor, saveBasicInfoDataDoctor } from '../utils/apiCalls';
import { useAuth0 } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import { NavBar, Footer, Loading } from "../components";

const querystring = window.location.search;
const params = new URLSearchParams(querystring);
const userType = params.get('userType');


function Service() {
    const [t, i18n] =useTranslation("home");
    const { isAuthenticated, logout, login } = useAuth0;
    const [userData, setUserData] = useState();
    const [userMedInfo, setUserMedInfo ] = useState();
    const [linkShow,updateLink] = useState({
        link:"/",
        type:""
    })

    const callLogout = () => {
        logout( `${window.location.origin}/`)
    }

    // useEffect(() => {
    //     if (!isAuthenticated) {
    //         router.push('/');
    //       }
    //     console.log("LET SEE =>",auth)
    //     let user = router.query
    //     if(user.userType=="admin"){
    //         updateLink({link:"/history-a",type:"admin"})
    //     }
    //     else{
    //         updateLink({link:"history-b",type:"doctor"})
    //     }
    // }, [])
    //const url = `https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user/med-case/${med_case_id}`;
    const [medCaseInfo, setMedCaseInfo] = useState({
        appointmentDate: '',
        callDuration: '',
        // doctorId: '',
        // id: '',
        // order_id: '',
        // pin_code: '',
        // update: ''
    })


    //Obtenemos los datos del query string
    const querystring = window.location.search
    console.log(querystring)

    // usando el querystring, creamos un objeto del tipo URLSearchParams
    const params = new URLSearchParams(querystring)


    const [chiefComplaints, setChiefComplaints] = useState({
        titleC: '',
        descriptionC: '',
        titleC2: '',
        descriptionC2: '',
        titleC3: '',
        descriptionC3: ''
    })
    
    const [addComplaint2, setAddComplaint2] = useState(false);
    const [addComplaint3, setAddComplaint3] = useState(false);

    const [historyIllness, setHistoryIllness] = useState({
        titleH: '',
        descriptionH: '',
        titleH2: '',
        descriptionH2: '',
        titleH3: '',
        descriptionH3: ''
    })

    const [addHistory2, setAddHistory2] = useState(false);
    const [addHistory3, setAddHistory3] = useState(false);

    const [vitalSigns, setVitalSigns] = useState('');

    const [subjectiveInfo, setSubjectiveInfo] = useState({
        titleS: '',
        descriptionS: '',
        titleS2: '',
        descriptionS2: '',
        titleS3: '',
        descriptionS3: ''
    })

    const [addSubjective2, setAddSubjective2] = useState(false);
    const [addSubjective3, setAddSubjective3] = useState(false);

    const [objectiveInfo, setObjectiveInfo] = useState({
        titleO: '',
        descriptionO: '',
        titleO2: '',
        descriptionO2: '',
        titleO3: '',
        descriptionO3: ''
    })

    const [addObjective2, setAddObjective2] = useState(false);
    const [addObjective3, setAddObjective3] = useState(false);

    const [assessmentInfo, setAssessmentInfo] = useState({
        titleA: '',
        descriptionA: '',
        titleA2: '',
        descriptionA2: '',
        titleA3: '',
        descriptionA3: ''
    })

    const [addAssessment2, setAddAssessment2] = useState(false);
    const [addAssessment3, setAddAssessment3] = useState(false);

    const [planInfo, setPlanInfo] = useState({
        titleP: '',
        descriptionP: '',
        titleP2: '',
        descriptionP2: '',
        titleP3: '',
        descriptionP3: ''
    })

    const [addPlan2, setAddPlan2] = useState(false);
    const [addPlan3, setAddPlan3] = useState(false);

    const [nonPrescriptionInfo, setNonPrescriptionInfo] = useState({
        providedNon: false,
        nameNon: '',
        strengthNon: '',
        sigNon: '',
        typeNon: '',
        name2Non: '',
        strength2Non: '',
        sig2Non: '',
        type2Non: '',
        name3Non: '',
        strength3Non: '',
        sig3Non: '',
        type3Non: '',
        name4Non: '',
        strength4Non: '',
        sig4Non: '',
        type4Non: '',
        name5Non: '',
        strength5Non: '',
        sig5Non: '',
        type5Non: ''
    })

    const [showNon, setShowNon] = useState(false);

    const [addNon2, setAddNon2] = useState(false);
    const [addNon3, setAddNon3] = useState(false);
    const [addNon4, setAddNon4] = useState(false);
    const [addNon5, setAddNon5] = useState(false);


    const [prescriptionInfo, setPrescriptionInfo] = useState({
        provided: false,
        pharmacy: '',
        instructions: '',
        name: '',
        strength: '',
        sig: '',
        type: '',
        quantity: '',
        unit: '',
        refills: '',
        diagnosis: '',
        date: '',
        dispense: '',
        name2: '',
        strength2: '',
        sig2: '',
        type2: '',
        quantity2: '',
        unit2: '',
        refills2: '',
        diagnosis2: '',
        date2: '',
        dispense2: '',
        name3: '',
        strength3: '',
        sig3: '',
        type3: '',
        quantity3: '',
        unit3: '',
        refills3: '',
        diagnosis3: '',
        date3: '',
        dispense3: '',
        name4: '',
        strength4: '',
        sig4: '',
        type4: '',
        quantity4: '',
        unit4: '',
        refills4: '',
        diagnosis4: '',
        date4: '',
        dispense4: '',
        name5: '',
        strength5: '',
        sig5: '',
        type5: '',
        quantity5: '',
        unit5: '',
        refills5: '',
        diagnosis5: '',
        date5: '',
        dispense5: ''
    })

    const [veracity, setVeracity] = useState({veracity_checked: false});


    const [showPres, setShowPres] = useState(false);

    const [addPres2, setAddPres2] = useState(false);
    const [addPres3, setAddPres3] = useState(false);
    const [addPres4, setAddPres4] = useState(false);
    const [addPres5, setAddPres5] = useState(false);

    const [showAlert, setShowAlert] = useState(false);



    const handleComplaints = (e) => {
        setChiefComplaints({
            ...chiefComplaints,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, chiefComplaints)
    }

    const handleHistory = (e) => {
        setHistoryIllness({
            ...historyIllness,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, historyIllness)
    }

    const handleVital = (e) => {
        setVitalSigns(e.target.value)
        console.log("HANDLING ==>",e.target.value, vitalSigns)
    }

    const handleSubjective = (e) => {
        setSubjectiveInfo({
            ...subjectiveInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, subjectiveInfo)
    }

    const handleObjective = (e) => {
        setObjectiveInfo({
            ...objectiveInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, objectiveInfo)
    }

    const handleAssessment = (e) => {
        setAssessmentInfo({
            ...assessmentInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, assessmentInfo)
    }

    const handlePlan = (e) => {
        setPlanInfo({
            ...planInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, planInfo)
    }

    const handleNonPres = (e) => {
        setNonPrescriptionInfo({
            ...nonPrescriptionInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, nonPrescriptionInfo)
    }
    const handlePres = (e) => {
        setPrescriptionInfo({
            ...prescriptionInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, prescriptionInfo)
    }

    const handleMedInfo = (e) => {
        setMedCaseInfo({
            ...medCaseInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value,medCaseInfo)
    }

    const handleVeracity = e => {
        setVeracity({[e.target.name]:e.target.checked})
        setShowAlert(false)
        console.log("HANDLING ==> veracity",e.target.value,veracity)
    }
    var date = new Date();
    const finishService = e => {
        if (veracity.veracity_checked==false){
            setShowAlert(true)
        }
        else {
            console.log("Veracity True")
            let responseMedInfo = {
                appointment_date: date.toISOString(),
                call_duration: medCaseInfo.callDuration,
                chief_complaints: {
                    titleC: chiefComplaints.titleC,
                    descriptionC: chiefComplaints.descriptionC,
                    titleC2: chiefComplaints.titleC2,
                    descriptionC2: chiefComplaints.descriptionC2,
                    titleC3: chiefComplaints.titleC3,
                    descriptionC3: chiefComplaints.descriptionC3
                },
                history_of_present_illness: {
                    titleH: historyIllness.titleH,
                    descriptionH: historyIllness.descriptionH,
                    titleH2: historyIllness.titleH2,
                    descriptionH2: historyIllness.descriptionH2,
                    titleH3: historyIllness.titleH3,
                    descriptionH3: historyIllness.descriptionH3
                },
                //id: params.get('id'),
                //order_id: params.get('order_id'),
                //pin_code: params.get('pin_code'),
                prescription: {
                    providedNon: nonPrescriptionInfo.providedNon,
                    nameNon: nonPrescriptionInfo.nameNon,
                    strengthNon: nonPrescriptionInfo.strengthNo,
                    sigNon: nonPrescriptionInfo.sigNon,
                    typeNon: nonPrescriptionInfo.typeNon,
                    name2Non: nonPrescriptionInfo.name2Non,
                    strength2Non: nonPrescriptionInfo.strength2Non,
                    sig2Non: nonPrescriptionInfo.sig2Non,
                    type2Non: nonPrescriptionInfo.type2Non,
                    name3Non: nonPrescriptionInfo.name3Non,
                    strength3Non: nonPrescriptionInfo.strength3Non,
                    sig3Non: nonPrescriptionInfo.sig3Non,
                    type3Non: nonPrescriptionInfo.type3Non,
                    name4Non: nonPrescriptionInfo.name4Non,
                    strength4Non: nonPrescriptionInfo.strength4Non,
                    sig4Non: nonPrescriptionInfo.sig4Non,
                    type4Non: nonPrescriptionInfo.type4Non,
                    name5Non: nonPrescriptionInfo.name5Non,
                    strength5Non: nonPrescriptionInfo.strength5Non,
                    sig5Non: nonPrescriptionInfo.sig5Non,
                    type5Non: nonPrescriptionInfo.type5Non,
                    provided: prescriptionInfo.provided,
                    pharmacy: prescriptionInfo.pharmacy,
                    instructions: prescriptionInfo.instructions,
                    name: prescriptionInfo.name,
                    strength: prescriptionInfo.strength,
                    sig: prescriptionInfo.sig,
                    type: prescriptionInfo.type,
                    quantity: prescriptionInfo.quantity,
                    unit: prescriptionInfo.unit,
                    refills: prescriptionInfo.refills,
                    diagnosis: prescriptionInfo.diagnosis,
                    date: prescriptionInfo.date,
                    dispense: prescriptionInfo.dispense,
                    name2: prescriptionInfo.name2,
                    strength2: prescriptionInfo.strength2,
                    sig2: prescriptionInfo.sig2,
                    type2: prescriptionInfo.type2,
                    quantity2: prescriptionInfo.quantity2,
                    unit2: prescriptionInfo.unit2,
                    refills2: prescriptionInfo.refills2,
                    diagnosis2: prescriptionInfo.diagnsosis2,
                    date2: prescriptionInfo.date2,
                    dispense2: prescriptionInfo.dispense2,
                    name3: prescriptionInfo.name3,
                    strength3: prescriptionInfo.strength3,
                    sig3: prescriptionInfo.sig3,
                    type3: prescriptionInfo.type3,
                    quantity3: prescriptionInfo.quantity3,
                    unit3: prescriptionInfo.unit3,
                    refills3: prescriptionInfo.refills3,
                    diagnosis3: prescriptionInfo.diagnosis3,
                    date3: prescriptionInfo.date3,
                    dispense3: prescriptionInfo.dispense3,
                    name4: prescriptionInfo.name3,
                    strength4: prescriptionInfo.strength4,
                    sig4: prescriptionInfo.sig4,
                    type4: prescriptionInfo.type4,
                    quantity4: prescriptionInfo.quantity4,
                    unit4: prescriptionInfo.unit4,
                    refills4: prescriptionInfo.refills4,
                    diagnosis4: prescriptionInfo.diagnosis4,
                    date4: prescriptionInfo.date4,
                    dispense4: prescriptionInfo.dispense4,
                    name5: prescriptionInfo.name5,
                    strength5: prescriptionInfo.strength5,
                    sig5: prescriptionInfo.sig5,
                    type5: prescriptionInfo.type5,
                    quantity5: prescriptionInfo.quantity5,
                    unit5: prescriptionInfo.unit5,
                    refills5: prescriptionInfo.refills5,
                    diagnosis5: prescriptionInfo.diagnosis5,
                    date5: prescriptionInfo.date5,
                    dispense5: prescriptionInfo.dispense5

                },
                soap: {
                    titleS: subjectiveInfo.titleS,
                    descriptionS: subjectiveInfo.descriptionS,
                    titleS2: subjectiveInfo.titleS2,
                    descriptionS2: subjectiveInfo.descriptionS2,
                    titleS3: subjectiveInfo.titleS3,
                    descriptionS3: subjectiveInfo.descriptionS3,
                    titleO: objectiveInfo.titleO,
                    descriptionO: objectiveInfo.descriptionO,
                    titleO2: objectiveInfo.titleO2,
                    descriptionO2: objectiveInfo.descriptionO2,
                    titleO3: objectiveInfo.titleO3,
                    descriptionO3: objectiveInfo.descriptionO3,
                    titleA: assessmentInfo.titleA,
                    descriptionA: assessmentInfo.descriptionA,
                    titleA2: assessmentInfo.titleA2,
                    descriptionA2: assessmentInfo.descriptionA2,
                    titleA3: assessmentInfo.titleA3,
                    descriptionA3: assessmentInfo.descriptionA3,
                    titleP: planInfo.titleP,
                    descriptionP: planInfo.descriptionP,
                    titleP2: planInfo.titleP2,
                    descriptionP2: planInfo.descriptionP2,
                    titleP3: planInfo.titleP3,
                    descriptionP3: planInfo.descriptionP3
                },
                // status: {},
                // updated_at: ,
                doctor_id: params.get('doctor_id'),
                status: "FINISHED",
                vital_signs: {
                    vital: vitalSigns
                }
                
            }
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            fetch('https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/cases/'+params.get('id'),{
                method:'PATCH',
                headers:{
                    Authorization: `Bearer ${storedSession.idToken}`,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                },
                body:JSON.stringify(responseMedInfo)
            })
            .then( response => response.json())
            .then(result =>{
                console.log("Updated Service", result)
                window.location.href="/doctor";
            })
            .catch(error =>{
                console.log("ERROR ?",error)
            })
        }
    }

    function changeStatusText(elementA, elementB, btn){
        elementA.toggleAttribute('readOnly');
        elementB.toggleAttribute('readOnly');
        if (btn.innerHTML === "+") {
            btn.style.backgroundColor = "#CC1F55";
            btn.innerHTML = "Edit";
        } else {
            btn.style.backgroundColor = "#3C9946";
            btn.innerHTML = "+";
        }
    }

    function changeStatusNon(ParentNon, btn){
        var node = ParentNon.querySelectorAll("input");
        for (var i=0; i<node.length; i++){
            node[i].toggleAttribute('readOnly');
        }
        if (btn.innerHTML === "+") {
            btn.style.backgroundColor = "#CC1F55";
            btn.innerHTML = "Edit";
        } else {
            btn.style.backgroundColor = "#3C9946";
            btn.innerHTML = "+";
        }
    }

    const saveService = e => {
        e.preventDefault();
        var btnclic = document.getElementById(e.target.id);
        switch(e.target.id){
            case "Complaint2":
                changeStatusText(document.getElementById('inputComplaint'), document.getElementById('textComplaint'), btnclic)
                setAddComplaint2(true);
            break;
            case "Complaint3":
                changeStatusText(document.getElementById('inputComplaint2'), document.getElementById('textComplaint2'), btnclic)
                setAddComplaint3(true);
            break;
            case "Complaint4":
                changeStatusText(document.getElementById('inputComplaint3'), document.getElementById('textComplaint3'), btnclic)
            break;
            case "Historybutton":
                changeStatusText(document.getElementById('Historyinput'), document.getElementById('Historytext'), btnclic)
                setAddHistory2(true);
            break;
            case "Historybutton2":
                changeStatusText(document.getElementById('Historyinput2'), document.getElementById('Historytext2'), btnclic)
                setAddHistory3(true);
            break;
            case "Historybutton3":
                changeStatusText(document.getElementById('Historyinput3'), document.getElementById('Historytext3'), btnclic)
                setAddHistory3(true);
            break;
            case "subjetivebutton":
                changeStatusText(document.getElementById('subjetiveinput'), document.getElementById('subjetivetext'), btnclic)
                setAddSubjective2(true);
            break;
            case "subjetivebutton2":
                changeStatusText(document.getElementById('subjetiveinput2'), document.getElementById('subjetivetext2'), btnclic)
                setAddSubjective3(true)
            break;
            case "subjetivebutton3":
                changeStatusText(document.getElementById('subjetiveinput3'), document.getElementById('subjetivetext3'), btnclic)
                setAddSubjective3(true)
            break;
            case "objetivebutton":
                changeStatusText(document.getElementById('objetiveinput'), document.getElementById('objetivetext'), btnclic)
                setAddObjective2(true);
            break;
            case "objetivebutton2":
                changeStatusText(document.getElementById('objetiveinput2'), document.getElementById('objetivetext2'), btnclic)
                setAddObjective3(true)
            break;
            case "objetivebutton3":
                changeStatusText(document.getElementById('objetiveinput3'), document.getElementById('objetivetext3'), btnclic)
                setAddObjective3(true)
            break;
            case "Assessmentbutton":
                changeStatusText(document.getElementById('Assessmentinput'), document.getElementById('Assessmenttext'), btnclic)
                setAddAssessment2(true);
            break;
            case "Assessmentbutton2":
                changeStatusText(document.getElementById('Assessmentinput2'), document.getElementById('Assessmenttext2'), btnclic)
                setAddAssessment3(true)
            break;
            case "Assessmentbutton3":
                changeStatusText(document.getElementById('Assessmentinput3'), document.getElementById('Assessmenttext3'), btnclic)
                setAddAssessment3(true)
            break;
            case "Planbutton":
                changeStatusText(document.getElementById('Planinput'), document.getElementById('Plantext'), btnclic)
                setAddPlan2(true);
            break;
            case "Planbutton2":
                changeStatusText(document.getElementById('Planinput2'), document.getElementById('Plantext2'), btnclic)
                setAddPlan3(true)
            break;
            case "Planbutton3":
                changeStatusText(document.getElementById('Planinput3'), document.getElementById('Plantext3'), btnclic)
                setAddPlan3(true)
            break;
            case "Nonbutton":
                changeStatusNon(document.getElementById('noncontainer'), btnclic)
                setAddNon2(true)
            break;
            case "Nonbutton2":
                changeStatusNon(document.getElementById('noncontainer2'), btnclic)
                setAddNon3(true)
            break;
            case "Nonbutton3":
                changeStatusNon(document.getElementById('noncontainer3'), btnclic)
                setAddNon4(true)
            break;
            case "Nonbutton4":
                changeStatusNon(document.getElementById('noncontainer4'), btnclic)
                setAddNon5(true)
            break;
            case "Nonbutton5":
                changeStatusNon(document.getElementById('noncontainer5'), btnclic)
                setAddNon5(true)
            break;
            case "prescriptionbutton":
                changeStatusNon(document.getElementById('prescriptioncontainer'), btnclic)
                setAddPres2(true)
            break;
            case "prescriptionbutton2":
                changeStatusNon(document.getElementById('prescriptioncontainer2'), btnclic)
                setAddPres3(true)
            break;
            case "prescriptionbutton3":
                changeStatusNon(document.getElementById('prescriptioncontainer3'), btnclic)
                setAddPres4(true)
            break;
            case "prescriptionbutton4":
                changeStatusNon(document.getElementById('prescriptioncontainer4'), btnclic)
                setAddPres5(true)
            break;
            case "prescriptionbutton5":
                changeStatusNon(document.getElementById('prescriptioncontainer5'), btnclic)
                setAddPres5(true)
            break;
        }
    }

    
    let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));

    useEffect(() => {
        // if(!isAuthenticated){
        //     router.push('/');
            
        // }
        if(false){
            window.location.href="/";
            
        }
        else{

            // Token
            // let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            try {
                console.log("querystring: ", params.get('pid'));

                fetchUserInfoDoctor(storedSession.idToken, params.get('pid')).then((userData) => {
                    setUserData(userData.patient_data)
                    console.log("user data")
                    console.log(userData.patient_data)
                })

                fetchMedInfoDoctor(storedSession.idToken, params.get('pid')).then((userMedInfo) => {
                    setUserMedInfo(userMedInfo.patient_medical_data)
                    console.log("user med info")
                    console.log(userMedInfo.patient_medical_data)
                    console.log("pharmacy:", userMedInfo.patient_medical_data.reason.pharmacy.label)
                })
                 
            } catch (error) {
                console.log("ERROR", error);
            }
        }
    },[ ]);

    const[pathHistory, setPathHistory] = useState();
    useEffect(() => {
        console.log("USER TYPE ==>",userType)
        // let user = JSON.parse(localStorage.getItem("user_info"))
        // let userData = user.user;
        // let userType = userData.user_type
        if (userType == "admin"){
            setPathHistory("/history-a")
            updateLink({link:"/history-a",type:"admin"})
        }
        else {
            setPathHistory("/history-b")
            updateLink({link:"/history-b",type:"doctor"})
        }
    }, [])

    let userinfo = JSON.parse(localStorage.getItem('user_info'));
    let btnOrigin = {
        Complaint2: 'Complaint2',
        Complaint3: 'Complaint3'
    }
    const last_name = userinfo.user.first_name + " " + userinfo.user.last_name;
    
    

    return (
        <>
            { userData === undefined ? (
                <Loading />
            ) : (
                <>
                    <AppBar position="static" color="transparent" elevation={0} className={styles.blueAppBar}>
                        <Toolbar className={styles.blueToolBar}>
                            <Link to={{pathname:"/doctor",query:{userType:linkShow.type}}} >
                                <a  className={`${styles.link} ${styles.callHistoryButton}`}>{t('header.2.cont.waiting')} </a>
                            </Link>
                            <Link to={{pathname:pathHistory}} >
                                <a  className={`${styles.link} ${styles.callHistoryButton}`}>{t('header.2.cont.history')}</a>
                            </Link>
                            <span className={`${styles.logoutMobile} ${styles.logout}`}>
                            <ExitToAppIcon style={{ fontSize: 30 }}                             
                                    onClick={() =>
                                        logout({
                                        returnTo: window.location.origin,
                                        })
                                    }/>
                                {/* <a title="Exit"  onClick={() => callLogout()}><ExitToAppIcon className={styles.logoutIcon} style={{ fontSize: 45}}/></a> */}
                            </span>
                        </Toolbar>
                    </AppBar>

                    <Drawer
                        variant="permanent"
                        anchor="left"
                        classes={{
                            paper: styles.blueDrawer,
                        }}
                    >
                        <img src="/LogoBlanco.png" alt="Meditel Logo" className={styles.meditelWhiteLogo}/>
                        {/*<div className={styles.userImage}><img src="/User.png" alt= "User"/></div>*/}
                    </Drawer>

                    <div className={styles.mainContainer}> 

                        {userData && userMedInfo && <BasicInfo userinfo={userData} userMedInfo={userMedInfo} section={"service"} saveBasicInfoData={(userinfo, userMedInfo) => {
                                saveBasicInfoDataDoctor( userinfo, userMedInfo, storedSession.idToken, 83 )
                        }} />}

                        <div className={`${styles.hxTitle} ${styles.notesTitle}`}>{t('service.title.notes')}</div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <div className={styles.subtitle}>{t('service.subtitle.cheif')}</div>
                                <div className={styles.noteMetadata}>{t('service.cont.dr')} {`${last_name}`}</div>
                                <input id="inputComplaint" name="titleC" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleComplaints}/>
                                <TextField id="textComplaint" name="descriptionC" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleComplaints}></TextField>
                                <button id="Complaint2" className={styles.saveNoteButton} 
                                    onClick={saveService}>+</button>
                            </Grid>
                            {addComplaint2 ? (
                                <Grid item xs={12} md={6}>
                                    <div className={styles.extra}></div>
                                    <input id="inputComplaint2" name="titleC2" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleComplaints}/>
                                    <TextField id="textComplaint2" name="descriptionC2" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleComplaints}></TextField>
                                    <button id="Complaint3" className={styles.saveNoteButton} 
                                    onClick={saveService}>+</button>
                                </Grid>
                            ) : null}
                            {addComplaint3 ? (
                                <Grid item xs={12} md={6}>
                                    <div className={styles.extra}></div>
                                    <input id="inputComplaint3" name="titleC3" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleComplaints}/>
                                    <TextField id="textComplaint3" name="descriptionC3" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleComplaints}></TextField>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} md={6}>
                                <div className={styles.subtitle}>{t('service.subtitle.history')}</div>
                                <div className={styles.noteMetadata}>{t('service.cont.dr')}Dr. {`${last_name}`}</div>
                                <input id="Historyinput" name="titleH" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleHistory}/>
                                <TextField id="Historytext" name="descriptionH" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleHistory}></TextField>
                                <button id="Historybutton" className={styles.saveNoteButton} 
                                onClick={saveService}>+</button>
                            </Grid>
                            {addHistory2 ? (
                                <Grid item xs={12} md={6}>
                                <div className={styles.extra}></div>
                                <input id="Historyinput2" name="titleH2" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleHistory}/>
                                <TextField id="Historytext2" name="descriptionH2" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleHistory}></TextField>
                                <button id="Historybutton2" className={styles.saveNoteButton} 
                                onClick={saveService}>+</button>
                                </Grid>
                            ) : null}
                            {addHistory3 ? (
                                <Grid item xs={12} md={6}>
                                <div className={styles.extra}></div>
                                <input id="Historytext3" name="titleH3" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleHistory}/>
                                <TextField id="Historytext3" name="descriptionH3" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleHistory}></TextField>
                                </Grid>
                            ) : null}
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <div className={styles.subtitle}>{t('service.cont.subjective')}</div>
                                <div className={styles.noteMetadata}>{t('service.cont.dr')} {`${last_name}`}.</div>
                                <input id="subjetiveinput" name="titleS" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleSubjective}/>
                                <TextField id="subjetivetext" name="descriptionS" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleSubjective}></TextField>
                                <button id="subjetivebutton" className={styles.saveNoteButton} onClick={saveService}>+</button>
                            </Grid>
                            {addSubjective2 ? (
                                <Grid item xs={12} md={6}>
                                    <div className={styles.extra}></div>
                                    <input id="subjetiveinput2" name="titleS2" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleSubjective}/>
                                    <TextField id="subjetivetext2" name="descriptionS2" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleSubjective}></TextField>
                                    <button id="subjetivebutton2" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                </Grid>
                            ) : null}
                            {addSubjective3 ? (
                                <Grid item xs={12} md={6}>
                                    <div className={styles.extra}></div>
                                    <input id="subjetiveinput3" name="titleS3" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleSubjective}/>
                                    <TextField id="subjetivetext3" name="descriptionS3" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleSubjective}></TextField>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} md={6}>
                                <div className={styles.subtitle}>{t('service.cont.objective')}</div>
                                <div className={styles.noteMetadata}>{t('service.cont.dr')} {`${last_name}`}</div>
                                <input id="objetiveinput" name="titleO" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleObjective}/>
                                <TextField id="objetivetext" name="descriptionO" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleObjective}></TextField>
                                <button id="objetivebutton" className={styles.saveNoteButton} onClick={saveService}>+</button>
                            </Grid>
                            {addObjective2 ? (
                                <Grid item xs={12} md={6}>
                                    <div className={styles.extra}></div>
                                    <input id="objetiveinput2" name="titleO2" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleObjective}/>
                                    <TextField id="objetivetext2" name="descriptionO2" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleObjective}></TextField>
                                    <button id="objetivebutton2" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                </Grid>
                            ) : null}
                            {addObjective3 ? (
                                <Grid item xs={12} md={6}>
                                    <div className={styles.extra}></div>
                                    <input id="objetiveinput3" name="titleO3" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleObjective}/>
                                    <TextField id="objetivetext3" name="descriptionO3" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleObjective}></TextField>
                                </Grid>
                            ) : null}
                        </Grid>

                        <div style={{ marginTop: "3vh"}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.subtitle}>{t('service.cont.assessment')}</div>
                                    <div className={styles.noteMetadata}>{t('service.cont.dr')} {`${last_name}`}</div>
                                    <input id="Assessmentinput" name="titleA" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleAssessment}/>
                                    <TextField id="Assessmenttext" name="descriptionA" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleAssessment}></TextField>
                                    <button id="Assessmentbutton" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                </Grid>
                                {addAssessment2 ? (
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.extra}></div>
                                        <input id="Assessmentinput2" name="titleA2" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleAssessment}/>
                                        <TextField id="Assessmenttext2" name="descriptionA2" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleAssessment}></TextField>
                                        <button id="Assessmentbutton2" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </Grid>
                                ) : null}
                                {addAssessment3 ? (
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.extra}></div>
                                        <input id="Assessmentinput3" name="titleA3" placeholder="Type in title" className={styles.titleNoteInput} onChange={handleAssessment}/>
                                        <TextField id="Assessmenttext3" name="descriptionA3" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handleAssessment}></TextField>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} md={6}>
                                    <div className={styles.subtitle}>{t('service.cont.plan')}</div>
                                    <div className={styles.noteMetadata}>{t('service.cont.dr')} {`${last_name}`}</div>
                                    <input id="Planinput" name="titleP" placeholder="Type in title" className={styles.titleNoteInput} onChange={handlePlan}/>
                                    <TextField id="Plantext" name="descriptionP" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handlePlan}></TextField>
                                    <button id="Planbutton" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                </Grid>
                                {addPlan2 ? (
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.extra}></div>
                                        <input id="Planinput2" name="titleP2" placeholder="Type in title" className={styles.titleNoteInput} onChange={handlePlan}/>
                                        <TextField id="Plantext2" name="descriptionP2" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handlePlan}></TextField>
                                        <button id="Planbutton2" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </Grid>
                                ) : null}
                                {addPlan3 ? (
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.extra}></div>
                                        <input id="Planinput3" name="titleP3" placeholder="Type in title" className={styles.titleNoteInput} onChange={handlePlan}/>
                                        <TextField id="Plantext3" name="descriptionP3" label="Description" multiline rows={4} variant={"outlined"} fullWidth onChange={handlePlan}></TextField>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </div>

                        <div className={styles.vitalSignsContainer}>
                            <div className={styles.subtitle} style={{ marginRight: "1vw" }}>{t('service.cont.vital.sings')}</div>
                            <div className={styles.vitalSignsRadioButtonDiv}>
                                    <input type="radio" value="No" name="vitalSigns" className={styles.vitalRadio} onClick={handleVital}/>
                                    <label for="No" className={styles.labelradio}>{t('service.option.vital.1')}</label>
                            </div>
                            <div className={styles.vitalSignsRadioButtonDiv}>
                                    <input type="radio" value="Yes" name="vitalSigns" className={styles.vitalRadio} onClick={handleVital}/>
                                    <label for="Yes" className={styles.labelradio}>{t('service.option.vital.2')}</label>
                            </div>
                            <div className={styles.vitalSignsRadioButtonDiv}>
                                    <input type="radio" value="NotApplicable" name="vitalSigns" className={styles.vitalRadio} onClick={handleVital}/>
                                    <label for="Notapplicable" className={styles.labelradio}>{t('service.option.vital.3')}</label>
                            </div>
                        </div>
                        
                        <div className={styles.nonPrescriptionContainer}>

                            <Grid container style={{ alignItems: "flex-end" }} spacing={3}>
                                <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center"}}>
                                    <div className={styles.infotitleM}>{t('service.cont.non.prescription')}</div>
                                    <div className={styles.vitalSignsRadioButtonDiv}>
                                            <input type="radio" value="false" name="providedNon" className={styles.vitalRadio}
                                            onChange={() =>{
                                                setShowNon(false);
                                                setAddNon2(false);
                                                setAddNon3(false);
                                                setAddNon4(false);
                                                setAddNon5(false);
                                            }}
                                            onClick={handleNonPres}/>
                                            <label for="No" className={styles.labelradio}>{t('service.option.non.prescription.1')}</label>
                                    </div>
                                    <div className={styles.vitalSignsRadioButtonDiv}>
                                            <input type="radio" value="true" name="providedNon" className={styles.vitalRadio}
                                            onChange={() =>{
                                                setShowNon(true)
                                            }}
                                            onClick={handleNonPres}/>
                                            <label for="Yes" className={styles.labelradio}>{t('service.option.non.prescription.2')}</label>
                                    </div>
                                </Grid>
                            </Grid>


                            {showNon ? (
                                <Grid id="noncontainer" container style={{ alignItems: "flex-end" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="nameNon" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strengthNon" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sigNon" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="typeNon" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <button id="Nonbutton" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </Grid>
                                </Grid>
                            ) : null}

                            
                            {addNon2 ? (
                                <Grid id="noncontainer2" container style={{ alignItems: "flex-end" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name2Non" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength2Non" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}} >
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig2Non" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type2Non" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <button id="Nonbutton2" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {addNon3 ? (
                                <Grid id="noncontainer3" container style={{ alignItems: "flex-end" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name3Non" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength3Non" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig3Non" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type3Non" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <button id="Nonbutton3" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {addNon4 ? (
                                <Grid id="noncontainer4" container style={{ alignItems: "flex-end" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name4Non" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength4Non" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig4Non" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type4Non" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <button id="Nonbutton4" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {addNon5 ? (
                                <Grid id="noncontainer5" container style={{ alignItems: "flex-end" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name5Non" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.extra2}></div>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength5Non" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig5Non" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type5Non" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handleNonPres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                    </Grid>
                                </Grid>
                            ) : null}

                        </div>

                        <div className={styles.prescriptionContainer}>
                            <Grid container style={{ alignItems: "flex-end" }} spacing={3}>
                                <Grid container item xs={12} style={{ display: "flex", alignItems: "space-between"}}>
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.infotitleM}>{t('service.cont.pharmacy')}</div>
                                        {userMedInfo !== undefined && userMedInfo.reason.pharmacy !== undefined ? (
                                            <input name="pharmacy" placeholder={userMedInfo.reason.pharmacy.label} className={styles.instructionsM3} onChange={handlePres}/>
                                        ) : (
                                            <input name="pharmacy" placeholder="Not specified" className={styles.instructionsM} onChange={handlePres}/>
                                        )}
                                        
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.infotitleM}>{t('service.cont.pharmacy.instruction')}</div>
                                        <textarea name="instructions" placeholder="Description" className={styles.instructionsM2} onChange={handlePres}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", alignItems: "center"}}>
                                    <div className={styles.infotitleM}>{t('service.cont.prescription')}</div>
                                    <div className={styles.vitalSignsRadioButtonDiv}>
                                            <input type="radio" value="false" name="provided" className={styles.vitalRadio}
                                            onChange={() =>{
                                                setShowPres(false);
                                                setAddPres2(false);
                                                setAddPres3(false);
                                                setAddPres4(false);
                                                setAddPres5(false);
                                            }}
                                            onClick={handlePres}/>
                                            <label for="No" className={styles.labelradio}>{t('service.option.prescription.1')}</label>
                                    </div>
                                    <div className={styles.vitalSignsRadioButtonDiv}>
                                            <input type="radio" value="true" name="provided" className={styles.vitalRadio} 
                                            onChange={() =>{
                                                setShowPres(true);
                                            }}
                                            onClick={handlePres}/>
                                            <label for="Yes" className={styles.labelradio}>{t('service.option.prescription.2')}</label>
                                    </div>
                                </Grid>

                                <Grid container xs={6} md={6} style={{ display: "flex", alignItems: "space-between", flexDirection: "row"}}>
                                    <Grid item style={{ width: "100%" }}>
                                        <div className={styles.infotitleM}>{t('service.cont.pharmacy')}</div>
                                        {userMedInfo !== undefined && userMedInfo.reason.pharmacy !== undefined ? (
                                            <input name="pharmacy" placeholder={userMedInfo.reason.pharmacy.label} className={styles.instructionsM3} onChange={handlePres}/>
                                        ) : (
                                            <input name="pharmacy" placeholder="Not specified" className={styles.instructionsM} onChange={handlePres}/>
                                        )}
                                        
                                    </Grid>
                                    <Grid item style={{ width: "50%" }}>
                                        <div className={styles.infotitleM}>{t('service.cont.pharmacy.instruction')}</div>
                                        <input name="instructions" placeholder="Description" className={styles.instructionsM2} onChange={handlePres}/>
                                    </Grid>
                                </Grid>

                            </Grid>

                            {showPres ? (
                                <Grid id="prescriptioncontainer" container style={{ alignItems: "flex-end", marginTop: "1vh" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.qty')}</div>
                                            <input name="quantity" placeholder="i.e., 20" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.unit')}</div>
                                            <input name="unit" placeholder="i.e., Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.refills')}</div>
                                            <input name="refills" placeholder="i.e., 0" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.diagnosis')}</div>
                                            <input name="diagnosis" placeholder="i.e., IDC - 10" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <div className={styles.beforeDateContainer}>
                                        <div className={styles.infotitleM}>{t('service.cont.fill.before')}</div>
                                        <div className={styles.beforeDateButtonsContainer}>
                                            <input name="date" type="date" placeholder="Today" style={{ border: "none"}} onChange={handlePres}/>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="No" name="dispense" className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="No" className={styles.labelradio}>{t('service.cont.dispense')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="Yes" name="dispense" className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Yes" className={styles.labelradio}>{t('service.cont.permanent')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="NotApplicable" name="dispense"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Notapplicable" className={styles.labelradio}>{t('service.cont.temporary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.drugButtonsContainer}>
                                        <button id="prescriptionbutton" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </div>

                                </Grid>
                            ) : null}

                            {addPres2 ? (
                                <Grid  id="prescriptioncontainer2"container style={{ alignItems: "flex-end", marginTop: "4vh" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name2" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength2" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig2" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type2" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.qty')}</div>
                                            <input name="quantity2" placeholder="i.e., 20" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.unit')}</div>
                                            <input name="unit2" placeholder="i.e., Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.refills')}</div>
                                            <input name="refills2" placeholder="i.e., 0" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.diagnosis')}</div>
                                            <input name="diagnosis2" placeholder="i.e., IDC - 10" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <div className={styles.beforeDateContainer}>
                                        <div className={styles.infotitleM}>{t('service.cont.fill.before')}</div>
                                        <div className={styles.beforeDateButtonsContainer}>
                                            <input name="date2" type="date" placeholder="Today" style={{ border: "none"}} onChange={handlePres}/>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="No" name="dispense2"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="No" className={styles.labelradio}>{t('service.cont.dispense')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="Yes" name="dispense2" className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Yes" className={styles.labelradio}>{t('service.cont.permanent')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="NotApplicable" name="dispense2"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Notapplicable" className={styles.labelradio}>{t('service.cont.temporary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.drugButtonsContainer}>
                                        <button id="prescriptionbutton2" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </div>
                                    </Grid>
                            ) : null}
                            {addPres3 ? (
                                <Grid id="prescriptioncontainer3" container style={{ alignItems: "flex-end", marginTop: "4vh" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name3" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength3" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig3" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type3" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.qty')}</div>
                                            <input name="quantity3" placeholder="i.e., 20" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.unit')}</div>
                                            <input name="unit3" placeholder="i.e., Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.refills')}</div>
                                            <input name="refills3" placeholder="i.e., 0" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.diagnosis')}</div>
                                            <input name="diagnosis3" placeholder="i.e., IDC - 10" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <div className={styles.beforeDateContainer}>
                                        <div className={styles.infotitleM}>{t('service.cont.fill.before')}</div>
                                        <div className={styles.beforeDateButtonsContainer}>
                                            <input name="date3" type="date" placeholder="Today" style={{ border: "none"}} onChange={handlePres}/>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="No" name="dispense3"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="No" className={styles.labelradio}>{t('service.cont.dispense')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="Yes" name="dispense3"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Yes" className={styles.labelradio}>{t('service.cont.permanent')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="NotApplicable" name="dispense3"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Notapplicable" className={styles.labelradio}>{t('service.cont.temporary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.drugButtonsContainer}>
                                        <button id="prescriptionbutton3" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </div>
                                    </Grid>
                            ) : null}
                            {addPres4 ? (
                                <Grid id="prescriptioncontainer4" container style={{ alignItems: "flex-end", marginTop: "4vh" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name4" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength4" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig4" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type4" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.qty')}</div>
                                            <input name="quantity4" placeholder="i.e., 20" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.unit')}</div>
                                            <input name="unit4" placeholder="i.e., Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.refills')}</div>
                                            <input name="refills4" placeholder="i.e., 0" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.diagnosis')}</div>
                                            <input name="diagnosis4" placeholder="i.e., IDC - 10" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <div className={styles.beforeDateContainer}>
                                        <div className={styles.infotitleM}>{t('service.cont.fill.before')}</div>
                                        <div className={styles.beforeDateButtonsContainer}>
                                            <input name="date4" type="date" placeholder="Today" style={{ border: "none"}} onChange={handlePres}/>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="No" name="dispense4"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="No" className={styles.labelradio}>{t('service.cont.dispense')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="Yes" name="dispense4"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Yes" className={styles.labelradio}>{t('service.cont.permanent')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="NotApplicable" name="dispense4"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Notapplicable" className={styles.labelradio}>{t('service.cont.temporary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.drugButtonsContainer}>
                                        <button id="prescriptionbutton4" className={styles.saveNoteButton} onClick={saveService}>+</button>
                                    </div>
                                    </Grid>
                            ) : null}
                            {addPres5 ? (
                                <Grid id="prescriptioncontainer5" container style={{ alignItems: "flex-end", marginTop: "4vh" }} spacing={3}>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.name.drug')}</div>
                                            <input name="name5" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.strength')}</div>
                                            <input name="strength5" placeholder="i.e., 200mg Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.sig')}</div>
                                            <input name="sig5" placeholder="i.e., 1 capsule orally" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.type')}</div>
                                            <input name="type5" placeholder="i.e., New RX" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.qty')}</div>
                                            <input name="quantity5" placeholder="i.e., 20" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.unit')}</div>
                                            <input name="unit5" placeholder="i.e., Cap" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.refills')}</div>
                                            <input name="refills5" placeholder="i.e., 0" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className={styles.infotitleM}>{t('service.cont.diagnosis')}</div>
                                            <input name="diagnosis5" placeholder="i.e., IDC - 10" className={styles.instructionsM} onChange={handlePres}/>
                                        </div>
                                    </Grid>
                                    <div className={styles.beforeDateContainer}>
                                        <div className={styles.infotitleM}>{t('service.cont.fill.before')}</div>
                                        <div className={styles.beforeDateButtonsContainer}>
                                            <input name="date5" type="date" placeholder="Today" style={{ border: "none"}} onChange={handlePres}/>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="No" name="dispense5"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="No" className={styles.labelradio}>{t('service.cont.dispense')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="Yes" name="dispense5"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Yes" className={styles.labelradio}>{t('service.cont.permanent')}</label>
                                            </div>
                                            <div className={styles.vitalSignsRadioButtonDiv}>
                                                    <input type="radio" value="NotApplicable" name="dispense5"  className={styles.vitalRadio} onClick={handlePres}/>
                                                    <label for="Notapplicable" className={styles.labelradio}>{t('service.cont.temporary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.drugButtonsContainer}>
                                    </div>
                                    </Grid>
                            ) : null}
                            {/*<Grid container style={{ alignItems: "flex-end" }} spacing={3}>
                                <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center"}}>
                                    <div style={{ width: "50%" }}>
                                        <div className={styles.extra2}></div>
                                        <div className={styles.infotitleM}>Appoinment Date</div>
                                        <input name="appointmentDate" type="date" placeholder="i.e., Tylenol" className={styles.instructionsM} onChange={handleMedInfo}/>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <div className={styles.extra2}></div>
                                        <div className={styles.infotitleM}>Call Duration</div>
                                        <input name="callDuration" placeholder="i.e., 5min" className={styles.instructionsM} onChange={handleMedInfo}/>
                                    </div>
                                </Grid>
                            </Grid>*/}
                            <Grid container direction="column" spacing={2}>
                                <label className={styles.veracity}>
                                    <Checkbox  required checked={veracity.veracity_checked} name="veracity_checked" onChange={handleVeracity} color="primary"/>{t('service.cont.veracity.i')} {`${last_name}`} {t('service.cont.veracity.message')}
                                </label>
                                {showAlert ? (<div class="alert alert-danger" role="alert">{t('service.cont.alert')}</div>) : null}
                            </Grid>

                            <div className={styles.finalButtonContainer}>
                                
                                <button className={styles.boton4} onClick={finishService}>{t('service.button.finish.service')}</button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            
        </>
    )
}

export default Service;
