import React, { useState, useEffect } from 'react';

import CreateIcon from '@material-ui/icons/Create';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Grid, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Controller } from "react-hook-form"

import { updateUserMedInfo } from '../services/userService';
import { useForm } from "react-hook-form";

import HistoryComplement from './HistoryComplement';
import { Button } from 'react-bootstrap';

import {useTranslation} from "react-i18next";


import styles from '../styles/Service.module.css';



const BasicInfo = ({userinfo, userMedInfo, section, saveBasicInfoData}) => {
    const [t, i18n] =useTranslation("home");

    const [editStatus, setEditStatus] = useState(true);

    const [modalShow, setModalShow] = useState(false);
    const [saveShow, setSaveShow ] = useState(false);
    const [pastMedShow, setPastMedShow] = useState(false);

    const useStyles = makeStyles({       
        inputSelect:{
            width: "100%",
            height: "45px",
            backgroundColor: "#F8F8F8",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            margin: 0.5+ "rem",
            marginLeft: 0,
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "Lucida Grande",
            disableUnderline: true
        },
        inputText:{
            width: "100%",
            height: "45px",
            backgroundColor: "#F8F8F8",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            margin: 0.5+ "rem",
            marginLeft: 0,
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "LucidaGrandeR",
            disableUnderline: true,
        },
        inputDate:{
            width: "100%",
            height: "45px",
            backgroundColor: "#F8F8F8",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            margin: 0.5+ "rem",
            marginLeft: 0,
            textAlign: "center",
            fontSize: 12 + "px",
            fontFamily: "LucidaGrandeR",
            disableUnderline: true,
        },
        inputRead:{
            width: "100%",
            height: "45px",
            backgroundColor: "#FFFFFF",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            margin: 0.5+ "rem",
            marginLeft: 0,
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "LucidaGrandeR",
        },
        inputReadDate:{
            width:  "100%",
            height: "45px",
            backgroundColor: "#FFFFFF",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            margin: 0.5+ "rem",
            marginLeft: 0,
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "LucidaGrandeR",
        }
        
    })
    const classes = useStyles();

    const { register, handleSubmit, watch, errors, setValue } = useForm();

    const handleSelectInputEthnicity = (e) => {
        setValue('ethnicity', e.target.value)
    }

    const handleSelectInputContactPreference = (e) => {
        setValue('contactPreference', e.target.value)
    }

    const handleSelectInputBirthSex = (e) => {
        setValue('birthSex', e.target.value)
    }
    // const handleSelect = (e) => {
    //     setSelectInput({
    //         selectInput,
    //         [e.target.name]: e.target.value
    //     })
    // }

    React.useEffect(() => {
        register('ethnicity')
    }, [register])

    React.useEffect(() => {
        register('contactPreference')
    }, [register])

    React.useEffect(() => {
        register('birthSex')
    }, [register])

    const onSubmit = data => {
        console.log(data);
    }

    // console.log("basic info log");
    // console.log(userinfo);
    // console.log(userMedInfo)

    const [ saveInfo, setSaveInfo ] = useState(false);
    const [ readOnly, setReadOnly ] = useState(true);

    const [ basicinfo, setBasicInfo ] = useState(null);
  
    // const { first_name, last_name, 
    //     birthday, ethnicity, birth_sex, 
    //     birthplace, emergency_contact, marital_status
    // } = userinfo;
    const { pre_existing_conditions, family_medical_history, allergies, medication, tobacco, alcohol, activity_level, used_illegal_drugs, travel} = userMedInfo
    // const {highBloodPressure, highCholesterol, heart, thyroid, kidney, liver, asthma, diabetes, depression, arrhythmias, panicAttack, seizure, anxiety, copd, psoriasis, cancer, stroke, acidReflux, skinProblems, osteopenia, headache, other, cancerType, heartType, liverType, kidneyType} = pre_existing_conditions
    // const {highBloodPressureFam, highCholesterolFam, heartFam, thyroidFam, kidneyFam, liverFam, asthmaFam, diabetesFam, depressionFam, arrhythmiasFam, panicAttackFam, seizureFam, anxietyFam, copdFam, psoriasisFam, cancerFam, strokeFam, acidRefluxFam, skinProblemsFam, osteopeniaFam, headacheFam, family_member_other, family_member_other_disease, second_family_other, second_family_other_disease, third_family_other, third_family_other_disease, fourth_family_other, fourth_family_other_disease} = family_medical_history
    // const {doesHaveAllergy, allergyName, drug, food, cosmetic, plant, pet, chills, cough, diarrhea, dizziness, eruption, fever, headacheAl, nausea, pruritus, throat, vomiting, swelling, tachycardia, otherAllergy} = allergies
    // const {doesTakeMedication, drugName, dose, frequencyTaken, nameSecondDrug, doseSecond, frequencyTakenSecond, nameThirdDrug, doseThird, frequencyTakenThird, nameFourthDrug, doseFourth, frequencyTakenFourth, nameFifthDrug, doseFifth, frequencyTakenFifth} = medication
    // const {usedTobacco, ageStarted, ageStopped, yearQuit, cigarrettes, cigarrettesPerDay, cigars, cigarsPerDay, pipe, pipePerDay, smokelessTobaco, smokelessTobaccoPerDay} = tobacco
    // const {activity} = activity_level
    // const {drinkAlcohol, yearQuitAlcohol, numberOfDrinksCheck, numberOfDrinksPerDay, numberOfDrinksPerWeek, numberOfDrinksPerMonth} = alcohol
    // const {illegal_drugs, recreationalDrugType} = used_illegal_drugs
    // const {travelOverseas, countryTraveled, cityTraveled, periodTraveled} = travel

    const saveMethod = ( data ) => {

        console.log('saveMethod')
        console.log('data:', data)
        setSaveShow(false)
        setEditStatus(!editStatus)

        const updatedUserInfo = {
            'first_name': data.firstName,
            'last_name': data.lastName,
            'phone': { 'phone_number': data.phoneNumber,
                'mobile_number': data.mobileNumber },
            'preferred_contact_method': data.contactPreference,
            'birthday': data.birthday,
            'birth_place': data.birthPlace,
            'ethnicity': data.ethnicity,
            'birth_sex': data.birthSex,
            'marital_status': data.maritalStatus,
            'emergency_contact': {
                'name': data.emergencyContactName,
                'relationship': data.emergencyContactRelation,
                'phone_number': data.emergencyContactPhone
            }
        }

        const updatedMedInfo = {
            'weight_data': {
                'weight': data.weight
            },
            'height_data': {
                'height': data.height
            }
        }

        console.log('updatedUserInfo', updatedUserInfo)
        console.log('updatedMedInfo', updatedMedInfo)
        saveBasicInfoData (updatedUserInfo, updatedMedInfo)
    }

    const editFields = () => {
        //Cambiar estado para ser editable
        setReadOnly(false);

        //Aparacer boton para guardar
        setSaveInfo(true);

    }

    //Actualizar la info del user
    const updateInfo = () => {

        //Enviar la nueva info a la base de datos

        setSaveInfo(false);

        //Cambiar estado para ser editable
        setReadOnly(true);
    }

    useEffect(() => {
        if (editStatus) {
            setSaveShow(true)
        }else{
            setSaveShow(false)
        }
    },[editStatus]);
    const newUserInfo = (e) => {
        // setBasicInfo({
        //     ...basicinfo,
        //     [e.target.name] : e.target.value
        // })
        
    }


    //console.log("basic info");
    // Array.from(userMedInfo, child => {
    //     console.log("child: ", child)
    // });
    // for (itemmax of userMedInfo ){
    //     console.log(itemmax);
    // }

    

    

    return (    
        <>

            {/* <Hx section={ section } onClick={() => setEditStatus(!editStatus)} editStatus={editStatus}/> */}
            { section=="service"  && 
                <div className={styles.welcome}>
                    {t('basic.info.title.attending')} {userinfo.first_name}
                </div>
            }
            { section=="service"  && 
                <div className={styles.hxTitle}>
                    {t('basic.info.title.hx')}
                    <span onClick={() => setEditStatus(!editStatus)} className={styles.write} ><CreateIcon color="primary" fontSize="small" /></span>
                    <SaveButton onClick={ handleSubmit(saveMethod)} editStatus={editStatus}/>

                </div>
            }

            <div className={styles.subtitleContainer}>
                <div className={styles.subtitle}>{t('basic.info.subtitle.basic.info')}</div>
                <EditLogo onClick={() => setEditStatus(!editStatus)} section={ section }/>
                <SaveButtonProfile onClick={ handleSubmit(saveMethod)} section={ section } editStatus={editStatus}/>
            </div>

            <Grid container direction="row" justify="flex-start" sm={12} xs={12} className={styles.gridMain}>
                <Grid item md={4} sm={12} xs={12} className={styles.p10}>
                    <div className={styles.dataTitle}>{t('basic.info.cont.name')}</div>
                    <div className={styles.nameInputs} style={{display: "flex", justifyContent: "space-between"}}>
                        {editStatus ? (
                            <TextField inputRef={register} name="firstName" defaultValue={userinfo.first_name} InputProps={{ readOnly: editStatus, className: classes.inputRead}} className={`${styles.dataTitle} ${styles.dataTitleLeft}`} className={styles.inputMobile} className={classes.inputRead}/>
                        ) : (
                            <TextField inputRef={register} name="firstName" defaultValue={userinfo.first_name} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} className={`${styles.dataTitle} ${styles.dataTitleLeft}`} className={styles.inputMobile} className={classes.inputText}/>
                        )}
                        <div style={{ marginRight: "1vw"}}></div>
                        {editStatus ? (
                            <TextField inputRef={register} name="lastName" defaultValue={userinfo.last_name} InputProps={{ readOnly: editStatus, className: classes.inputRead}} className={styles.dataTitle} className={styles.inputMobile} className={classes.inputRead}/>
                        ) : (
                            <TextField inputRef={register} name="lastName" defaultValue={userinfo.last_name} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} className={styles.dataTitle} className={styles.inputMobile} className={classes.inputText}/>
                        )}
                    </div>
                </Grid>
                <Grid item md={2} sm={12} xs={12} className={styles.p10}>
                    <div>
                        <div className={styles.dataTitle}>{t('basic.info.cont.phone.number')}</div>
                        <div className={styles.phoneInputs}>
                            {editStatus ? (
                                <TextField inputRef={register} name="phoneNumber" defaultValue={userinfo.phone.phone_number} type="number" id="phoneNumber" InputProps={{ readOnly: editStatus, className: classes.inputRead}} className={styles.inputMobile} className={classes.inputRead}/>
                            ) : (
                                <TextField inputRef={register} name="phoneNumber" defaultValue={userinfo.phone.phone_number} type="number" id="phoneNumber" InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} className={styles.inputMobile} className={classes.inputText}/>
                            )}
                        </div>
                    </div>
                    <div style={{width: "35px"}}></div>
                </Grid>
            </Grid>
            
            <div className={styles.subtitleContainer} style={{marginTop: "4vh"}}>
                <div className={styles.subtitle}>{t('basic.info.subtitle.demographic')}</div>
                <EditLogo onClick={() => setEditStatus(!editStatus)} section={ section }/>
            </div>

            <Grid ontainer sm={12} xs={12}>
                <Grid container direction="row" justify="flex-start" xs={12}>
                    <Grid item xs={6} sm={6} md={2} className={styles.p10}>
                        <div className={styles.dataTitle}>{t('basic.info.cont.birth')}</div>
                        <div className={styles.nameInputs}>
                            {editStatus ? (
                                <TextField inputRef={register} name="birthday" defaultValue={userinfo.birthday} type="date" InputProps={{ readOnly: editStatus, className: classes.inputReadDate}}/> 
                            ) : (
                                <TextField inputRef={register} name="birthday" defaultValue={userinfo.birthday} type="date" InputProps={{ readOnly: editStatus, className: classes.inputDate, disableUnderline: true}}/> 
                            )} 
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} className={styles.p10}>
                        <div className={styles.dataTitle}>{t('basic.info.cont.place')}</div>
                        <div className={styles.mobileInput}>
                            {editStatus ? (
                                <TextField inputRef={register} name="birthPlace" defaultValue={userinfo.birth_place} InputProps={{ readOnly: editStatus, className: classes.inputRead}}/>
                            ) : (
                                <TextField inputRef={register} name="birthPlace" defaultValue={userinfo.birth_place} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}}/>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} className={styles.p10}>
                        <div className={styles.dataTitle}>{t('basic.info.cont.ethicity')}</div>
                        <div className={styles.ethnicity}>
                            {editStatus ? (
                                <TextField inputRef={register} name="ethnicity" defaultValue={userinfo.ethnicity}  id="ethnicity" InputProps={{ readOnly: editStatus, className: classes.inputRead}} className={styles.inputMobile} />
                            ) : (
                                <div className={classes.inputText}>

                                <Select id="ethnicity"
                                    defaultValue={userinfo.ethnicity}
                                    InputProps={{ classes: classes.inputSelect }}
                                    disabled={ editStatus }
                                    name="ethnicity"
                                    style={{width: "90%"}}
                                    disableUnderline={true}
                                    onChange={handleSelectInputEthnicity}
                                >

                                    {/* Cambios */}
                                    <MenuItem value={"Hispanic or Latino"}>{t('basic.info.cont.hispanic')}</MenuItem>
                                    <MenuItem value={"White"}>{t('basic.info.cont.white')}</MenuItem>
                                    <MenuItem value={"African-American"}>{t('basic.info.cont.african')}</MenuItem>
                                    <MenuItem value={"Other"}>{t('basic.info.cont.other')}</MenuItem>
                            </Select>
                                </div>
                            )}

                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} className={styles.p10}>
                        {/* Cambios */}
                        <div className={styles.dataTitle}>{t('basic.info.cont.birth.sex')}</div>
                        <div className={styles.selectInputs}>

                            {editStatus ? (
                                <TextField inputRef={register} name="birthSex" defaultValue={userinfo.birth_sex} InputProps={{ readOnly: editStatus, className: classes.inputReadDate}}/> 
                            ) : (
                                <div className={classes.inputText}>
                                    <Select

                                            id="birthSex"
                                            defaultValue={userinfo.birth_sex}
                                            InputProps={{ classes: classes.inputText }}
                                            disabled={ editStatus }
                                            disableUnderline={true}
                                            name="birthSex"
                                            style={{width: "80%"}}
                                            onChange={handleSelectInputBirthSex}
                                    >

                                            {/* Cambios */}
                                            <MenuItem value={"Male"}>{t('basic.info.cont.male')}</MenuItem>
                                            <MenuItem value={"Female"}>{t('basic.info.cont.female')}</MenuItem>
                                            <MenuItem value={"Unknown"}>{t('basic.info.cont.unknown')}</MenuItem>
                                    </Select>
                                </div>  
                            )}



                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={1} className={styles.p10}>
                        <div className={styles.dataTitle}>{t('basic.info.cont.weight')}</div>
                        <div className={styles.mobileInputs}>
                            {editStatus ? (

                                (userMedInfo.weight_data !== null ? (
                                    <TextField inputRef={register} name="weight" defaultValue={userMedInfo.weight_data.weight} InputProps={{ readOnly: editStatus, className: classes.inputRead}} type="number" InputProps={{endAdornment: <InputAdornment position="start">kg</InputAdornment>}} className={classes.inputRead} style={{ position: "relative", top: "13px"}}/>  
                                ) : (
                                    <TextField inputRef={register} name="weight" defaultValue="Not specified" InputProps={{ readOnly: editStatus, className: classes.inputRead}} type="number" InputProps={{endAdornment: <InputAdornment position="start">kg</InputAdornment>}} className={classes.inputRead} style={{ position: "relative", top: "13px"}}/>
                                ))
                                
                            ) : (
                                (userMedInfo.weight_data !== null ? (
                                    <TextField inputRef={register} name="weight" defaultValue={userMedInfo.weight_data.weight} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} type="number" InputProps={{endAdornment: <InputAdornment position="start">kg</InputAdornment>, disableUnderline: true}} className={classes.inputText}/> 
                                ) : (<TextField inputRef={register} name="weight" defaultValue="Not specified" InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} type="number" InputProps={{endAdornment: <InputAdornment position="start">kg</InputAdornment>, disableUnderline: true}} className={classes.inputText}/>))
                                 

                            )}
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={1} className={styles.p10}>
                       <div className={styles.dataTitle}>{t('basic.info.cont.height')}</div>
                        <div className={styles.mobileInput}>
                            {editStatus ? (

                                (userMedInfo.height_data !== null ? (
                                    <TextField inputRef={register} name="height" defaultValue={userMedInfo.height_data.height} InputProps={{ readOnly: editStatus, className: classes.inputRead}}  type="number" InputProps={{endAdornment: <InputAdornment position="start">cm</InputAdornment>}} className={classes.inputRead} style={{ position: "relative", top: "13px"}}/>
                                ) : (
                                    <TextField inputRef={register} name="height" defaultValue="Not specified" InputProps={{ readOnly: editStatus, className: classes.inputRead}}  type="number" InputProps={{endAdornment: <InputAdornment position="start">cm</InputAdornment>}} className={classes.inputRead} style={{ position: "relative", top: "13px"}}/>
                                ))
                                
                            ) : (
                                (userMedInfo.height_data !== null ? (
                                    <TextField inputRef={register} name="height" defaultValue={userMedInfo.height_data.height} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}}  type="number" InputProps={{endAdornment: <InputAdornment position="start">cm</InputAdornment>, disableUnderline: true}}className={classes.inputText}/>
                                ) : (
                                    <TextField inputRef={register} name="height" defaultValue="Not specified" InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}}  type="number" InputProps={{endAdornment: <InputAdornment position="start">cm</InputAdornment>, disableUnderline: true}}className={classes.inputText}/>
                                ))
                                

                            )}
                        </div> 
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} className={styles.p10}>
                        <div className={`${styles.dataTitle} ${styles.maritalStatusDesktop}`}>{t('basic.info.cont.marital.status')}</div>
                        <div className={`${styles.dataTitle} ${styles.maritalStatusMobile}`}>{t('basic.info.cont.mar.stat')}</div>
                        <div className={styles.mobileInput}>
                           {editStatus ? (
                                <TextField inputRef={register} name="maritalStatus" defaultValue={userinfo.marital_status} InputProps={{ readOnly: editStatus, className: classes.inputRead}} />
                           ) : (
                                <TextField inputRef={register} name="maritalStatus" defaultValue={userinfo.marital_status} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} />
                           )}
                        </div>
                    </Grid>

                </Grid>
            </Grid>
            
            <Grid container sm={12} xs={12}>
                <Grid container direction="row" justify="flex-start" xs={12} sm={8}>
                    <Grid item xs={12} sm={12} className={styles.p10}>
                        <div className={styles.subtitleContainer} style={{marginTop: "4vh"}}>
                            <div className={styles.subtitle}>{t('basic.info.cont.emergency')}</div>
                            <EditLogo onClick={() => setEditStatus(!editStatus)} section={ section }/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} className={styles.p10}>
                                <div className={styles.dataTitle}>{t('basic.info.cont.name')}</div>
                                <div className={styles.nameInputs}>
                                    {editStatus ? (
                                        <TextField inputRef={register} name="emergencyContactName" defaultValue={userinfo.emergency_contact.name} InputProps={{ readOnly: editStatus, className: classes.inputRead}} />
                                    ) : (
                                        <TextField inputRef={register} name="emergencyContactName" defaultValue={userinfo.emergency_contact.name} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} />
                                    )}  
                                </div>
                            </Grid>
                    <Grid item xs={12} sm={3} className={styles.p10}>
                                <div className={styles.dataTitle}>{t('basic.info.cont.relation')}</div>
                                <div className={styles.mobileInput}>
                                    {editStatus ? (
                                        <TextField inputRef={register} name="emergencyContactRelation" defaultValue={userinfo.emergency_contact.relationship} InputProps={{ readOnly: editStatus, className: classes.inputRead}} />
                                    ) : (
                                        <TextField inputRef={register} name="emergencyContactRelation" defaultValue={userinfo.emergency_contact.relationship} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} />
                                    )}  
                                </div>
                            </Grid>
                    <Grid item xs={12} sm={3} className={styles.p10}>
                                <div className={styles.dataTitle}>{t('basic.info.cont.phone')}</div>
                                <div className={styles.mobileInput}>
                                    {editStatus ? (
                                        <TextField type="number" inputRef={register} name="emergencyContactPhone" defaultValue={userinfo.emergency_contact.phone_number} InputProps={{ readOnly: editStatus, className: classes.inputRead}} />
                                    ) : (
                                        <TextField type="number" inputRef={register} name="emergencyContactPhone" defaultValue={userinfo.emergency_contact.phone_number} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} />
                                    )}
                                </div>
                            </Grid>
                        
                </Grid>
                {section=="service" &&

                <Grid container direction="row" justify="flex-start" xs={12} sm={4}>
                    <div className={styles.subtitleContainer} style={{marginTop: "4vh"}}>
                        <div className={styles.subtitle}>{t("basic.info.cont.reason")}</div>
                    </div>

                    <Grid item xs={12} sm={12} className={styles.p10}>
                        <Grid item xs={12} className={styles.dataContainer}>
                            <div>
                                <div className={styles.dataTitle}>{t('emr.title.reason')}</div>
                                <div className={styles.nameInputs}>
                                    {editStatus ? (
                                        <TextField type="text" defaultValue={userMedInfo.reason.reason_info.reasonInfo} InputProps={{ readOnly: editStatus, className: classes.inputRead}} />
                                    ) : (
                                        <TextField type="text" defaultValue={userMedInfo.reason.reason_info.reasonInfo} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} />
                                    )} 
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                        
                </Grid>

                    // <Grid item className={styles.subtitleContainer}>
                    //     <div className={styles.dataLeftMargin}>
                    //             <div className={styles.subtitle}>{t('emr.title.reason')}</div>
                    //             <div className={styles.dataTitle}>{t('basic.info.cont.name')}</div>
                    //             <div className={styles.mobileInput}>
                    //                 {userMedInfo.reason.reason_info.reasonInfo ? (
                                    //     <TextField type="text" defaultValue={userMedInfo.reason.reason_info.reasonInfo} className={classes.inputRead} readOnly />
                                    // ) : (
                                    //     <TextField type="text" defaultValue={userMedInfo.reason.reason_info.reasonInfo} InputProps={{ readOnly: editStatus, className: classes.inputText, disableUnderline: true}} />
                                    // )}
                    //             </div>
                    //     </div>
                    // </Grid>
                }
                { section=="service"  &&
                    <Grid container direction="row" justify="flex-start" xs={12} sm={12} style={{marginTop:'20px'}}>
                        {pastMedShow ? (
                            <span style={{width:'100%'}} >
                                <Grid item xs={12} sm={2} style={{marginTop:'20px'}}>
                                    <button className={styles.boton} onClick={() => setPastMedShow(false)} style={{backgroundColor:'red'}}>Hide Medical History</button>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" xs={12} sm={12} className={styles.contHistorialMedic}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={styles.subtitle}>{t("addendum.title.past")}</div>
                                        {pre_existing_conditions != null ? (
                                            <Grid container direction="row" xs={12} className={styles.contLi}>
                                            {pre_existing_conditions.acidReflux ? (
                                                <li className={styles.liHistory}>Acid Reflux</li>
                                            ) : null}
                                            {pre_existing_conditions.anxiety ? (
                                                <li className={styles.liHistory}>Anxiety</li>
                                            ) : null}
                                            {pre_existing_conditions.arrhythmias ? (
                                                <li className={styles.liHistory}>Arrhythmias</li>
                                            ) : null}
                                            {pre_existing_conditions.asthma ? (
                                                <li className={styles.liHistory}>Asthma</li>
                                            ) : null}
                                            {pre_existing_conditions.cancer ? (
                                                <li className={styles.liHistory}>Cancer: {pre_existing_conditions.cancerType}</li>
                                            ) : null}
                                            {pre_existing_conditions.copd ? (
                                                <li className={styles.liHistory}>COPD</li>
                                            ) : null}
                                            {pre_existing_conditions.depression ? (
                                                <li className={styles.liHistory}>Depression</li>
                                            ) : null}
                                            {pre_existing_conditions.diabetes ? (
                                                <li className={styles.liHistory}>Diabetes</li>
                                            ) : null}
                                            {pre_existing_conditions.headache ? (
                                                <li className={styles.liHistory}>Headaches or migraines</li>
                                            ) : null}
                                            {pre_existing_conditions.heart ? (
                                                <li className={styles.liHistory}>Heart disease: {pre_existing_conditions.heartType}</li>
                                            ) : null}
                                            {pre_existing_conditions.highBloodPressure ? (
                                                <li className={styles.liHistory}>High Blood Pressure</li>
                                            ) : null}
                                            {pre_existing_conditions.highCholesterol ? (
                                                <li className={styles.liHistory}>High cholesterol</li>
                                            ) : null}
                                            {pre_existing_conditions.kidney ? (
                                                <li className={styles.liHistory}>Kidney disease: {pre_existing_conditions.kidneyType}</li>
                                            ) : null}
                                            {pre_existing_conditions.liver ? (
                                                <li className={styles.liHistory}>Liver disease: {pre_existing_conditions.liverType}</li>
                                            ) : null}
                                            {pre_existing_conditions.osteopenia ? (
                                                <li className={styles.liHistory}>Osteopenia/ Osteoporosis</li>
                                            ) : null}
                                            {pre_existing_conditions.panicAttack ? (
                                                <li className={styles.liHistory}>Panic attacks</li>
                                            ) : null}
                                            {pre_existing_conditions.psoriasis ? (
                                                <li className={styles.liHistory}>Psoriasis</li>
                                            ) : null}
                                            {pre_existing_conditions.seizure ? (
                                                <li className={styles.liHistory}>Seizures</li>
                                            ) : null}
                                            {pre_existing_conditions.skinProblems ? (
                                                <li className={styles.liHistory}>Skin problems/ Eczema</li>
                                            ) : null}
                                            {pre_existing_conditions.stroke ? (
                                                <li className={styles.liHistory}>Stroke</li>
                                            ) : null}
                                            {pre_existing_conditions.thyroid ? (
                                                <li className={styles.liHistory}>Thyroid disease</li>
                                            ) : null}
                                            {pre_existing_conditions.other ? (
                                                <li className={styles.liHistory}>{pre_existing_conditions.other}</li>
                                            ) : null}
                                        </Grid>
                                        ) : (<li className={styles.liHistory}>Not specified</li>)}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <div className={styles.subtitle}>{t("addendum.title.family")}</div>
                                        {family_medical_history != null ? (
                                            <span>
                                                <Grid container direction="row" xs={12} className={styles.contLi}>
                                                    {/* Acid Reflux */}
                                                    {family_medical_history.acidRefluxFam ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Acid Reflux</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.acidReflux_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.acidReflux_family_member_other}</li>
                                                                    {family_medical_history.acidReflux_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.acidReflux_second_family_other}</li>
                                                                            {family_medical_history.acidReflux_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.acidReflux_third_family_other}</li>
                                                                                    {family_medical_history.acidReflux_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.acidReflux_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Anxiety */}
                                                    {family_medical_history.anxietyFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Anxiety</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.anxiety_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.anxiety_family_member_other}</li>
                                                                    {family_medical_history.anxiety_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.anxiety_second_family_other}</li>
                                                                            {family_medical_history.anxiety_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.anxiety_third_family_other}</li>
                                                                                    {family_medical_history.anxiety_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.anxiety_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Arrhythmias */}
                                                    {family_medical_history.arrhythmiasFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Arrhythmias</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.arrhythmias_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.arrhythmias_family_member_other}</li>
                                                                    {family_medical_history.arrhythmias_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.arrhythmias_second_family_other}</li>
                                                                            {family_medical_history.arrhythmias_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.arrhythmias_third_family_other}</li>
                                                                                    {family_medical_history.arrhythmias_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.arrhythmias_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Asthma */}
                                                    {family_medical_history.asthmaFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Asthma</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.asthma_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.asthma_family_member_other}</li>
                                                                    {family_medical_history.asthma_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.asthma_second_family_other}</li>
                                                                            {family_medical_history.asthma_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.hasthma_third_family_other}</li>
                                                                                    {family_medical_history.asthma_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.asthma_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Cancer */}
                                                    {family_medical_history.cancerFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Cancer</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.cancer_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.cancer_family_member_other}: {family_medical_history.cancer_family_member_other_disease}</li>
                                                                    {family_medical_history.cancer_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.cancer_second_family_other}: {family_medical_history.cancer_second_family_other_disease}</li>
                                                                            {family_medical_history.cancer_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.cancer_third_family_other}: {family_medical_history.cancer_third_family_other_disease}</li>
                                                                                    {family_medical_history.cancer_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.cancer_fourth_family_other}: {family_medical_history.cancer_fourth_family_other_disease}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* COPD */}
                                                    {family_medical_history.copdFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>COPD</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.copd_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.copd_family_member_other}</li>
                                                                    {family_medical_history.copd_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.copd_second_family_other}</li>
                                                                            {family_medical_history.copd_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.copd_third_family_other}</li>
                                                                                    {family_medical_history.copd_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.copd_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Depression */}
                                                    {family_medical_history.depressionFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Depression</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.depression_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.depression_family_member_other}</li>
                                                                    {family_medical_history.depression_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.depression_second_family_other}</li>
                                                                            {family_medical_history.depression_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.depression_third_family_other}</li>
                                                                                    {family_medical_history.depression_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.depression_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Diabetes */}
                                                    {family_medical_history.diabetesFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Diabetes</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.diabetes_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.diabetes_family_member_other}</li>
                                                                    {family_medical_history.diabetes_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.diabetes_second_family_other}</li>
                                                                            {family_medical_history.diabetes_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.diabetes_third_family_other}</li>
                                                                                    {family_medical_history.diabetes_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.diabetes_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Headache */}
                                                    {family_medical_history.headacheFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Headache</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.highBloodPressure_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.headache_family_member_other}</li>
                                                                    {family_medical_history.headache_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.headache_second_family_other}</li>
                                                                            {family_medical_history.headache_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.headache_third_family_other}</li>
                                                                                    {family_medical_history.headache_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.headache_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Heart */}
                                                    {family_medical_history.heartFam ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Heart Disease</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.heart_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.heart_family_member_other}: {family_medical_history.heart_family_member_other_disease}</li>
                                                                    {family_medical_history.heart_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.heart_second_family_other}: {family_medical_history.heart_second_family_other_disease}</li>
                                                                            {family_medical_history.heart_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.heart_third_family_other}: {family_medical_history.heart_third_family_other_disease}</li>
                                                                                    {family_medical_history.heart_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.heart_fourth_family_other}: {family_medical_history.heart_fourth_family_other_disease}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* High Blood Pressure */}
                                                    {family_medical_history.highBloodPressureFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>High Blood Pressure</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.highBloodPressure_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highBloodPressure_family_member_other}</li>
                                                                    {family_medical_history.highBloodPressure_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highBloodPressure_second_family_other}</li>
                                                                            {family_medical_history.highBloodPressure_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highBloodPressure_third_family_other}</li>
                                                                                    {family_medical_history.highBloodPressure_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highBloodPressure_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* High Cholesterol */}
                                                    {family_medical_history.highCholesterolFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>High Cholesterol</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.highCholesterol_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highCholesterol_family_member_other}</li>
                                                                    {family_medical_history.highCholesterol_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highCholesterol_second_family_other}</li>
                                                                            {family_medical_history.highCholesterol_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle } style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highCholesterol_third_family_other}</li>
                                                                                    {family_medical_history.highCholesterol_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.highCholesterol_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Kidney */}
                                                    {family_medical_history.kidneyFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Kidney</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.kidney_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.kidney_family_member_other}: {family_medical_history.kidney_family_member_other_disease}</li>
                                                                    {family_medical_history.kidney_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.kidney_second_family_other}: {family_medical_history.kidney_second_family_other_disease}</li>
                                                                            {family_medical_history.kidney_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.kidney_third_family_other}: {family_medical_history.kidney_third_family_other_disease}</li>
                                                                                    {family_medical_history.kidney_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.kidney_fourth_family_other}: {family_medical_history.kidney_fourth_family_other_disease}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Liver */}
                                                    {family_medical_history.liverFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Liver</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.liver_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.liver_family_member_other}: {family_medical_history.liver_family_member_other_disease}</li>
                                                                    {family_medical_history.liver_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.liver_second_family_other}: {family_medical_history.liver_second_family_other_disease}</li>
                                                                            {family_medical_history.liver_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.liver_third_family_other}: {family_medical_history.liver_third_family_other_disease}:</li>
                                                                                    {family_medical_history.liver_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.liver_fourth_family_other}: {family_medical_history.liver_fourth_family_other_disease}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Osteopenia */}
                                                    {family_medical_history.osteopeniaFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Osteopenia/ Osteoporosis</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.osteopenia_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.osteopenia_family_member_other}</li>
                                                                    {family_medical_history.osteopenia_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.osteopenia_second_family_other}</li>
                                                                            {family_medical_history.osteopenia_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.osteopenia_third_family_other}</li>
                                                                                    {family_medical_history.osteopenia_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.osteopenia_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Panic Attack */}
                                                    {family_medical_history.panicAttackFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Panic Attack</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.panicAttack_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.panicAttack_family_member_other}</li>
                                                                    {family_medical_history.panicAttack_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.panicAttack_second_family_other}</li>
                                                                            {family_medical_history.panicAttack_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.panicAttack_third_family_other}</li>
                                                                                    {family_medical_history.panicAttack_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.panicAttack_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Psoriasis */}
                                                    {family_medical_history.psoriasisFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Psoriasis</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.psoriasis_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.psoriasis_family_member_other}</li>
                                                                    {family_medical_history.psoriasis_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.psoriasis_second_family_other}</li>
                                                                            {family_medical_history.psoriasis_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.psoriasis_third_family_other}</li>
                                                                                    {family_medical_history.psoriasis_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.psoriasis_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Seizure */}
                                                    {family_medical_history.seizureFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Seizure</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.seizure_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.seizure_family_member_other}</li>
                                                                    {family_medical_history.seizure_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.seizure_second_family_other}</li>
                                                                            {family_medical_history.seizure_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.seizure_third_family_other}</li>
                                                                                    {family_medical_history.seizure_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.seizure_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Skin Problems */}
                                                    {family_medical_history.skinProblemsFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Skin Problems / Eczema</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.skinProblems_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.skinProblems_family_member_other}</li>
                                                                    {family_medical_history.skinProblems_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.skinProblems_second_family_other}</li>
                                                                            {family_medical_history.skinProblems_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.skinProblems_third_family_other}</li>
                                                                                    {family_medical_history.skinProblems_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.skinProblems_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Stroke */}
                                                    {family_medical_history.strokeFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Stroke</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.stroke_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.stroke_family_member_other}</li>
                                                                    {family_medical_history.stroke_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.stroke_second_family_other}</li>
                                                                            {family_medical_history.stroke_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.stroke_third_family_other}</li>
                                                                                    {family_medical_history.stroke_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.stroke_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                    {/* Thyroid */}
                                                    {family_medical_history.thyroidFam  ? (
                                                        <span className={styles.liHistory}>
                                                            <li className={styles.dataTitle}>Thyroid</li>
                                                            <div className={styles.dataTitle2} style={{paddingLeft:"10px"}}>Details</div>
                                                            {family_medical_history.thyroid_family_member_other !== "" ? (
                                                                <span>
                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.thyroid_family_member_other}</li>
                                                                    {family_medical_history.thyroid_second_family_other !== "" ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.thyroid_second_family_other}</li>
                                                                            {family_medical_history.thyroid_third_family_other !== "" ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.thyroid_third_family_other}</li>
                                                                                    {family_medical_history.thyroid_fourth_family_other !=="" ? (
                                                                                        <li className={styles.dataTitle} style={{paddingLeft:"10px", listStyle:"circle"}}>{family_medical_history.thyroid_fourth_family_other}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : (
                                                                <li className={styles.dataTitle}>No details</li>
                                                            )}
                                                        </span>
                                                    ) : null}
                                                </Grid>
                                            </span>
                                        ) : (<li className={styles.dataTitle}>Not specified</li>)}
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{paddingRight:"10px"}}>
                                        <span>
                                            <div className={styles.subtitle}>{t("addendum.title.allergies")}</div>
                                            {allergies != null ? (
                                                <span>
                                                    {(allergies.doesHaveAllergy==="true") ? (
                                                        <span>
                                                            <div className={styles.contLi}>
                                                            <Grid container direction="row" justify="space-between" xs={12} >
                                                                {allergies.drug ? (
                                                                    // <li className={styles.dataTitle}>Drug: {allergies.allergyName_one}</li>
                                                                    <li className={styles.liHistory}>Drug: {allergies.drugAllergyName}</li>
                                                                ) : null}
                                                                {allergies.food ? (
                                                                    // <li className={styles.dataTitle}>Food: {allergies.allergyName_two}</li>
                                                                    <li className={styles.liHistory}>Food: {allergies.foodAllergyName}</li>
                                                                ) : null}
                                                                {allergies.cosmetic ? (
                                                                    // <li className={styles.dataTitle}>Cosmetic: {allergies.allergyName_three}</li>
                                                                    <li className={styles.liHistory}>Cosmetic: {allergies.cosmeticAllergyName}</li>
                                                                ) : null}
                                                                {allergies.plant ? (
                                                                    // <li className={styles.dataTitle}>Plant: {allergies.allergyName_four}</li>
                                                                    <li className={styles.liHistory}>Plant: {allergies.plantAllergyName}</li>
                                                                ) : null}
                                                                {allergies.pet ? (
                                                                    // <li className={styles.dataTitle}>Pet: {allergies.allergyName_five}</li>
                                                                    <li className={styles.liHistory}>Pet: {allergies.petAllergyName}</li>
                                                                ) : null}
                                                                {allergies.other ? (
                                                                    // <li className={styles.dataTitle}>Other: {allergies.allergyName_six}</li>
                                                                    <li className={styles.liHistory}>Other: {allergies.otherAllergyName}</li>
                                                                ) : null}
                                                            </Grid>
                                                            <div className={styles.dataTitle2}>Reactions</div>
                                                            <Grid container direction="row" xs={12} >
                                                                {allergies.chills ? (
                                                                    <li className={styles.liHistory}>Chills</li>
                                                                ) : null}
                                                                {allergies.cough ? (
                                                                    <li className={styles.liHistory}>Cough</li>
                                                                ) : null}
                                                                {allergies.diarrhea ? (
                                                                    <li className={styles.liHistory}>Diarrhea</li>
                                                                ) : null}
                                                                {allergies.dizziness ? (
                                                                    <li className={styles.liHistory}>Dizziness</li>
                                                                ) : null}
                                                                {allergies.eruption ? (
                                                                    <li className={styles.liHistory}>Eruption</li>
                                                                ) : null}
                                                                {allergies.fever ? (
                                                                    <li className={styles.liHistory}>Fever</li>
                                                                ) : null}
                                                                {allergies.headacheAl ? (
                                                                    <li className={styles.liHistory}>Headache</li>
                                                                ) : null}
                                                                {allergies.nausea ? (
                                                                    <li className={styles.liHistory}>Nausea</li>
                                                                ) : null}
                                                                {allergies.pruritus ? (
                                                                    <li className={styles.liHistory}>Pruritus</li>
                                                                ) : null}
                                                                {allergies.throat ? (
                                                                    <li className={styles.liHistory}>Sore Throat</li>
                                                                ) : null}
                                                                {allergies.vomiting ? (
                                                                    <li className={styles.liHistory}>Vomiting</li>
                                                                ) : null}
                                                                {allergies.swelling ? (
                                                                    <li className={styles.liHistory}>Swelling</li>
                                                                ) : null}
                                                                {allergies.tachycardia ? (
                                                                    <li className={styles.liHistory}>Tachycardia</li>
                                                                ) : null}
                                                            </Grid>
                                                            </div>
                                                        </span>
                                                    ) : (
                                                        <li className={styles.dataTitle}>None</li>
                                                    )}
                                                </span>
                                            ) : (<li className={styles.dataTitle}>Not specified</li>)}
                                            
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{paddingRight:"10px"}}>
                                        <span>
                                            <div className={styles.subtitle}>{t("addendum.title.medication")}</div>
                                            {medication != null ? (
                                                <Grid container direction="row" justify="space-between" xs={12} className={styles.contLi}>
                                                    {(medication.doesTakeMedication ==="true") ? (
                                                        <span>
                                                            <li className={styles.dataTitle}>Name: {`${medication.drugName}`} Dose: {`${medication.dose}`} Frequency: {`${medication.frequencyTaken}`}</li>
                                                            {medication.nameSecondDrug ? (
                                                                <span>
                                                                    <li className={styles.dataTitle}>Name: {`${medication.nameSecondDrug}`} Dose: {`${medication.doseSecond}`} Frequency: {`${medication.frequencyTakenSecond}`}</li>
                                                                    {medication.nameThirdDrug ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Name: {`${medication.nameThirdDrug}`} Dose: {`${medication.doseThird}`} Frequency: {`${medication.frequencyTakenThird}`}</li>
                                                                            {medication.nameFourthDrug ? (
                                                                                <span>
                                                                                    <li className={styles.dataTitle}>Name: {`${medication.nameFourthDrug}`} Dose: {`${medication.doseFourth}`} Frequency: {`${medication.frequencyTakenFourth}`}</li>
                                                                                    {medication.nameFifthDrug ? (
                                                                                        <li className={styles.dataTitle}>Name: {`${medication.nameFifthDrug}`} Dose: {`${medication.doseFifth}`} Frequency: {`${medication.frequencyTakenFifth}`}</li>
                                                                                    ) : null}
                                                                                </span>
                                                                            ) : null}
                                                                        </span>
                                                                    ) : null}
                                                                </span>
                                                            ) : null}
                                                        </span>
                                                        
                                                    ) : (
                                                        <li className={styles.dataTitle}>None</li>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <li className={styles.dataTitle}>Not specified</li>
                                            )}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container direction="row" justify="flex-start" xs={12} sm={12}>
                                            <Grid item xs={12} sm={12}>
                                                <div className={styles.subtitle}>{t("addendum.title.social")}</div>
                                            </Grid>
                                            <Grid container direction="row" justify="space-between" xs={12} sm={12} className={styles.contLi}>
                                            {tobacco != null ? (
                                                <Grid item xs={12} sm={3}>
                                                    {(tobacco.usedTobacco ==="true") ? (
                                                        <span>
                                                            <div className={styles.dataTitle2}>Tobacco</div>
                                                            <Grid container direction="row" justify="space-between" xs={8}>
                                                                    <li className={styles.dataTitle}>Age started: {tobacco.ageStarted}</li>
                                                                    {tobacco.ageStopped ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Age stopped: {tobacco.ageStopped}</li>
                                                                        </span>
                                                                    ) : null}
                                                                    {tobacco.yearQuit ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Year quit: {tobacco.yearQuit}</li>
                                                                        </span>
                                                                    ) : null}
                                                                    {tobacco.cigarrettesPerDay ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Cigarrettes Per Day: {tobacco.cigarrettesPerDay}</li>
                                                                        </span>
                                                                    ) : null}
                                                                    {tobacco.packsPerDay ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Cigarrettes Pack Per Day: {tobacco.packsPerDay}</li>
                                                                        </span>
                                                                    ) : null}
                                                                    {tobacco.cigars || tobacco.cigarsPerDay ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Cigars Per Day: {tobacco.cigarsPerDay}</li>
                                                                        </span>
                                                                    ) : null}
                                                                    {tobacco.pipe || tobacco.pipePerDay ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Pipe Per Day: {tobacco.pipePerDay}</li>
                                                                        </span>
                                                                    ) : null}
                                                                    {tobacco.smokelessTobaco || tobacco.smokelessTobaccoPerDay ? (
                                                                        <span>
                                                                            <li className={styles.dataTitle}>Smokeless Tobacco Per Day: {tobacco.smokelessTobaccoPerDay}</li>
                                                                        </span>
                                                                    ) : null}
                                                            </Grid>
                                                        </span>
                                                    ) : null}
                                                </Grid>
                                            ) : null}
                                            {alcohol != null ? (
                                                <Grid item xs={12} sm={3}>
                                                    <div className={styles.dataTitle2}>Alcohol</div>
                                                    {alcohol.drinkAlcohol=="socially" || alcohol.drinkAlcohol=="ocasionally" || alcohol.drinkAlcohol=="daily" ? (
                                                        <Grid container direction="row" justify="space-between" xs={6}>
                                                            <span>
                                                                <li className={styles.dataTitle}>Drinks Alcohol: {alcohol.drinkAlcohol}</li>
                                                                {alcohol.numberOfDrinksPerDay!="" ? (
                                                                    <span>
                                                                        <li className={styles.dataTitle}>Drinks Per Day: {alcohol.numberOfDrinksPerDay}</li>
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {alcohol.numberOfDrinksPerWeek!="" ? (
                                                                            <span>
                                                                                <li className={styles.dataTitle}>Drinks Per Week: {alcohol.numberOfDrinksPerWeek}</li>
                                                                            </span>
                                                                        ) : (
                                                                            <span>
                                                                                {alcohol.numberOfDrinksPerMonth !="" ? (
                                                                                    <li className={styles.dataTitle}>Drinks Per Month: {alcohol.numberOfDrinksPerMonth}</li>
                                                                                ) : null}
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                )}
                                                                {alcohol.drinkType!='' || alcohol.drinkType!=null ? (
                                                                    <li className={styles.dataTitle}>Alcohol Type: {alcohol.drinkType}</li>
                                                                ) : null}
                                                            </span>
                                                        </Grid>
                                                    ) : null}
                                                    {alcohol.drinkAlcohol=="never" ? (
                                                        <li className={styles.dataTitle}>Never drinks alcohol</li>
                                                    ) : null}
                                                    {alcohol.drinkAlcohol=="former" ? (
                                                        <li className={styles.dataTitle}>Year quit alcohol: {alcohol.yearQuitAlcohol}</li>
                                                    ) : null}
                                                </Grid>
                                            ) : null}
                                            {used_illegal_drugs!= null ? (
                                                <Grid item xs={12} sm={3}>
                                                    <div className={styles.dataTitle2}>Recreational Drugs</div>
                                                    {used_illegal_drugs.illegal_drugs ? (
                                                        <li className={styles.dataTitle}>Does use {used_illegal_drugs.recreationalDrugType}</li>
                                                    ) : (<li className={styles.dataTitle}>Doesn't use illegal or recreational drugs</li>)}
                                                </Grid>
                                            ) : null}
                                            {activity_level!= null ? (
                                                <Grid item xs={12} sm={3}>
                                                    <div className={styles.dataTitle2}>Activity</div>
                                                    <li className={styles.dataTitle}>{activity_level.activity}</li>
                                                </Grid>
                                            ) : null}
                                            {travel != null  && (travel.travelOverseas ==="true") ? (
                                                <span>
                                                    <div className={styles.dataTitle2}>Travel</div>
                                                    <Grid container direction="row" justify="space-between" xs={6}>
                                                        <li className={styles.dataTitle}>Did Travel overseas in the past 3 months</li>
                                                        {travel.cityTraveled !="" ? (
                                                            <li className={styles.dataTitle}>Traveled to {travel.cityTraveled}, {travel.countryTraveled}</li>
                                                        ) : null}
                                                        {travel.periodTraveled !="" ? (
                                                            <li className={styles.dataTitle}>Period of stay: {travel.periodTraveled}</li>
                                                        ) : null}
                                                    </Grid>
                                                </span>
                                            ) : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                
                                </Grid>
                            </span>
                        ) : (
                            <Grid item xs={12} sm={2} style={{marginTop:'20px'}}>
                                <button className={styles.boton} onClick={() => setPastMedShow(true)}>Show Medical History</button>
                            </Grid>
                        )}
                    </Grid>
                     
                    // <Grid item xs={12} md={12} container className={styles.pastMedialHistoryContainer}>
                    //     <div className={`${styles.subtitleContainer} ${styles.pastMedialHistory}`} style={{marginTop: "4vh"}}>
                    //         {/*<div className={styles.subtitle}>Past medical history</div>*/}
                    //     </div>
    
                    //     <Grid container spacing={0} >
                    //         <Grid item xs={12} className={styles.dataContainer} style={{display: "flex", justifyContent: "space-between"}}>
                    //             {/*<Button variant="primary" onClick={() => setModalShow(true)}>
                    //                 View Past medical history
                    //             </Button>*/}
    
                    //             <HistoryComplement 
                    //                 show={modalShow}
                    //                 onHide={() => setModalShow(false)}
                    //                 TEST TEST TEST
                    //             />
                    //         </Grid>
                    //     </Grid>
                    //     <Grid item xs={12} md={12} lg={12} container>
                    //         <Grid item xs={12} md={12}>
                    //             <h3 className={styles.subtitle}>Past medical history</h3>
                    //         </Grid>
                    //         <Grid item xs={12} md={3}>
                    //             <div className={styles.dataLeftMargin}>
                    //                 <div className={styles.dataTitle}>Reason for consultation</div>
                                    // <div className={styles.mobileInput}>
                                    //     {userMedInfo.reason.reason_info.reasonInfo ? (
                                    //         <TextField type="text" defaultValue={userMedInfo.reason.reason_info.reasonInfo} className={classes.inputRead} readOnly />
                                    //     ) : (
                                    //         <TextField type="text" defaultValue={userMedInfo.reason.reason_info.reasonInfo} className={classes.inputRead} readOnly />
                                    //     )}
                                    // </div>
                    //             </div>
                    //         </Grid>
                    //         <Grid item xs={12} md={3}>
                    //             <div className={styles.dataLeftMargin}>
                    //                 <div className={styles.dataTitle}>Allergies</div>
                    //                 <div className={styles.mobileInput}>
                    //                     {userMedInfo.allergies.doesHaveAllergy == "true" ? (
                    //                         <TextField type="text" defaultValue={userMedInfo.allergies.allergyName} className={classes.inputRead} readOnly />
                    //                     ) : (
                    //                         <TextField type="text" defaultValue="" className={classes.inputRead} readOnly />
                    //                     )}
                    //                 </div>
                    //             </div>
                    //         </Grid>
                            
                    //     </Grid>
                    // </Grid>
                }

            </Grid>
        </>
    );
}

const ReactHookFormSelect = ({
    name,
    label,
    control,
    defaultValue,
    children,
    ...props
  }) => {
    const labelId = `${name}-label`;
    return (
      <FormControl {...props}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          as={
            <Select labelId={labelId} label={label}>
              {children}
            </Select>
          }
          name={name}
          control={control}
          defaultValue={defaultValue}
        />
      </FormControl>
    );
  };

const Hx = ({ section, onClick, }) => {
    if (section == "service") {
        return (
            <div className={styles.hxTitle}>
                Hx
                <span className={styles.write} onClick={onClick}><CreateIcon color="primary" fontSize="small" /></span>
                <SaveButton /*onClick={ handleSubmit(saveMethod)}*/ />
            </div>
        )
    }
    return null
}

const EditLogo = ({ section, onClick }) => {
    if (section == "profile") {
        return (
            <span className={styles.write} onClick={onClick}><CreateIcon color="primary" fontSize="small" /></span>
        )
    }
    return null
}

const SaveButton = ({ editStatus, onClick }) => {
    const [t, i18n] =useTranslation("home");
    if ( !editStatus ) {
        return (
            <button onClick={onClick} className={styles.saveButton}>{t('basic.info.button.save')}</button>
        )
    }
    return null
}

const SaveButtonProfile = ({ editStatus, section, onClick}) => {
    const [t, i18n] =useTranslation("home");
    if ( !editStatus && section == "profile" ) {
        return (
            <button className={styles.saveButtonProfile} onClick={onClick}>{t('basic.info.button.save')}</button>
        )
    }
    return null
}
export default BasicInfo;

