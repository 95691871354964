import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from '@material-ui/core/styles';
//import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import style from '../styles/Disclaimer.module.css'
import TextField from '@material-ui/core/TextField';
import {useTranslation} from "react-i18next";

function TermsConditions(props) {
    const [t, i18n] =useTranslation("home");

    

    return (
      <Modal
        {...props}
        contentClassName= {style.custom3}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t('footer.cont.terms')}</h4>
          <p className={style.subtitulo}>Info Terms and Conditions</p>
          <p>{t('terms.1')}</p>
          <p>{t('terms.2')}</p>
          <p>{t('terms.3')}</p>
          <p>{t('terms.4')}</p>
          <p>{t('terms.5')}</p>
          <p>{t('terms.6')}</p>
          <p>{t('terms.7')}</p>
          <p>{t('terms.8')}</p>
          <p>{t('terms.9')}</p>
          <p>{t('terms.10')}</p>
          <p>{t('terms.11')}</p>
          <p>{t('terms.12')}</p>
          <p>{t('terms.13')}</p>
          <p>{t('terms.14')}</p>
          <p>{t('terms.15')}</p>
          <p>{t('terms.16')}</p>
          <ul>
            <li>{t('terms.16.list.a')}</li>
            <li>{t('terms.16.list.b')}</li>
            <li>{t('terms.16.list.c')}</li>
            <li>{t('terms.16.list.d')}</li>
            <li>{t('terms.16.list.e')}</li>
            <li>{t('terms.16.list.f')}</li>
            <li>{t('terms.16.list.g')}</li>
            <li>{t('terms.16.list.h')}</li>
            <li>{t('terms.16.list.i')}</li>
            <li>{t('terms.16.list.j')}</li>
            <li>{t('terms.16.list.k')}</li>
            <li>{t('terms.16.list.l')}</li>
            <li>{t('terms.16.list.m')}</li>
          </ul>
          <p>{t('terms.17')}</p>
          <p>{t('terms.18')}</p>
          <p>{t('terms.19')}</p>
          <p>{t('terms.20')}</p>
          <p>{t('terms.21')}</p>
          <p>{t('terms.22')}</p>
          <p>{t('terms.23')}</p>
          <p>{t('terms.24')}</p>
          <p>{t('terms.25')}</p>
          <p>{t('terms.26')}</p>
          <p>{t('terms.27')}</p>
          <p>{t('terms.28')}</p>
          <p>{t('terms.29')}</p>
          <p>{t('terms.30')}</p>
          <p>{t('terms.31')}</p>
          <p>{t('terms.32')}</p>
          <p>{t('terms.33')}</p>
          <p>{t('terms.34')}</p>
          <p>{t('terms.35')}</p>
          <p>{t('terms.36')}</p>
          <p>{t('terms.37')}</p>
          <p>{t('terms.38')}</p>
          <p>{t('terms.39')}</p>
          <p>{t('terms.40')}</p>
          <p>{t('terms.41')}</p>
          <p>{t('terms.42')}</p>
          <p>{t('terms.43')}</p>
          <p>{t('terms.44')}</p>
          <p>{t('terms.45')}</p>
          <p>{t('terms.46')}</p>
          <p>{t('terms.47')}</p>
          <p>{t('terms.48')}</p>
          <p>{t('terms.49')}</p>
          <p>{t('terms.50')}</p>
          
        </Modal.Body>
      </Modal>
    );
  }
  
export default TermsConditions;