import React,  { useState, useEffect, Fragment } from "react";

import Header2 from '../components/Header2';
//import HistoryTable from '../components/HistoryTable';

import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import {useTranslation} from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";
import styles from '../styles/History.module.css';




const Progress = () => {
    const [t, i18n] =useTranslation("home");

    const { isAuthenticated, logout, login } = useAuth0;
    const [pastData, setPastData] = useState([]);
    const [progressData, setProgressData] = useState([])
    const [UserWaitingInfo, setUserWaitingInfo] = useState({});

    let userinfo = JSON.parse(localStorage.getItem('user_info'));
    console.log(userinfo.user)
    

    const viewSAddendum = (id, order_id, pin_code, doctor_id, user_id) => {


        console.log(doctor_id)
        console.log(id, order_id, pin_code, doctor_id, user_id)
        
    }
    const viewService = (id, order_id, pin_code, doctor_id, user_id) => {


        let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
        fetch('https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/cases/'+`${id}`,{
            method:'PATCH',
            headers:{
                Authorization: `Bearer ${storedSession.idToken}`,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            },
            body:JSON.stringify({"status": "IN_PROGRESS"})
        })
        .then( response => response.json())
        .then(result =>{
            console.log("Go to medical case");
            var newURL = "/service?id="+`${id}`+"&order_id="+`${order_id}`+"&pin_code="+`${pin_code}`+"&doctor_id="+`${doctor_id}`+"&pid="+`${user_id}`+"";
            //console.log(newURL);
            window.location.href=newURL;
            /*router.push({
                pathname: '/service',
                query:{
                    id: `${id}`,
                    order_id: `${order_id}`,
                    pin_code: `${pin_code}`,
                    doctor_id: `${doctor_id}`,
                    pid: `${user_id}`
                },
                
            })*/
        })
        .catch(error =>{
            console.log("ERROR ?",error)
        })

        
    }

    useEffect(() => {
        /*if (!isAuthenticated) {
            router.push('/');
        }*/
        if(false){
            window.location.href="/";
            
        }else{
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            try {
                async function fetchWaiting(){
                    await fetch(`https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/past-cases?page=${1}&size=${100}`, {
                        method: 'GET',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        setPastData(data.items);
                        //console.log(data);
                        
                        
                    })
                    .catch( error_reason => {
                        console.log(error_reason)
                    })

                }
                fetchWaiting();

            } catch (error) {
                console.log("ERROR", error);
            }
        }
    }, []);


    //Aqui se le debe cambiar al tipo de contacto que se va a realizar y agregar numeros o campos
    const renderBody = () => {
        return pastData.map(({ id, first_name, last_name, status, pin_code, updated_at, minutes, phone, user_id, order_id, doctor_name, doctor_last, doctor_id, }) => {
            if (status === "IN_PROGRESS" && userinfo.user.id === doctor_id){
                return (
                    <tr key={id} className={styles.fila}>
                    <td>{first_name} {last_name}</td>
                    <td>{pin_code}</td>
                    <td>Dr. {doctor_name} {doctor_last}</td>
                    <td>
                        {(() => {
                            if (phone == null){
                                return(
                                    "not available"
                                )
                            }
                            else{
                                if (phone.mobile_number != null){
                                    return(
                                        phone.mobile_number
                                    )
                                }
                                if(phone.phone_number != null){
                                    return(
                                        phone.phone_number
                                    )
                                }
                                if(phone.mobile_number != null && phone.phone_number != null){
                                    return(
                                        phone.mobile_number + "<br/>" + phone.phone_number
                                    )
                                }
                            }
                        })()}
                    </td>
                    <td>In progress</td>
                    <td><button className={styles.boton6} onClick={() => viewService(id, order_id, pin_code, userinfo.user.id, user_id )}>{t('doctor.table.cont.continue')}</button></td>
                    {/* <td>
                        {(() => {
                            var endDate = new Date(updated_at);
                            //alert(endDate.toLocaleDateString());
                            var endDatelocale = endDate.toLocaleDateString();
                            if(endDate){
                                return (
                                    endDatelocale
                                )
                            }
                        })()}
                    </td> */}
                    
                </tr>
                )
            }
        })
    }


    return (
        <div className={styles.container}>
            <Header2  userType={userinfo.user.user_type} page={"doctor"}/>
            <div className={styles.linea}>
                <div className={styles.barrab}></div>
                <h4 className={styles.sub}>{t('history.a.subtitle.latest')}</h4>
            </div>
            <div className={styles.tab}>
            <div className={styles.barrab}></div>
            <div className={styles.tabla2}>
                <table id="myTable" className={styles.a}>
                    <tr>
                        <th>{t('history.a.th.name')}</th>
                        <th>{t('history.a.th.consult.code')}</th>
                        <th>{t('history.a.th.type.contact')}</th>
                        <th>{t('history.a.th.contact')}</th>
                        <th>{t('history.a.th.call.duration')}</th>
                    </tr>
                    {renderBody()}
                </table>
            </div>
        </div>
        </div>
    )
}

export default Progress;