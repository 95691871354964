import React, { useEffect } from 'react';
import styles from '../styles/Landing.module.css';
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, Grid, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";


//Custom breakpoints
const theme = createMuiTheme({
    breakpoints: {
      values:{
        xs: 0,
        sm: 425,
        md: 769,
        lg: 1250,
        xl: 1920,
      }
    },
  })


const CustomButton = withStyles({
    root:{
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: 'rgb(65, 181, 210)',
        color: '#fff',
        height: '110px',
        width: '360px',
        borderRadius: '50px 50px 50px 50px',
        fontFamily: 'Lucida Grande',
        fontSize: '25px',
        bottom: '40px',
        margin: '0 auto',
        display: 'block',
        '&:hover':{
        backgroundColor: '#0069d9',
        },
        '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
        },
        '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '@media screen and (max-width:350px)':{
            width: '320px'
        }
    }
})(Button);
const CustomButton2 = withStyles({
    root:{
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: 'rgb(65, 181, 210)',
        color: '#fff',
        height: '70px',
        width: '300px',
        borderRadius: '50px 50px 50px 50px',
        fontFamily: 'Lucida Grande',
        fontSize: '25px',
        bottom: '40px',
        margin: '0 auto',
        display: 'block',
        '&:hover':{
        backgroundColor: '#0069d9',
        },
        '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
        },
        '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '@media screen and (max-width:350px)':{
            width: '320px'
        }
    }
})(Button);

const useStyles = makeStyles({
    root:{
        width: '70%',
        height: '650px',
        backgroundColor: '#fff',
        position: "static",
        margin: '2em auto 0 auto',
        borderRadius: '25px 25px 25px 25px',
        MozBorderRadius: '25px 25px 25px 25px',
        boxShadow: '5px 4px 6px 5px rgba(0,0,0,0.15)',
        padding: '2.5rem',
        "@media (max-width: 780px)":{
            width:'90%',
            height:'800px'
        },
        "@media (max-width: 500px)":{
            height:'900px',
            padding:'5px'
        },
        "@media (max-width: 320px)":{
            height:'1000px'
        },

    },
    title:{
        fontSize: 35,
        textAlign: 'center',
        color: 'var(--azulPrincipal)',
        fontFamily: 'Lucida Grande',
        fontWeight: 'bold',
        marginBottom: '2rem',
    },
    text:{
        fontSize: 22,
        fontFamily: 'Lucida Grande',
        fontWeight: 'bold',
        textAlign: 'left'
    },  
    span:{
        fontSize: 22,
        fontFamily: 'Lucida Grande',
        
    },
    div:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        fontFamily: 'Lucida Grande',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        fontSize: '20px',
    },
    col1:{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Lucida Grande',
    },
    col2:{
        display: 'flex',
        flexDirection: 'column',
    },
    col3:{
        display: 'flex',
        flexDirection: 'column',
    },
    col4:{
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer:{
        margin: "20px 0px",
        "@media (max-width: 780px)":{
            flexWrap:'wrap'
        }
    }
})

const useStyles2 = makeStyles({
    title:{
      fontSize: '25px',
      height: '30px',
      fontFamily: 'Lucida Grande',
      "@media (max-width: 470px)":{
        fontSize: '20px'
    },
    },
    usd:{
      fontSize: '32px',
      height: '50px',
      fontFamily: 'Lucida Grande',
      fontWeight: 'bold'
    }
    
  })

const LandingCard = () => {
    const [t, i18n] =useTranslation("home");
    
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        
    }, [])

    const classes = useStyles();

    //Se importan los estilos de makeStyles oara usar en el botonUSD
    const btnclasses = useStyles2();

    return (  
        <>
        <CustomButton2>
            <div className={styles.fakebutton2} onClick={() => loginWithRedirect()}>
                <span className={btnclasses.title}>{t("home.btn.register")}</span> 
            </div>
        </CustomButton2>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} variant="h5" component="h2">
                   {t("home.subtitle.the.service")}
                </Typography>
                <Typography className={classes.text} variant="h6" component="h2">
                    {t("home.cont.the.service.blod")}
                </Typography>
                <Typography className={classes.span} variant="h6" component="h2" align='left'>
                    {t("home.cont.the.service")}
                </Typography>
                <Grid container wrap={"nowrap"} className={classes.gridContainer}>
                    <Grid container wrap={"nowrap"} item sm={12}>
                        <Grid item xs={6} >
                            <div className={classes.col1}>
                                <p>{t("home.li.condition.1")}</p>
                                <p>{t("home.li.condition.2")}</p>
                                <p>{t("home.li.condition.3")}</p>
                                <p>{t("home.li.condition.4")}</p>
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div className={classes.col2}>
                                <p>{t("home.li.condition.5")}</p>
                                <p>{t("home.li.condition.6")}</p>
                                <p>{t("home.li.condition.7")}</p>
                                <p>{t("home.li.condition.8")}</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container wrap={"nowrap"} item sm={12}>
                        <Grid item xs={12}>
                            <div className={classes.col3}>
                                <p>{t("home.li.condition.9")}</p>
                                <p>{t("home.li.condition.10")}</p>
                                <p>{t("home.li.condition.11")}</p>
                                <p>{t("home.li.condition.12")}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.col4}>
                                <p>{t("home.li.condition.13")}</p>
                                <p>{t("home.li.condition.14")}</p>
                                <p>{t("home.li.condition.15")}</p>
                                <p>{t("home.li.condition.16")}</p>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
                {/* <div className={classes.div}>
                    <div className={classes.col1}>
                        <p>Allergies</p>
                        <p>Cold / Flu</p>
                        <p>Constipation</p>
                        <p>Cough</p>
                    </Grid>
                    <Grid item className={classes.col2} sm={3} md={3} lg={3}>
                        <p>Ear Problems</p>
                        <p>Eye Infection</p>
                        <p>Fever</p>
                        <p>Headache</p>
                    </Grid>
                    <Grid item className={classes.col3} sm={3} md={3} lg={3}>
                        <p>Nausea</p>
                        <p>Rash / Skin Infection</p>
                        <p>Respiratory Problems</p>
                        <p>Sinus Infections</p>
                    </Grid>
                    <Grid item className={classes.col4} sm={3} md={3} lg={3}>
                        <p>Sore Throat</p>
                        <p>Stomachache / Diarrhea</p>
                        <p>Urinary Tract Infection</p>
                        <p>Vomiting</p>
                    </div>
                </div> */}
            </CardContent>
            
        </Card>
        <CustomButton>
            <div className={styles.fakebutton} onClick={() => loginWithRedirect()}>
                <span className={btnclasses.title}>{t("home.btn.buy.home")}</span> 
                <span className={btnclasses.usd}>{t("home.btn.buy.home.value")}</span>
            </div>
        </CustomButton>
        </>
    );
}
 
export default LandingCard;