import React,  { useState, useEffect, Fragment } from "react";

import { Grid, Container, withStyles, createMuiTheme, Avatar, Button, AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AddAPhoto, BorderAll, DriveEta, Edit } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link, useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";

import LogoutButton from "../components/logout-button-1";

import BasicInfo from '../components/BasicInfo';
import UpdateInfo from '../components/UpdateInfo';
import RequestCall from '../components/UI/RequestCall';
import { fetchMedInfoUser, saveBasicInfoDataUser } from '../utils/apiCalls'

import styles from '../styles/Profile.module.css';

import { NavBar, Footer, Loading } from "../components";

import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
    avatar:{
        width: '150px',
        height: '150px',
        left: '25px',
        top: '10px',
        position: 'relative',
    },
    addPhoto:{
        position: 'absolute',
        left: '25px',
        top: '10px',
        color: 'white'

    },
    btnRequest:{
        background: 'var(--azulPrincipal)',
        borderRadius: 66,
        fontFamily: 'Lucida Grande',
        color: 'var(--blanco)',
    },
    container:{
        width: '80%',
        height: '100%'
    },
    edit:{
        color: '#1C5EA9',
    },
    blueDrawer:{
        backgroundColor: '#1E5EA8',
        width: '15%'
    },
    
})



const UserProfile = () => {
    const [t, i18n] =useTranslation("home");
    const { isAuthenticated, logout, login } = useAuth0;

    //instanciamos la clase de avatar 
    const classes = useStyles();

    const callLogout = () => {
        logout( `${window.location.origin}/`)
    }
    
    const [modalShow, setModalShow] = useState(true);
    const [modalCallShow, setModalCallShow] = useState(false);

    const [ userinfo, setUserInfo ] = useState();
    const [ userMedInfo, setUserMedInfo ] = useState();

    
    //Obtenemos el token del usuario
    let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));

    useEffect(() => {

        //Declaramos el endpoint
        let url = 'https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user';


        // if(!isAuthenticated){
        //     router.push('/');
            
        // }
        if(false){
            window.location.href="/";
            
        }
        else{
            //Fetch API
            try {
                async function fetchUser(){
                    await fetch(url, {
                        method: 'GET',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        setUserInfo(data.user);
                        console.log(data.user)
                    })
                } 
                
                fetchUser();

                fetchMedInfoUser(storedSession.idToken).then((userMedInfo) => {
                    setUserMedInfo(userMedInfo.medical_data)
                    console.log("user med info")
                    console.log(userMedInfo)
                })

            } catch (error) {
                console.log("ERROR ?",error);
            }
        }
    }, []);

    return(
      <main>
            <AppBar position="static" color="transparent" elevation={0} className={styles.blueAppBar}>
                <Toolbar className={styles.blueToolBar}>
                    <div className={styles.contP}>
                       <div className={styles.availableDoctorContainer}>
                            <div className={`${styles.status} ${styles.availableDoctorIndicator}`}></div>
                            <h1 className={styles.link}>{t('userprofile.title.doctor')}</h1>
                        </div>
                        <button className={styles.boton} onClick={() => setModalCallShow(true)}>{t('userprofile.button.request')}</button>
                        <RequestCall show={modalCallShow} onHide={() => setModalCallShow(false)}/> 
                    </div>
                    <div className={styles.btnLogoutProfile}>
                        <LogoutButton />
                    </div>
                    <div className={styles.trad}>
                        <button onClick={() => i18n.changeLanguage("es")} >ESPAÑOL</button>
                        <button onClick={() => i18n.changeLanguage("en")} >ENGLISH</button> 
                    </div>
                </Toolbar>
            </AppBar>

            {/* Primer hidden para cuando la pantalla es chica xsDown: desactivado*/}
            <Hidden xsDown>
                <Drawer
                    variant="permanent"
                    anchor="left"
                    classes={{
                        paper: classes.blueDrawer,
                    }}
                >
                    <img src="/LogoBlanco.png" alt="Meditel Logo" className={styles.meditelWhiteLogo}/>
                </Drawer>
            </Hidden>
            
            <div className={styles.mainContainer}>
                <div className={styles.welcome}>
                    {userinfo && 
                        <div>
                            {t('userprofile.cont.wellcome')} {`${userinfo.first_name} ${userinfo.last_name}`}!
                        </div> }
                    <Button className={styles.requestButtonMobile}>
                        <Link href="/payment">
                            <a className={styles.requestButtonText}>{t('userprofile.cont.call.now')}</a>
                        </Link>                                
                    </Button>
                </div> 
                    {userinfo && userMedInfo && <BasicInfo userinfo={userinfo} userMedInfo={userMedInfo} section={"profile"} saveBasicInfoData={(userinfo, userMedInfo) => {
                        saveBasicInfoDataUser( userinfo, userMedInfo, storedSession.idToken )
                    }} />}
                <Grid container>
                    <UpdateInfo show={modalShow} onHide={() => setModalShow(false)}/>
                    <div style={{ width: "100%" }}>
                        {/* <CallTable auth={auth} style={{ width: "100%" }}/> */}
                    </div>
                </Grid>
            </div>
      </main>
    );
}

export default UserProfile;
