import React from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import style from '../../styles/Disclaimer.module.css'

function WaitingLine(props) {
    const [t, i18n] =useTranslation("home");
    return (
      <Modal
        {...props}
        contentClassName= {style.custom2}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t('waiting.line.title.waiting.line')}</h4>
          <p className={style.parrafo}>{t('waiting.line.cont.waiting.line')}</p>
        </Modal.Body>
      </Modal>
    );
  }
  
export default WaitingLine;