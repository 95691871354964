// src/components/logout-button.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles'
import { BorderRight } from "@material-ui/icons";

import styles from '../styles/Landing.module.css';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <button
      className={styles.btnLogout}
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Logout<ExitToAppIcon style={{ fontSize: 45 }}/>
    </button>
  );
};

export default LogoutButton;