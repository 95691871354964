import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from '@material-ui/core/styles';
//import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import style from '../../styles/EMR.module.css'
import TextField from '@material-ui/core/TextField';
import {useTranslation} from "react-i18next";

function EMR(props) {
    const [t, i18n] =useTranslation("home");
    const useStyles = makeStyles({
        inputAnswer:{
            width:"100%",
            height: "65px",
            backgroundColor: "#F8F8F8",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            marginLeft: .2+ "rem",
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "Lucida Grande",
            disableUnderline: true,
            'padding-left':"10px",
            'padding-right':"10px"
        }

    })
    const classes = useStyles();


    console.log("Popup", props.userMedInfo)
    //Destructuring
    // console.log("Med Info:", props.userMedInfo.id)
    // console.log("Activity", props.userMedInfo.alcohol.drinkAlcohol)
    const {userMedInfo} = props
    // console.log("Destruct", userMedInfo)
    const {
        activity_level, alcohol, allergies, family_medical_history, height_data, medication, 
        pre_existing_conditions, reason, tobacco, travel, used_illegal_drugs, weight_data
    } = userMedInfo
    const {reason_info} = reason
    const{reasonInfo} = reason_info
    // console.log("Destruct alcohol", alcohol)

    

    return (
      <Modal
        {...props}
        dialogClassName={style.dialog}
        contentClassName= {style.custom}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t('emr.title.reason')}</h4>
          <h4 className={style.titulo}><TextField multiline defaultValue={reasonInfo} InputProps={{ readOnly: true, disableUnderline: true, className: classes.inputAnswer }} className={classes.inputAnswer}/></h4>
          {/* {(pre_existing_conditions!="" || pre_existing_conditions==null) ? (
              <h3 className={style.subtitulo}>Past Medical History</h3>
          ) : null} */}
          <div className={style.justify}><Button className={style.buton} onClick={props.onHide}>{t('emr.button.continue')}</Button></div>
        </Modal.Body>
      </Modal>
    );
  }
  
export default EMR;