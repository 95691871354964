import React from 'react';
import Modal from "react-bootstrap/Modal";
//import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import style from '../styles/Disclaimer.module.css'

function UpdateInfo(props) {
  const [t, i18n] =useTranslation("home");
  return (
    <Modal
      {...props}
      contentClassName= {style.custom3}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className={style.titulo}>{t('update.info.title.welcome')}</h4>
        <h4 className={style.subtitulo}>{t('update.info.subtitle.info.medical')}</h4>
        <p className={style.parrafo}>{t('update.info.cont.important')}</p>
        <div className={style.justify2}><Button className={style.buton2} onClick={props.onHide}>{t('update.info.button.continue')}</Button></div>
      </Modal.Body>
    </Modal>
  );
}
  
export default UpdateInfo;
