import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import WaitingLine from '../components/UI/WaitingLine';
import styles from '../styles/Book.module.css';
import CreateIcon from '@material-ui/icons/Create';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useAuth0 } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
//import { NavBar, Footer, Loading } from "../components";

import { deleteForm } from '../utils/storage';

export default function Book() {
    const [t, i18n] =useTranslation("home");
    const { isAuthenticated, logout, login } = useAuth0();
    const [modalShow, setModalShow] = React.useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [ userinfo, setUserInfo ] = useState({});
    // const [showSpinner, setShowSpinner] = useState(true);

    const [ medCase, setCase] = useState({});

    //Get Query String
    function getQueryVariable(variable){
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
    }

    console.log('test auth', isAuthenticated);


    useEffect(() => {
       let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
       const order = getQueryVariable('chid');
       let idorder;
       if(order){
        idorder = {order_id: order};
       }
       else{
        window.location.href = `${window.location.origin}/`
       }
       

            //Fetch API
            try {
                async function fetchCase(){
                    await fetch('https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user/med-case', {
                        method: 'POST',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        },
                        body: JSON.stringify(idorder)
                    })
                    .then(response => response.json())
                    .then(data => {
                        
                        console.log("pppin code: ", data.medical_case.pin_code);
                        localStorage.setItem("consult_code", JSON.stringify(data.medical_case));
                        setCase(data.medical_case?.pin_code);
                    })
                    .catch(error =>{
                      console.log("ERROR payment =>",error)
                    });
                }
                async function fetchUser(){
                    await fetch('https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user', {
                        method: 'GET',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        setUserInfo(data.user);
                    })
                    // .then((res) =>{
                    //     setShowSpinner(false)
                    // })
                } 
                
                fetchUser();
                fetchCase();
                deleteForm();
            } catch (error) {
                console.log("ERROR", error);
            }
    },[ ]);

    const { first_name, last_name, phone, username } = userinfo;
    const { med_case_id } = medCase;
    console.log('medCase ', medCase);
    console.log('med_case_id ', med_case_id);


    const ShowSave = (status) => {
            if(status == 'show'){
                //let element = document.getElementsByClassName('hiddenItem');
                //ReactDOM.findDOMNode(element).classList.remove('hiddenItem');
                return(
                    console.log("lo va a mostrar")
                )
            }
    }
     //Update phone number
     const [phoneUpdate, setPhone] = useState({
        phoneNumber:''
    })

    const handlePhone = e => {
        setPhone({
            ...phoneUpdate,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==> phone change",e.target.value,phoneUpdate)
    }

    //On click continue
    const handleSubmit = (e) => {
        e.preventDefault();
        let prevUser = JSON.parse(localStorage.getItem('user_info'))
        let prevUserInfo = prevUser.user
        let responseMed = {
            phone: {
                phone_number: phoneUpdate.phoneNumber
            }
        }
        let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
        const address = `https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/`;
        fetch(address+'user',{
            method:'PATCH',
            headers:{
                Authorization: `Bearer ${storedSession.idToken}`,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            },
            body:JSON.stringify(responseMed)
        })
        .then( response => response.json())
        .then(result =>{
            console.log("Update User Phone", result)
        })
        .catch(error =>{
            console.log("ERROR ?",error)
        })
        //Hide save button
        setEditStatus(false)
    }
    

    return (
        <>
            {/* {showSpinner ? (
                <Loading />
            ) : ( */}
                <div className= {styles.padre}>
                    <header>
                        <title>Meditel24</title>
                        <link rel="shortcut icon" href="/faviconMeditel.ico" />
                    </header>
                    <div className={styles.primerhijo}>
                        <Header/>
                    </div>
                    <div container direction="column" className={styles.segundohijo}>
                        <Grid  item xs={12} sm={6} direction="row"><img src="/Dr.png" alt="View" className={styles.drImage}/></Grid>
                        <Grid  item xs={12} sm={6} direction="row">
                            <div className={styles.info}>
                                <div className={styles.titulo1}>
                                    <h1 className={styles.titulo}>{t('book.title.hi')}{first_name}</h1>
                                        {/* {(() => {
                                            if(showSpinner){
                                                return(
                                                    <CircularProgress />
                                                )
                                            }
                                            else{
                                                if(medCase.length ==6){
                                                    return(<h1 className={styles.titulo}>{t('book.cont.your.consult')} {medCase} </h1>)
                                                }
                                                else{
                                                    return(<h1 className={styles.titulo}>{t('book.cont.sorry.code.use')}</h1>)
                                                }
                                                return(
                                                    <h1 className={styles.titulo}>{t('book.cont.sorry.code.use')}</h1>
                                                )
                                            }
                                        })()} */}
                                        {(() => {
                                            if(medCase.length == 6){
                                                console.log("leng: ", medCase.length);
                                                return(
                                                    <h1 className={styles.titulo}>{t('book.cont.your.consult')} {medCase} </h1>
                                                )
                                            }
                                            else{
                                                return(
                                                    <h1 className={styles.titulo}>{t('book.cont.sorry.code.use')}</h1>
                                                )
                                            }
                                        })()}
                                </div>
                                <div className={styles.parrafo1}>
                                    <p>{t('book.cont.thanks.choosing')}</p>
                                </div>
                                <div className={styles.espacio}></div>
                                <div className={styles.botlinea2}>
                                    <h2 className={styles.subtitulo}>{t('book.cont.phone.preferece')}</h2>
                                    <div className={styles.espacio}></div>
                                    {phone ? (
                                        <input pattern="[0-9]*" className={styles.phone} defaultValue={phone.phone_number} minLength="10" maxLength="12" onChange={handlePhone} name="phoneNumber"/>
                                        ) : (
                                        <input pattern="[0-9]*" className={styles.phone} minLength="10" maxLength="12" />
                                    )}

                                {/* Cambios Hellem
                                    <CreateIcon color="primary" fontSize="small" onClick={() => ShowSave('show')} />
                                    <button className={styles.saveButton}>{t('book.button.save')}</button>
                                */}

                                {editStatus ? (
                                    <button className={styles.saveButton} onClick={handleSubmit}>Save Changes</button>
                                ) : (
                                    <CreateIcon color="primary" fontSize="small" onClick={() => setEditStatus(true)} />
                                )}
                                </div>
                                <div className={styles.espacio}></div>
                                <div className={styles.linea}>
                                    <p className={styles.parrafo2}>{t('book.cont.privacity')}</p>
                                </div>
                                <div className={styles.linea3}>
                                    <div className={styles.doctorAvailableContainer}>
                                        <FiberManualRecordIcon fontSize="large" style={{ color:'#3F9947' }}/>
                                        <h2 className={styles.subtitulodr}>{t('book.subtitle.doctor.available')}</h2>
                                    </div>
                                    <button className={styles.boton} onClick={() => setModalShow(true)}>{t('book.button.call.now')}</button>
                                    <WaitingLine show={modalShow} onHide={() => setModalShow(false)}/>
                                </div>
                                <div className={styles.drImageMobile}></div>
                            </div>
                        </Grid>
                    </div>
                </div>
            {/* )} */}

        </>
    )
}