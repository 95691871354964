import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useTranslation} from "react-i18next";
import LogoutButton1 from "./logout-button-1";
import styles from '../styles/Header2.module.css';

const Header2 = ({userType,page}) => {
    const [t, i18n] =useTranslation("home");
    const { isAuthenticated, logout, login } = useAuth0();
    const [linkShow,updateLink] = useState({
        link:"/",
        type:""
    })

    const[pathHistory, setPathHistory] = useState();

    const callLogout = () => {
        logout( `${window.location.origin}/`)
    }


    useEffect(() => {
        console.log("USER TYPE ==>",userType)
        // let user = JSON.parse(localStorage.getItem("user_info"))
        // let userData = user.user;
        // let userType = userData.user_type
        if (userType == "admin"){
            setPathHistory("/history-a")
            updateLink({link:"/history-a",type:"admin"})
        }
        else {
            setPathHistory("/history-b")
            updateLink({link:"/history-b",type:"doctor"})
        }
    }, [])

    return ( 
        <div className={styles.container}>
            <div className={styles.doctornavcont}>
                <img src="/Meditel24.png" alt="logo"/>
            </div>
            
                {(() => {
                    if (window.location.href.indexOf("doctor") > -1){
                        return(
                            <div className={styles.doctornavcont}>
                                <span>
                                    <Link className={styles.aDesact} to={{pathname:"/doctor",query:{userType:linkShow.type}}} >
                                         <a  className={styles.selected}>{t('header.2.cont.waiting')}</a>
                                    </Link>
                                </span>
                                <span>
                                    <Link className={styles.aDesact} to={{pathname:"/progress"}} >
                                        <a  className={styles.list}>{t('header.2.cont.progress')}</a>
                                    </Link>
                                </span>
                                <span>
                                    <Link className={styles.aDesact} to={{pathname:pathHistory}} >
                                        <a  className={styles.list}>{t('header.2.cont.history')}</a>
                                    </Link>
                                </span>
                                <LogoutButton1 />
                                <div className={styles.trad}>
                                    <button onClick={() => i18n.changeLanguage("es")} >ESPAÑOL</button>
                                    <button onClick={() => i18n.changeLanguage("en")} >ENGLISH</button>
                                </div>
                            </div>
                        )
                    }
                    else{
                        return (
                            <div className={styles.doctornavcont}>
                                <span>
                                    <Link to={{pathname:"/doctor",query:{userType:linkShow.type}}} >
                                         <a  className={styles.list}>{t('header.2.cont.waiting')}</a>
                                    </Link>
                                </span>
                                <span>
                                    <Link className={styles.aDesact} to={{pathname:"/progress"}} >
                                        <a  className={styles.list}>{t('header.2.cont.progress')}</a>
                                    </Link>
                                </span>
                                <span>
                                    <Link to={{pathname:pathHistory}} >
                                        <a  className={styles.selected}>{t('header.2.cont.history')}</a>
                                    </Link>
                                </span>
                                <LogoutButton1 />
                                <div className={styles.trad}>
                                    <button onClick={() => i18n.changeLanguage("es")} >ESPAÑOL</button>
                                    <button onClick={() => i18n.changeLanguage("en")} >ENGLISH</button>  
                                </div>
                            </div>
                        )
                    }
                })()}
                
        </div>
     );
}
 
export default Header2;