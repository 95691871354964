// src/components/nav-bar.js

import React from "react";

import MainNav from "./main-nav";
import AuthNav from "./auth-nav";
import {useTranslation} from "react-i18next";

import styles from "../styles/Navbar.module.css";
import i18next from "i18next";

const NavBar = () => {
  const [t, i18n] = useTranslation("header", "home", "register");

  return (
    <div className="nav-container">
      <nav className={styles.navbar}>
        <div className={styles.containerBtns}>
        	<div className={styles.containerImg} >
        		<img src="/Meditel24.png" className={styles.Logo} />
        	</div>
          <AuthNav />
          <div className={styles.trad}>
                <button onClick={() => i18n.changeLanguage("es")} >ESPAÑOL</button>
                <button onClick={() => i18n.changeLanguage("en")} >ENGLISH</button>  
            </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;