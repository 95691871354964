import React, { Fragment } from "react";

import { Grid, Container, withStyles, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Loading } from "./components";



import { useAuth0 } from "@auth0/auth0-react";





const Callback = () => {

	const {
		isLoading,
		isAuthenticated,
		getIdTokenClaims,
		user
	} = useAuth0();


	if (isAuthenticated) {
		async function asyncCall() {
			const result = await getIdTokenClaims();
			const tokenid = result.__raw;
			const token = {
				idToken: tokenid,
				profile: user
			};
			localStorage.setItem('auth:auth0', JSON.stringify(token));

			let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));

			fetch(`https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user`, {
					headers: {
						Authorization: `Bearer ${storedSession.idToken}`,
						'Content-Type': 'application/json',
						"Access-Control-Allow-Origin": "",
						"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
						//origin: '*'
					},
				}).then(response => response.json())
				.then(data => {
					localStorage.setItem("user_info", JSON.stringify(data));
					const user_type = data.user.user_type;
					console.log(user_type);
					switch (user_type) {
						case "ADMIN":
							console.log('entra admin');
							window.location.href = '/doctor/typea?userType=admin';
							break;
						case "DOCTOR":
							console.log('entra doctor');
							window.location.href = '/doctor/typeb?userType=doctor';
							break;
						case "USER":
							console.log(data.user.first_name);
							if (data.user.first_name) {
								console.log('entra user con nombre');
								window.location.href = '/user/profile';
							} else {
								console.log('entra user sin nombre');
								window.location.href = '/user/register';
							}
							break;
						default:
							console.log('entra default');
							window.location.href = '/user/register';
							break;

					}

				}).catch(error => {
					console.log("ERROR =>", error)
				});
		}
		asyncCall();
	}


	return ( <main >
		<Loading />
		</main>
	);
}

export default Callback;
