import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useAuth0 } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";

//Shopify
import Client from 'shopify-buy';

import { Grid, StylesProvider } from '@material-ui/core';

import styles from '../styles/Payment.module.css';

const Payment = () => {
    const [t, i18n] =useTranslation("home");
    const { isAuthenticated} = useAuth0();
    var webUrl = '';


    useEffect(() => {


        console.log("hello")
        const client = Client.buildClient({
        domain: 'meditel-24.myshopify.com',
        storefrontAccessToken: '61ef59f0c5fba79af5d1c7be3cbf95c2',
        });

        const productId = "6132814119096" ;

        // client.product.fetch(productId)
        // .then((product) => {
        //     const variantId = product.variants[0].id;
        //     console.log(variantId);
        // });



        // Create an empty checkout
        // client.checkout.create().then((checkout) => {
        //     // Do something with the checkout
        //     console.log(checkout);
        // });


        //Prueba 1
        // client.checkout.create(productId).then((checkout) => {
        //     // Do something with the checkout
        //     console.log("1",checkout);
        //     const webUrl1 = checkout.webUrl
        //     console.log(webUrl1);
        // });

        //Prueba 2
        // client.checkout.create(lineItems = {
        //     productId: productId,
        //     quantity: 1
        // }).then((checkout) => {
        //     console.log("2",checkout);
        //     const webUrl2 = checkout.webUrl
        //     console.log(webUrl2);
        // })

        //Prueba 3
        // client.checkout.create(line_items = {
        //     product_id: productId,
        //     quantity: 1
        // }).then((checkout) => {
        //     console.log("3",checkout);
        //     const webUrl3 = checkout.webUrl
        //     console.log(webUrl3);
        // })

        //Prueba 4
        // const lineItems = [
        //     {
        //         'variantId': '39259385790648',
        //         quantity: 1
        //     }
        // ]
        // client.checkout.create(lineItems).then((checkout) => {
        //     console.log("4", checkout)
        //     const webUrl4 = checkout.webUrl
        //     console.log(webUrl4);
        // })

        //Prueba 5
        // client.checkout.create().then((checkout) => {
        //     const checkoutId = checkout.id;
        //     console.log(checkout);
        //     const lineItemsToAdd = [
        //         {
        //             'variantId': '39259385790648',
        //             quantity: 1
        //         }
        //     ];
        //     client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkoutUpdate) => {
        //         const webUrl5 = checkoutUpdate.webUrl
        //         console.log(webUrl5);
        //     })
        // });

        //Prueba 6
        var checkoutPromise = client.checkout.create()
        var productPromise = client.product.fetchAll()


        Promise.all([checkoutPromise,productPromise]).then(([checkout,products]) => 
        {
            var lineItemsToAdd = [
                {variantId: products[0].variants[0].id, quantity: 1}
            ]
            var checkoutId = checkout.id

            console.log('checkout id: ' + checkoutId)       
            console.log('line items to add: ' + lineItemsToAdd)

            client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
                console.log('checkout line items: ' + checkout.lineItems)
                webUrl = checkout.webUrl
                console.log(webUrl)
            })
        })



        
    },[])

    const redirectShopify = () => {
        console.log("Redirect to Shopify");
        window.location.href = webUrl;
    }


    
    // (function () {
    //     var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

    //     if (window.ShopifyBuy) {
    //         console.log(window.location.pathname);
    //         if (window.ShopifyBuy.UI) {
    //             ShopifyBuyInit();
    //             // setTimeout(() => {
    //             //     router.push('/book');
    //             // }, 15000)
                
    //         } else {
    //             loadScript();
    //         }
    //     } else {
    //         loadScript();
    //     }
    //     function loadScript() {
    //         var script = document.createElement('script');
    //         script.async = true;
    //         script.src = scriptURL;
    //         (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    //         script.onload = ShopifyBuyInit;
    //     }
    //     function ShopifyBuyInit() {
    //         var client = Client.buildClient({
    //         domain: 'meditel-24.myshopify.com',
    //         storefrontAccessToken: '61ef59f0c5fba79af5d1c7be3cbf95c2',
    //         });

    //         Client.UI.onReady(client).then(function (ui) {
    //         ui.createComponent('product', {
    //             id: '6132814119096',
    //             node: document.getElementById('product-component-1610044487935'),
    //             moneyFormat: '%24%7B%7Bamount%7D%7D',
    //             options: {
    //         "product": {
    //             "styles": {
    //             "product": {
    //                 "@media (min-width: 601px)": {
    //                 "max-width": "100%",
    //                 "margin-left": "0",
    //                 "margin-bottom": "50px"
    //                 },
    //                 "text-align": "left"
    //             },
    //             "title": {
    //                 "font-size": "26px"
    //             },
    //             "button": {
    //                 "font-size": "18px",
    //                 "padding-top": "17px",
    //                 "padding-bottom": "17px",
    //                 "border-radius": "40px",
    //                 "padding-left": "138px",
    //                 "padding-right": "138px"
    //             },
    //             "quantityInput": {
    //                 "font-size": "18px",
    //                 "padding-top": "17px",
    //                 "padding-bottom": "17px"
    //             },
    //             "price": {
    //                 "font-size": "18px"
    //             },
    //             "compareAt": {
    //                 "font-size": "15.299999999999999px"
    //             },
    //             "unitPrice": {
    //                 "font-size": "15.299999999999999px"
    //             },
    //             "description": {
    //                 "color": "#6e6666"
    //             }
    //             },
    //             "buttonDestination": "checkout",
    //             "layout": "horizontal",
    //             "contents": {
    //             "img": false,
    //             "imgWithCarousel": true,
    //             "description": true
    //             },
    //             "width": "100%",
    //             "text": {
    //             "button": "Buy now"
    //             }
    //         },
    //         "productSet": {
    //             "styles": {
    //             "products": {
    //                 "@media (min-width: 601px)": {
    //                 "margin-left": "-20px"
    //                 }
    //             }
    //             }
    //         },
    //         "modalProduct": {
    //             "contents": {
    //             "img": false,
    //             "imgWithCarousel": true,
    //             "button": false,
    //             "buttonWithQuantity": true
    //             },
    //             "styles": {
    //             "product": {
    //                 "@media (min-width: 601px)": {
    //                 "max-width": "100%",
    //                 "margin-left": "0px",
    //                 "margin-bottom": "0px"
    //                 }
    //             },
    //             "button": {
    //                 "font-size": "18px",
    //                 "padding-top": "17px",
    //                 "padding-bottom": "17px",
    //                 "border-radius": "40px",
    //                 "padding-left": "138px",
    //                 "padding-right": "138px"
    //             },
    //             "quantityInput": {
    //                 "font-size": "18px",
    //                 "padding-top": "17px",
    //                 "padding-bottom": "17px"
    //             }
    //             },
    //             "text": {
    //             "button": "Add to cart"
    //             }
    //         },
    //         "cart": {
    //             "styles": {
    //             "button": {
    //                 "font-size": "18px",
    //                 "padding-top": "17px",
    //                 "padding-bottom": "17px",
    //                 "border-radius": "40px"
    //             }
    //             },
    //             "text": {
    //             "total": "Subtotal",
    //             "button": "Checkout"
    //             },
    //             "popup": false
    //         },
    //         "toggle": {
    //             "styles": {
    //             "count": {
    //                 "font-size": "18px"
    //             }
    //             }
    //         }
    //         },
    //         });
    //         });
    //     }
    // })();


    // const client = Client.buildClient({
    //     domain: 'mediteldev.myshopify.com/',
    //     storefrontAccessToken: '0e87e4f76dfa558c4268c01e29df7e6e',
    // })

    
    return (  
        <>
            <Header/>
            {/* <Grid container direction="row" justify="space-around">
                <Grid item className={styles.container}>
                    <img src="/Meditel24-2x.png" height="150" width="250" />
                    <PaymentForm />
                </Grid>
                <Grid item className={styles.container2}>
                    <PaymentTotal />
                    <div id='product-component-1609193934533'></div>
                </Grid>
            </Grid> */}
            <Grid container alignItems="center" justify="center" direction="column">
                <Grid item>
                    <h1 className={styles.titulo}>{t('payment.title.online.medical')}</h1>
                </Grid>
                <Grid item>
                <h2 className={styles.subtitulo}>{t('payment.subtitle.price')}</h2>
                </Grid>
                {/* <button className={styles.boton} onClick={() => redirectShopify()}>{t('payment.button.buy.now')}</button> */}
                <button className={styles.boton} onClick={() => redirectShopify()}>Buy Now</button>
                <Grid item>
                <h2 className={styles.subtitulo2}>{t('payment.cont.independent.payment.1')} <span className={styles.bold}>{t('payment.cont.independent.payment.2')}</span></h2>
                </Grid>
            </Grid>

            <div id='product-component-1610044487935' className={styles.container}></div>

            
            
        </>
        
    );
}
 
export default Payment;