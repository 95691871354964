import React from 'react';
import Modal from "react-bootstrap/Modal";
//import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {useTranslation} from "react-i18next";
import style from '../styles/HistoryComplement.module.css';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderBottom: '0px solid rgba(0, 0, 0, 0)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '5px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);



function HistoryComplement(props) {
    const [t, i18n] =useTranslation("home");

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    //const [PatientData, setPatientData] = useState([]);

    const qs = window.location.search;
    const p = new URLSearchParams(qs);


    



    return (
      <Modal
        {...props}
        contentClassName= {style.custom}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t('history.complements.cont.importance')}</h4>
          <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <div className={style.question}>
                    <AddCircleIcon  className={style.add} />
                    <Typography className={style.text}>{t('history.complements.question.what')}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={style.text}>{t('history.complements.cont.what')}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className={style.question}>
                    <AddCircleIcon  className={style.add} />
                    <Typography className={style.text}>{t('history.complements.question.conditions')}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={style.text}>
                <ul>
                    <li>{t('history.complements.cont.allergies')}</li>
                    <li>{t('history.complements.cont.constipation')}</li>
                    <li>{t('history.complements.cont.cough')}</li>
                    <li>{t('history.complements.cont.diarrhea')}</li>

                </ul>
                <ul>    
                    <li>{t('history.complements.cont.ear')}</li>
                    <li>{t('history.complements.cont.fever')}</li>
                    <li>{t('history.complements.cont.headache')}</li>
                    <li>{t('history.complements.cont.insect')}</li>
                </ul>
                <ul>
                    <li>{t('history.complements.cont.nauseas')}</li>
                    <li>{t('history.complements.cont.pinkeye')}</li>
                    <li>{t('history.complements.cont.rash')}</li>
                    <li>{t('history.complements.cont.respiratory')}</li>
                </ul>
                <ul>
                    <li>{t('history.complements.cont.sora')}</li>
                    <li>{t('history.complements.cont.urinary')}</li>
                    <li>{t('history.complements.cont.vaginal')}</li>
                    <li>{t('history.complements.cont.more')}</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <p className={style.parrafo}>{t('history.complements.cont.service')}</p>
          <p className={style.parrafo}>{t('history.complements.cont.medical')} </p>
          <div className={style.justify}><Button className={style.buton} onClick={props.onHide}>{t('history.complements.button.continue')}</Button></div>
        </Modal.Body>
      </Modal>
    );
  }
  
export default HistoryComplement;