import React,  { useState, useEffect, Fragment } from "react";

import Header2 from '../components/Header2';
//import HistoryTable from '../components/HistoryTable';

import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import {useTranslation} from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";
import styles from '../styles/History.module.css';




const HistoryAdmin = () => {
    const [t, i18n] =useTranslation("home");

    const { isAuthenticated, logout, login } = useAuth0;
    const [pastwaitingData, setPastWaitingData] = useState([]);
    const [UserWaitingInfo, setUserWaitingInfo] = useState({});
    

    const viewSAddendum = (id, order_id, pin_code, doctor_id, user_id) => {


        console.log(doctor_id)
        console.log(id, order_id, pin_code, doctor_id, user_id)
        let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
        fetch('https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/cases/'+`${id}`,{
            method:'PATCH',
            headers:{
                Authorization: `Bearer ${storedSession.idToken}`,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            },
            body:JSON.stringify({"status": "FINISHED"})
        })
        .then( response => response.json())
        .then(result =>{
            console.log("Go to medical case");
            var newURL = "/addendum?id="+`${id}`+"&order_id="+`${order_id}`+"&pin_code="+`${pin_code}`+"&doctor_id="+`${doctor_id}`+"&pid="+`${user_id}`+"";
            //console.log(newURL);
            window.location.href=newURL;
        })
        .catch(error =>{
            console.log("ERROR ?",error)
        }); 
        
    }

    useEffect(() => {
        /*if (!isAuthenticated) {
            router.push('/');
        }*/
        if(false){
            window.location.href="/";
            
        }else{
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            try {
                async function fetchWaiting(){
                    await fetch(`https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/past-cases?page=${1}&size=${100}`, {
                        method: 'GET',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        setPastWaitingData(data.items);
                        //console.log(data);
                        
                    })
                    .catch( error_reason => {
                        console.log(error_reason)
                    })
                }
                fetchWaiting();

            } catch (error) {
                console.log("ERROR", error);
            }
        }
    }, []);


    //Aqui se le debe cambiar al tipo de contacto que se va a realizar y agregar numeros o campos
    const renderBody = () => {
        return pastwaitingData.map(({ id, first_name, last_name, status, pin_code, updated_at, minutes, phone, user_id, order_id, doctor_name, doctor_last, doctor_id, }) => {
            if(status !== "IN_PROGRESS"){
                return (
                    <tr key={id} className={styles.fila}>
                        <td>{first_name} {last_name}</td>
                        <td>{pin_code}</td>
                        <td>Dr. {doctor_name} {doctor_last}</td>
                        <td>
                            {(() => {
                                if (phone == null){
                                    return(
                                        "not available"
                                    )
                                }
                                else{
                                    if (phone.mobile_number != null){
                                        return(
                                            phone.mobile_number
                                        )
                                    }
                                    if(phone.phone_number != null){
                                        return(
                                            phone.phone_number
                                        )
                                    }
                                    if(phone.mobile_number != null && phone.phone_number != null){
                                        return(
                                            phone.mobile_number + "<br/>" + phone.phone_number
                                        )
                                    }
                                }
                            })()}
                        </td>
                        <td>
                            {(() => {
                                switch(status){
                                    case "IN_PROGRESS":
                                        return(
                                            "In progress"
                                        );
                                    break;
                                    case "FINISHED":
                                    var min = minutes.slice(0,-7);
                                        return(
                                            min
                                        );
                                    break;
                                    default:
                                        console.log("Algo ha salido mal");
                                    break;
                                }
                            })()}
                        </td>
                        <td><VisibilityIcon fontSize="medium" onClick={() => viewSAddendum(id, order_id, pin_code, doctor_id, user_id )}/></td>
                        <td>
                            {(() => {
                                var endDate = new Date(updated_at);
                                //alert(endDate.toLocaleDateString());
                                var endDatelocale = endDate.toLocaleDateString();
                                if(endDate){
                                    return (
                                        endDatelocale
                                    )
                                }
                            })()}
                        </td>
                        
                    </tr>
                )
            }

        })
    }

    const searchName = () => {
        // Declare variables
          var input, filter, table, tr, td, i, txtValue;
          input = document.getElementById("search");
          filter = input.value.toUpperCase();
          table = document.getElementById("myTable");
          tr = table.getElementsByTagName("tr");

          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
              txtValue = td.textContent || td.innerText;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
              } else {
                tr[i].style.display = "none";
              }
            }
          }
    }

    const searchDate = () => {
        // Declare variables
          var input, filter, table, tr, td, i, txtValue;
          input = document.getElementById("date");
          filter = input.value.toUpperCase();
          table = document.getElementById("myTable");
          tr = table.getElementsByTagName("tr");

          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[5];
            if (td) {
              txtValue = td.textContent || td.innerText;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
              } else {
                tr[i].style.display = "none";
              }
            }
          }
    }

    return (
        <div className={styles.container}>
            <Header2 userType= "admin" page={"doctor"}/>
            <div className={styles.linea}>
                <div className={styles.barrab}></div>
                <div className={styles.ele}>
                    <h4 className={styles.sub}>{t('history.a.subtitle.search')}</h4>
                    <div className={styles.altura}>
                    <input id="search" placeholder="Type in name" className={styles.codigo} onChange={searchName} />
                    </div>
                </div>
                <div className={styles.espacio}></div>
                <div className={styles.ele}>
                    <h4 className={styles.sub}>{t('history.a.subtitle.filters')}</h4>
                    <div className={styles.altura}>
                        <div className={styles.espacio}></div>
                        <p className={styles.p1}>{t('history.a.subtitle.date')}</p>
                        <div className={styles.espacio}></div>
                        <input id="date" placeholder="dd/mm/yyyy" className={styles.fecha} name="fecha"  onChange={searchDate} />
                    </div>
                </div>
            </div>
            <div className={styles.linea}>
                <div className={styles.barrab}></div>
                <h4 className={styles.sub}>{t('history.a.subtitle.latest')}</h4>
            </div>
            <div className={styles.tab}>
            <div className={styles.barrab}></div>
            <div className={styles.tabla2}>
                <table id="myTable" className={styles.a}>
                    <tr>
                        <th>{t('history.a.th.name')}</th>
                        <th>{t('history.a.th.consult.code')}</th>
                        <th>{t('history.a.th.type.contact')}</th>
                        <th>{t('history.a.th.contact')}</th>
                        <th>{t('history.a.th.call.duration')}</th>
                        <th>Resumen</th>
                        <th>{t('history.a.th.date')}</th>
                    </tr>
                    {renderBody()}
                </table>
            </div>
        </div>
        </div>
    )
}

export default HistoryAdmin;
