const url = "https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/"

async function fetchMedInfoUser( token ){
  const response = await fetch(url + "user/med-info", {
    method: 'GET',
    headers:{
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
   }
  )
  return await response.json()
// .then(response => response.json())
// .then(data => {
//     setUserInfo(data.user);
//     console.log(data.user)
// })
  
}

async function saveUserInfoUser ( userInfo, token ) {
  const response = await fetch(url + "user", {
    method: 'PATCH',
    body: JSON.stringify(userInfo),
    headers:{
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
  })
  return await response.json()
}

async function saveUserMedInfoUser ( userMedInfo, token ) {
  const response = await fetch(url + "user/med-info", {
    method: 'PATCH',
    body: JSON.stringify(userMedInfo),
    headers:{
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
  })
  return await response.json()
}

async function saveBasicInfoDataUser (userInfo, userMedInfo, token) {
  console.log("PATCH")
  const responseUserInfo = await saveUserInfoUser( userInfo, token )
  const responseMedInfo = await saveUserMedInfoUser ( userMedInfo, token )
  console.log('response user info', responseUserInfo)
  console.log('response med info', responseMedInfo)
  return (responseUserInfo, responseMedInfo)
  // console.log(responseUserInfo)
  // return responseUserInfo
}

// ----------------------------------------

const address = `https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/`

// let storedSession = localStorage.getItem('storageKey')
export function updateUserProfile(user,idToken){
    fetch(address+'user',{
        method:'PATCH',
        headers:{
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        },
        body:JSON.stringify(user)})
        .then(response => response.json())
        .then(result =>{
            console.log("RESULT UPDATE USER PROFILE",result)
            return result
        }).catch(error =>{
            console.log("ERROR ?",error)
            return error
        })
}

export function updateUserMedInfo(user, idToken){
    fetch(address+'user/med-info/',{
        method:'PATCH',
        headers:{
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      },
        body:JSON.stringify(user)})
        .then(response => response.json())
        .then(result =>{
            console.log("RESULT UPDATE USER MED INFO",result)
            return result
        }).catch(error =>{
            console.log("ERROR UPDATE USER MED INFO",error)
            return error
    })
}

// async function saveBasicInfoDataUser (userInfo, userMedInfo, token) {
//   console.log("PATCHING")
//   const response1 = await updateUserProfile( userInfo, token )
//   // const response2 = await updateUserMedInfo ( userMedInfo, token )
//   // return (response1, response2)
//   return response1
  
// }

// ------------------------------------ Doctor

async function fetchUserInfoDoctor( token, patientID ){
  const response = await fetch(url + "doctor/patient/" + patientID, {
    method: 'GET',
    headers:{
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
   }
  )
  return await response.json() 
}

async function fetchMedInfoDoctor( token, patientID ){
  const response = await fetch(url + "doctor/patient-medical-data/" + patientID, {
    method: 'GET',
    headers:{
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
   }
  )
  return await response.json()  
}


async function saveUserInfoDoctor ( userInfo, token, patientID ) {
  const response = await fetch(url + "doctor/patient/" + patientID, {
    method: 'PATCH',
    body: JSON.stringify(userInfo),
    headers:{
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
  })
  return await response.json()
}

async function saveUserMedInfoDoctor ( userMedInfo, token, patientID ) {
  const response = await fetch(url + "doctor/patient-medical-data/" + patientID, {
    method: 'PATCH',
    body: JSON.stringify(userMedInfo),
    headers:{
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }
  })
  return await response.json()
}

async function saveBasicInfoDataDoctor (userInfo, userMedInfo, token, patientID) {
  console.log("PATCH")
  const responseUserInfo = await saveUserInfoDoctor( userInfo, token, patientID )
  const responseMedInfo = await saveUserMedInfoDoctor ( userMedInfo, token, patientID)
  console.log('response user info', responseUserInfo)
  console.log('response med info', responseMedInfo)
  return (responseUserInfo, responseMedInfo)
  // console.log(responseUserInfo)
  // return responseUserInfo
}



export { fetchMedInfoUser, saveBasicInfoDataUser, fetchUserInfoDoctor, fetchMedInfoDoctor, saveBasicInfoDataDoctor}