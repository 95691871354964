import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import TextField from '@material-ui/core/TextField';
import { Grid, Checkbox, Button, MenuItem, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Typography from '@material-ui/core/Typography';
import Disclaimer from "../components/UI/Disclaimer";
import Header from "../components/Header";
import Alert from 'react-bootstrap/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { NavBar, Footer, Loading } from "../components";

import { useAuth0 } from "@auth0/auth0-react";

import {useTranslation} from "react-i18next";

import { getForm, updateForm, createForm } from '../utils/storage';

import styles from '../styles/Register.module.css';

const useStyles = makeStyles({
    btn:{
        backgroundColor: '#1C5EA9',
        width: '50%',
        height: '55px',
        fontFamily: 'Lucida Grande',
        borderRadius: '66px'
    },
    Title:{
        margin: ".9rem 0rem",
        "text-align": "left",
        font: "normal normal bold 15px/18px Lucida Grande",
        "letter-spacing": "0px",
        color: "#1C5EA9",
        "font-size": "larger",
        "padding-top": "20px",
        "opacity": 1
    },
    subTitle:{
        fontFamily: 'Lucida Grande',
        fontSize: 11 + "px",
        margin: 0.5 + "rem",
        color: "black",
    },
    inputbasic:{
        width:'100% !important',
        height: "43px",
        backgroundColor: "transparent",
        border: "none",
        borderRadius: "0px",
        margin: 0.5+ "rem",
        marginLeft: 0,
        textAlign: "center",
        fontSize: 11 + "px",
        fontFamily: "Lucida Grande",
        disableUnderline: true,
    },
    inputText:{
        width: 100+ "%",
        height: "43px",
        backgroundColor: "transparent !important",
        border: "1px solid #cccccc",
        borderRadius: "0px",
        margin: 0.5+ "rem",
        marginLeft: 0,
        textAlign: "center",
        fontSize: 11 + "px",
        fontFamily: "Lucida Grande",
        disableUnderline: true,
    },
    inputTextwh:{
        width: 100+ "%",
        height: "43px",
        backgroundColor: "transparent !important",
        border: "none",
        borderRadius: "0px",
        margin: 0.5+ "rem",
        marginLeft: 0,
        textAlign: "center",
        fontSize: 11 + "px",
        fontFamily: "Lucida Grande",
        disableUnderline: true,
    },
    inputTextBig:{
        width: 100+ "%",
        height: "60px",
        backgroundColor: "transparent",
        border: "1px solid #cccccc",
        borderRadius: "6px 6px 6px 6px",
        margin: 0.5+ "rem",
        marginLeft: 0,
        textAlign: "center",
        fontSize: 11 + "px",
        fontFamily: "Lucida Grande",
        disableUnderline: true,
        
    },
    inputDate:{
        width: 100+ "%",
        height: "60px",
        backgroundColor: "transparent",
        border: "1px solid #cccccc",
        borderRadius: "0px",
        margin: 0+ "rem",
        marginLeft: 0,
        textAlign: "center",
        fontSize: 11 + "px",
        fontFamily: "Lucida Grande",
        disableUnderline: true,
    },
    radio:{
        textAlign: "left",
        font: "normal normal normal 11px/16px Lucida Grande",
        letterSpacing: "0.55px",
        color: "#000000",
        opacity: 1,
    },
    contForm:{
        border: "1px solid #cccccc",
        padding: "10px",
        "overflow-y": "auto",
        "max-height": "180px",
        "justify-content": "inherit",
    },
    contFormLabel:{
        "min-width":"200px",
    },
    contFormLabel100:{
        "min-width":"100px",
    },
    contOptions:{
        display:'flex',
        "@media (max-width: 700px)":{
            display:'block',
          }
    },
    mr10:{
        "margin-right":'10px',
    },
    pr10:{ "padding-right":'10px'},
    pr25:{ "padding-right":'25px'},
    MuiFormControl:{
        width:'100%'
    },
    border:{
        border: '1px solid #cccccc',
        padding: '10px'
    },
    contFormLabelP:{
        display:'grid',
        paddingRight:'10px'
    },
    selectCont:{
        display:"flex"
    },
    selectContwh:{
        display:"flex",
        border:"1px solid #cccccc"
    },
    contBtn:{ padding: "20px 0"}
})

const Register = ({auth}) => {
    const [t, i18n] =useTranslation("home");

    const { isAuthenticated, logout, login, user } = useAuth0();

    const [modalShow, setModalShow] = useState(true);
    const [buttonShow, setButtonShow] = useState(true);

    const [showAlert, setShowAlert] = useState(false);
    const [veracity, setVeracity] = useState({
        veracity_checked: false,
        veracity_third: false
    });

    //App state
    const [ userInfo, setUserInfo ] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber:'',
        nameEmergencyContact:'',
        relationshipEmergencyContact:'',
        phoneNumberEmergencyContact:''
    })

    const [preferredContact, setPreferredContact] = useState(null)

    const [errorList,setErrorList] = useState({
        error:false,
        errorMessage:{}
    })

    const [errorLastName,setErrorLastName] = useState({
        error:false,
        errorMessage:{}
    })

    const [ demographInfo, setDemographInfo ] = useState({
        birth: '',
        birthPlace: '',
        ethnicity: '',
        ethnicity2: '',
        birthGender:'',
        weight:'',
        height:'',
        maritalStatus:''
    })

    const [ emergencyContact, setEmergencyContact ] = useState({
        name: '',
        relationship: '',
        phone: ''
    })

    const [measures,setUserMeasures] = useState({
        weightMeasure: '',
        heightMeasure:''
    })

    const [pastMedicalHistory,setPastMedicalHistory] = useState({
        highBloodPressure:false,
        highCholesterol:false,
        heart:false,
        thyroid:false,
        kidney:false,
        liver:false,
        asthma:false,
        diabetes:false,
        depression:false,
        arrhythmias:false,
        panicAttack:false,
        seizure:false,
        anxiety:false,
        copd:false,
        psoriasis:false,
        cancer:false,
        stroke:false,
        acidReflux:false,
        skinProblems:false,
        osteopenia:false,
        headache:false,
    })

    const [ pastMedicalOthers, setPastMedicalOthers ] = useState({
        otherType:'',
        cancerType:'',
        heartType:'',
        liverType:'',
        kidneyType:''  
    })


    const [ familyMedicalHistory, setfamilyMedicalHistory ] = useState({
        highBloodPressureFam:false,
        highCholesterolFam:false,
        heartFam:false,
        thyroidFam:false,
        kidneyFam:false,
        liverFam:false,
        asthmaFam:false,
        diabetesFam:false,
        depressionFam:false,
        arrhythmiasFam:false,
        panicAttackFam:false,
        seizureFam:false,
        anxietyFam:false,
        copdFam:false,
        psoriasisFam:false,
        cancerFam:false,
        strokeFam:false,
        acidRefluxFam:false,
        skinProblemsFam:false,
        osteopeniaFam:false,
        headacheFam:false,    
    })

    const [ familyMedicalOthers, setfamilyMedicalOthers ] = useState({

        /*1*/familymemberone: '', diseasetypeone: '', 
        /*2*/familymembertwo: '', diseasetypetwo: '',
        /*3*/familymemberthree: '', diseasetypethree: '',
        /*4*/familymemberfour: '', diseasetypfoure: '',
        /*5*/familymemberfive: '', diseasetypefive: '',
        /*6*/familymembersix: '', diseasetypesix: '',
        /*7*/familymemberseven: '', diseasetypeseven: '',
        /*8*/familymembereight: '', diseasetypeeight: '',
        /*9*/familymembernine: '', diseasetypenine: '',
        /*10*/familymemberten: '', diseasetypeten: '',
        /*11*/familymembereleven: '', diseasetypeeleven: '',
        /*12*/familymembertwelve: '', diseasetypetwelve: '',
        /*13*/familymemberthirteen: '', diseasetypethirteen: '',
        /*14*/familymemberfourteen: '', diseasetypefourteen: '',
        /*15*/familymemberfifteen: '', diseasetypefifteen: '',
        /*16*/familymembersixteen: '', diseasetypesixteen: '',
        /*17*/familymemberseventeen: '', diseasetypeseventeen: '',
        /*18*/familymembereighteen: '', diseasetypeeighteen: '',
        /*19*/familymembernighteen: '', diseasetypenighteen: '',
        /*20*/familymembertwenty: '', diseasetypetwenty: '',
        /*21*/familymembertwentyone: '', diseasetypeone: '',
    })

    const [ familyMedicalSecondOthers, setfamilyMedicalSecondOthers ] = useState({
        secondfamilymemberone: '',
        seconddiseasetypeone: '',
        thirdFamilyMemberone: '',
        thirdDiseaseTypeone: '',
        fourthFamilyMemberone: '',
        fourthDiseaseTypeone: '',

        secondfamilymembertwo: '',
        seconddiseasetypetwo: '',
        thirdFamilyMembertwo: '',
        thirdDiseaseTypetwo: '',
        fourthFamilyMembertwo: '',
        fourthDiseaseTypetwo: '',

        secondfamilymemberthree: '', 
        seconddiseasetypethree: '', 
        thirdFamilyMemberthree: '', 
        thirdDiseaseTypethree: '', 
        fourthFamilyMemberthree: '', 
        fourthDiseaseTypethree: '',

        secondfamilymemberfour: '', 
        seconddiseasetypefour: '', 
        thirdFamilyMemberfour: '', 
        thirdDiseaseTypefour: '', 
        fourthFamilyMemberfour: '', 
        fourthDiseaseTypefour: '',
        /*5*/
        secondfamilymemberfive: '', 
        seconddiseasetypefive: '', 
        thirdFamilyMemberfive: '', 
        thirdDiseaseTypefive: '', 
        fourthFamilyMemberfive: '', 
        fourthDiseaseTypefive: '',

        secondfamilymembersix: '', 
        seconddiseasetypesix: '', 
        thirdFamilyMembersix: '', 
        thirdDiseaseTypesix: '', 
        fourthFamilyMembersix: '', 
        fourthDiseaseTypesix: '',

        secondfamilymemberseven: '', 
        seconddiseasetypeseven: '', 
        thirdFamilyMemberseven: '', 
        thirdDiseaseTypeseven: '', 
        fourthFamilyMemberseven: '', 
        fourthDiseaseTypeseven: '',
        
        secondfamilymembereight: '', 
        seconddiseasetypeeight: '', 
        thirdFamilyMembereight: '', 
        thirdDiseaseTypeeight: '', 
        fourthFamilyMembereight: '', 
        fourthDiseaseTypeeight: '',
        
        secondfamilymembernine: '', 
        seconddiseasetypenine: '', 
        thirdFamilyMembernine: '', 
        thirdDiseaseTypenine: '', 
        fourthFamilyMembernine: '', 
        fourthDiseaseTypenine: '',
        
        secondfamilymemberten: '', 
        seconddiseasetypeten: '', 
        thirdFamilyMemberten: '', 
        thirdDiseaseTypeten: '', 
        fourthFamilyMemberten: '', 
        fourthDiseaseTypeten: '',
        /*11*/
        secondfamilymembereleven: '', 
        seconddiseasetypeeleven: '', 
        thirdFamilyMembereleven: '', 
        thirdDiseaseTypeeleven: '', 
        fourthFamilyMembereleven: '', 
        fourthDiseaseTypeeleven: '',
        /*12*/
        secondfamilymembertwelve: '', 
        seconddiseasetypetwelve: '', 
        thirdFamilyMembertwelve: '', 
        thirdDiseaseTypetwelve: '', 
        fourthFamilyMembertwelve: '', 
        fourthDiseaseTypetwelve: '',
        /*13*/
        secondfamilymemberthirteen: '', 
        seconddiseasetypethirteen: '', 
        thirdFamilyMemberthirteen: '', 
        thirdDiseaseTypethirteen: '', 
        fourthFamilyMemberthirteen: '', 
        fourthDiseaseTypethirteen: '',
        /*14*/
        secondfamilymemberfourteen: '', 
        seconddiseasetypefourteen: '', 
        thirdFamilyMemberfourteen: '', 
        thirdDiseaseTypefourteen: '', 
        fourthFamilyMemberfourteen: '', 
        fourthDiseaseTypefourteen: '',
        /*15*/
        secondfamilymemberfifteen: '', 
        seconddiseasetypefifteen: '', 
        thirdFamilyMemberfifteen: '', 
        thirdDiseaseTypefifteen: '', 
        fourthFamilyMemberfifteen: '', 
        fourthDiseaseTypefifteen: '',
        /*16*/
        secondfamilymembersixteen: '', 
        seconddiseasetypesixteen: '', 
        thirdFamilyMembersixteen: '', 
        thirdDiseaseTypesixteen: '', 
        fourthFamilyMembersixteen: '', 
        fourthDiseaseTypesixteen: '',
        /*17*/
        secondfamilymemberseventeen: '', 
        seconddiseasetypeseventeen: '', 
        thirdFamilyMemberseventeen: '', 
        thirdDiseaseTypeseventeen: '', 
        fourthFamilyMemberseventeen: '', 
        fourthDiseaseTypeseventeen: '',
        /*18*/
        secondfamilymembereighteen: '', 
        seconddiseasetypeeighteen: '', 
        thirdFamilyMembereighteen: '', 
        thirdDiseaseTypeeighteen: '', 
        fourthFamilyMembereighteen: '', 
        fourthDiseaseTypeeighteen: '',
        /*19*/
        secondfamilymembernighteen: '', 
        seconddiseasetypenighteen: '', 
        thirdFamilyMembernighteen: '', 
        thirdDiseaseTypenighteen: '', 
        fourthFamilyMembernighteen: '', 
        fourthDiseaseTypenighteen: '',
        /*20*/
        secondfamilymembertwenty: '', 
        seconddiseasetypetwenty: '', 
        thirdFamilyMembertwenty: '', 
        thirdDiseaseTypetwenty: '', 
        fourthFamilyMembertwenty: '', 
        fourthDiseaseTypetwenty: '',
        
        secondfamilymembertwentyone: '', 
        seconddiseasetypetwentyone: '', 
        thirdFamilyMembertwentyone: '', 
        thirdDiseaseTypetwentyone: '', 
        fourthFamilyMembertwentyone: '', 
        fourthDiseaseTypetwentyone: '',

    })

    const [controls, setControls] = useState({
      addOtherMemberone: false,
      addThirdMemberone: false,
      addFourthMemberone: false,
      addOtherMembertwo: false,
      addThirdMembertwo: false,
      addFourthMembertwo: false,
      addOtherMemberthree: false,
      addThirdMemberthree: false,
      addFourthMemberthree: false,
      addOtherMemberfour: false,
      addThirdMemberfour: false,
      addFourthMemberfour: false,
      addOtherMemberfive: false,
      addThirdMemberfive: false,
      addfourthMemberfive: false,
      addOtherMembersix: false,
      addThirdMembersix: false,
      addFourthMembersix: false,
      addOtherMemberseven: false,
      addThirdMemberseven: false,
      addFourthMemberseven: false,
      addOtherMembereight: false,
      addThirdMembereight: false,
      addFourthMembereight: false,
      addOtherMembernine: false,
      addThirdMembernine: false,
      addFourthMembernine: false,
      addOtherMemberten: false,
      addThirdMemberten: false,
      addfourthMemberten: false,
      addOtherMembereleven: false,
      addThirdMembereleven: false,
      addFourthMembereleven: false,
      addOtherMembertwelve: false,
      addThirdMembertwelve: false,
      addFourthMembertwelve: false,
      addOtherMemberthirteen: false,
      addThirdMemberthirteen: false,
      addFourthMemberthirteen: false,
      addOtherMemberfourteen: false,
      addThirdMemberfourteen: false,
      addFourthMemberfourteen: false,
      addOtherMemberfifteen: false,
      addThirdMemberfifteen: false,
      addfourthMemberfifteen: false,
      addOtherMembersixteen: false,
      addThirdMembersixteen: false,
      addFourthMembersixteen: false,
      addOtherMemberseventeen: false,
      addThirdMemberseventeen: false,
      addFourthMemberseventeen: false,
      addOtherMembereighteen: false,
      addThirdMembereighteen: false,
      addFourthMembereighteen: false,
      addOtherMembernighteen: false,
      addThirdMembernighteen: false,
      addFourthMembernighteen: false,
      addOtherMembertwenty: false,
      addThirdMembertwenty: false,
      addfourthMembertwenty: false,
      addOtherMembertwentyone: false,
      addThirdMembertwentyone: false,
      addFourthMembertwentyone: false,
    });

    const [ allergies, setUserAllergies ] = useState({
        drug:false,
        food:false,
        cosmetic:false,
        plant:false,
        pet:false,
        other:false,
        chills:false,
        cough:false,
        diarrhea:false,
        dizziness:false,
        eruption:false,
        fever:false,
        headacheAl:false,
        nausea:false,
        pruritus:false,
        throat:false,
        vomiting:false,
        swelling:false,
        tachycardia:false,
    })

    const [hasAllergies,setHasAllergies] = useState({
        doesHaveAllergy:false,
        allergyNameone:null,
        allergyNametwo:null,
        allergyNamethree:null,
        allergyNamefour:null,
        allergyNamefive:null,
        allergyNamesix:null,
        otherAllergy:false
    })


    const [ medications, setMedications ] = useState({
        nameOfDrug: '',
        dose: '',
        frequencytaken: '',
        nameOfSecondDrug: '',
        dosesecond: '',
        frequencytakensecond: '',
        nameOfThirdDrug: '',
        doseThird: '',
        frequencyTakenThird: '',
        nameOfFourthDrug: '',
        doseFourth: '',
        frequencyTakenFourth: '',
        nameOfFifthDrug: '',
        doseFifth: '',
        frequencyTakenFifth: '',
        drugList:[],
    })



    //True-false states
    const [ anymedication, setAnyMedication ] = useState(false);
    const [ useTobacco, setUseTobacco ] = useState(false);
    const [ showAlcohol, setShowAlcohol ] = useState(false);
    const [showYear, setShowYear] = useState(false);
    const [ illegalDrug, setIllegalDrug ] = useState(false);
    const [ threeMonths, setThreeMonths ] = useState(false);

    const [ addothermedication, setAddOtherMedication ] = useState(false);
    const [ addThirdMedication, setAddThirdMedication ] = useState(false);
    const [ addFourthMedication, setAddFourthMedication ] = useState(false);
    const [ addFifthMedication, setAddFifthMedication] = useState(false);


    const [ socialHistory, setSocialHistory ] = useState({
        ageStarted:'',
        ageStopped:'',
        yearQuit:'',
        cigarrettesPerDay:'',
        packsPerDay:'',
        cigarsPerDay:'',
        pipePerDay:'',
        smokelessTobaccoPerDay:'',
        drinkAlcohol:'',
        yearQuitAlcohol:'',
        numberOfDrinksCheck:'',
        checkedDrinksPerDay: false,
        numberOfDrinksPerDay: '',
        checkedDrinksPerWeek: false,
        numberOfDrinksPerWeek: '',
        checkedDrinksPerMonth: false,
        numberOfDrinksPerMonth: '',
        drinkTypeBeer:'',
        drinkTypeWine:'',
        drinkTypeStrong:'',
        recreationalDrugType:'',
        activityLevel:'',
        countryTraveled:'',
        cityTraveled:'',
        periodTraveled:''
    });

    const [ checkedsocial, setCheckedSocial ] = useState({
        cigarrettes:false,
        cigars:false,
        pipe:false,
        smokelessTobaco:false,
    })
    const [travelOverseas, setTravelOverseas] = useState(false);

    const [pharmacy, setPharmacyName] = useState({ label: '', value: {} });
    const [reasonInfo, setReasonInfo] = useState('');

    useEffect(() => {
        const form = getForm();
        if (form && form?.user && form?.user === user?.sub) {
          setUserInfo(form?.userInfo || {});
          setPreferredContact(form?.preferredContact || {});
          setDemographInfo(form?.demographInfo || {});
          setUserMeasures(form?.measures || {});
          setPastMedicalHistory(form?.pastMedicalHistory || {});
          setPastMedicalOthers(form?.pastMedicalOthers || {});
          setfamilyMedicalHistory(form?.familyMedicalHistory || {});
          setfamilyMedicalOthers(form?.familyMedicalOthers || {});
          setfamilyMedicalSecondOthers(form?.familyMedicalSecondOthers || {});
          setHasAllergies(form?.hasAllergies || {});
          setUserAllergies(form?.allergies || {});
          setAnyMedication(form?.anymedication || {});
          setIllegalDrug(form?.illegalDrug || {});
          setMedications(form?.medications || {});
          setAddOtherMedication(form?.addothermedication || false);
          setAddThirdMedication(form?.addThirdMedication || false);
          setAddFourthMedication(form?.addFourthMedication || false);
          setAddFifthMedication(form?.addFifthMedication || false);
          setUseTobacco(form?.useTobacco || false);
          setSocialHistory(form?.socialHistory || {});
          setTravelOverseas(form?.travelOverseas || false);
          setPharmacyName(form?.pharmacy || { label: '', value: {} });
          setReasonInfo(form?.reasonInfo || '');
          setControls(form?.controls || {});
          setCheckedSocial(form?.checkedsocial || {});
        } else {
          createForm(user?.sub || 'unique');
        }
    }, [user?.sub]);

    useEffect(() => {
      showContent();
    }, [familyMedicalHistory]);

    //Instanciamos los estilos para el boton de continuar
    const classes = useStyles();

    const { firstName, middleName, lastName, phoneNumber, nameEmergencyContact, relationshipEmergencyContact, phoneNumberEmergencyContact } = userInfo;
    const {error,errorMessage}  = errorList;
    const {birth,birthPlace,ethnicity,birthGender,ethnicity2,weight,height,maritalStatus} = demographInfo
    const { weightMeasure, heightMeasure } = measures;
    const {
        highBloodPressure,highCholesterol,heart,thyroid,kidney,
        liver,asthma,diabetes,depression,arrhythmias,panicAttack,seizure,
        anxiety,copd,psoriasis,cancer,stroke,acidReflux,skinProblems,
        osteopenia,headache} = pastMedicalHistory
    const {
        highBloodPressureFam, highCholesterolFam, heartFam, thyroidFam, kidneyFam,
        liverFam, asthmaFam, diabetesFam, depressionFam, arrhythmiasFam, panicAttackFam, seizureFam,
        anxietyFam, copdFam, psoriasisFam, cancerFam, strokeFam, acidRefluxFam, skinProblemsFam,
        osteopeniaFam, headacheFam } = familyMedicalHistory

    const {
        drug, food,cosmetic,plant,pet,other,
        chills,cough,diarrhea,dizziness,eruption,
        fever,headacheAl,nausea,pruritus,throat,
        vomiting,swelling,tachycardia
    } = allergies
    const {doesHaveAllergy, allergyNameone, allergyNametwo, allergyNamethree, allergyNamefour, allergyNamefive, allergyNamesix, otherAllergy} = hasAllergies

    const { cancerType, heartType, liverType, kidneyType, otherType } = pastMedicalOthers;
    const { 
        /*1*/familymemberone, diseasetypeone, 
        /*2*/familymembertwo, diseasetypetwo,
        /*3*/familymemberthree, diseasetypethree,
        /*4*/familymemberfour, diseasetypefour,
        /*5*/familymemberfive, diseasetypefive,
        /*6*/familymembersix, diseasetypesix,
        /*7*/familymemberseven, diseasetypeseven,
        /*8*/familymembereight, diseasetypeeight,
        /*9*/familymembernine, diseasetypenine,
        /*10*/familymemberten, diseasetypeten,
        /*11*/familymembereleven, diseasetypeeleven,
        /*12*/familymembertwelve, diseasetypetwelve,
        /*13*/familymemberthirteen, diseasetypethirteen,
        /*14*/familymemberfourteen, diseasetypefourteen,
        /*15*/familymemberfifteen, diseasetypefifteen,
        /*16*/familymembersixteen, diseasetypesixteen,
        /*17*/familymemberseventeen, diseasetypeseventeen,
        /*18*/familymembereighteen, diseasetypeeighteen,
        /*19*/familymembernighteen, diseasetypenighteen,
        /*20*/familymembertwenty, diseasetypetwenty,
        /*21*/familymembertwentyone, diseasetypetwentyone,

    } = familyMedicalOthers;
    const { 
        /*1*/secondfamilymemberone, seconddiseasetypeone, thirdFamilyMemberone, thirdDiseaseTypeone, fourthFamilyMemberone, fourthDiseaseTypeone, 
        /*2*/secondfamilymembertwo, seconddiseasetypetwo, thirdFamilyMembertwo, thirdDiseaseTypetwo, fourthFamilyMembertwo, fourthDiseaseTypetwo,
        /*3*/secondfamilymemberthree, seconddiseasetypethree, thirdFamilyMemberthree, thirdDiseaseTypethree, fourthFamilyMemberthree, fourthDiseaseTypethree,
        /*4*/secondfamilymemberfour, seconddiseasetypefour, thirdFamilyMemberfour, thirdDiseaseTypefour, fourthFamilyMemberfour, fourthDiseaseTypefour,
        /*5*/secondfamilymemberfive, seconddiseasetypefive, thirdFamilyMemberfive, thirdDiseaseTypefive, fourthFamilyMemberfive, fourthDiseaseTypefive,
        /*6*/secondfamilymembersix, seconddiseasetypesix, thirdFamilyMembersix, thirdDiseaseTypesix, fourthFamilyMembersix, fourthDiseaseTypesix,
        /*7*/secondfamilymemberseven, seconddiseasetypeseven, thirdFamilyMemberseven, thirdDiseaseTypeseven, fourthFamilyMemberseven, fourthDiseaseTypeseven,
        /*8*/secondfamilymembereight, seconddiseasetypeeight, thirdFamilyMembereight, thirdDiseaseTypeeight, fourthFamilyMembereight, fourthDiseaseTypeeight,
        /*9*/secondfamilymembernine, seconddiseasetypenine, thirdFamilyMembernine, thirdDiseaseTypenine, fourthFamilyMembernine, fourthDiseaseTypenine,
        /*10*/secondfamilymemberten, seconddiseasetypeten, thirdFamilyMemberten, thirdDiseaseTypeten, fourthFamilyMemberten, fourthDiseaseTypeten,
        /*11*/secondfamilymembereleven, seconddiseasetypeeleven, thirdFamilyMembereleven, thirdDiseaseTypeeleven, fourthFamilyMembereleven, fourthDiseaseTypeeleven,
        /*12*/secondfamilymembertwelve, seconddiseasetypetwelve, thirdFamilyMembertwelve, thirdDiseaseTypetwelve, fourthFamilyMembertwelve, fourthDiseaseTypetwelve,
        /*13*/secondfamilymemberthirteen, seconddiseasetypethirteen, thirdFamilyMemberthirteen, thirdDiseaseTypethirteen, fourthFamilyMemberthirteen, fourthDiseaseTypethirteen,
        /*14*/secondfamilymemberfourteen, seconddiseasetypefourteen, thirdFamilyMemberfourteen, thirdDiseaseTypefourteen, fourthFamilyMemberfourteen, fourthDiseaseTypefourteen,
        /*15*/secondfamilymemberfifteen, seconddiseasetypefifteen, thirdFamilyMemberfifteen, thirdDiseaseTypefifteen, fourthFamilyMemberfifteen, fourthDiseaseTypefifteen,
        /*16*/secondfamilymembersixteen, seconddiseasetypesixteen, thirdFamilyMembersixteen, thirdDiseaseTypesixteen, fourthFamilyMembersixteen, fourthDiseaseTypesixteen,
        /*17*/secondfamilymemberseventeen, seconddiseasetypeseventeen, thirdFamilyMemberseventeen, thirdDiseaseTypeseventeen, fourthFamilyMemberseventeen, fourthDiseaseTypeseventeen,
        /*18*/secondfamilymembereighteen, seconddiseasetypeeighteen, thirdFamilyMembereighteen, thirdDiseaseTypeeighteen, fourthFamilyMembereighteen, fourthDiseaseTypeeighteen,
        /*19*/secondfamilymembernighteen, seconddiseasetypenighteen, thirdFamilyMembernighteen, thirdDiseaseTypenighteen, fourthFamilyMembernighteen, fourthDiseaseTypenighteen,
        /*20*/secondfamilymembertwenty, seconddiseasetypetwenty, thirdFamilyMembertwenty, thirdDiseaseTypetwenty, fourthFamilyMembertwenty, fourthDiseaseTypetwenty,
        /*21*/secondfamilymembertwentyone, seconddiseasetypetwentyone, thirdFamilyMembertwentyone, thirdDiseaseTypetwentyone, fourthFamilyMembertwentyone, fourthDiseaseTypetwentyone,


    } = familyMedicalSecondOthers;

    const {
        ageStarted, ageStopped, yearQuit, cigarrettesPerDay, packsPerDay, cigarsPerDay,
        pipePerDay, smokelessTobaccoPerDay, drinkAlcohol, yearQuitAlcohol, numberOfDrinksPerDay, numberOfDrinksPerWeek, numberOfDrinksPerMonth, drinkTypeBeer, drinkTypeWine, drinkTypeStrong, recreationalDrugs,
        recreationalDrugType, activityLevel, countryTraveled, cityTraveled, periodTraveled, checkedDrinksPerDay, checkedDrinksPerWeek, checkedDrinksPerMonth 
    } = socialHistory;

    const { nameOfDrug, dose, frequencytaken, nameOfSecondDrug, dosesecond, frequencytakensecond, nameOfThirdDrug, doseThird, frequencyTakenThird, nameOfFourthDrug, doseFourth, frequencyTakenFourth, nameOfFifthDrug, doseFifth, frequencyTakenFifth,} = medications;

    const {
      addOtherMemberone,
      addThirdMemberone,
      addFourthMemberone,
      addOtherMembertwo,
      addThirdMembertwo,
      addFourthMembertwo,
      addOtherMemberthree,
      addThirdMemberthree,
      addFourthMemberthree,
      addOtherMemberfour,
      addThirdMemberfour,
      addFourthMemberfour,
      addOtherMemberfive,
      addThirdMemberfive,
      addfourthMemberfive,
      addOtherMembersix,
      addThirdMembersix,
      addFourthMembersix,
      addOtherMemberseven,
      addThirdMemberseven,
      addFourthMemberseven,
      addOtherMembereight,
      addThirdMembereight,
      addFourthMembereight,
      addOtherMembernine,
      addThirdMembernine,
      addFourthMembernine,
      addOtherMemberten,
      addThirdMemberten,
      addfourthMemberten,
      addOtherMembereleven,
      addThirdMembereleven,
      addFourthMembereleven,
      addOtherMembertwelve,
      addThirdMembertwelve,
      addFourthMembertwelve,
      addOtherMemberthirteen,
      addThirdMemberthirteen,
      addFourthMemberthirteen,
      addOtherMemberfourteen,
      addThirdMemberfourteen,
      addFourthMemberfourteen,
      addOtherMemberfifteen,
      addThirdMemberfifteen,
      addfourthMemberfifteen,
      addOtherMembersixteen,
      addThirdMembersixteen,
      addFourthMembersixteen,
      addOtherMemberseventeen,
      addThirdMemberseventeen,
      addFourthMemberseventeen,
      addOtherMembereighteen,
      addThirdMembereighteen,
      addFourthMembereighteen,
      addOtherMembernighteen,
      addThirdMembernighteen,
      addFourthMembernighteen,
      addOtherMembertwenty,
      addThirdMembertwenty,
      addfourthMembertwenty,
      addOtherMembertwentyone,
      addThirdMembertwentyone,
      addFourthMembertwentyone,
    } = controls;

    const {
      cigarrettes,
      cigars,
      pipe,
      smokelessTobaco,
    } = checkedsocial;

    const handleUserInfo = ({ target }) => {
        setUserInfo({
            ...userInfo,
            [target.name] : target.value
        });

        //Checks error handling in first name field.
        if(target.name === "firstName"){
            if(!target.value.length){
                setErrorList({
                    ...errorList,
                    error:{firstName:true},
                    errorMessage:{firstName:"This field is required"}
                })
            }
            else{
                setErrorList({
                    ...errorList,
                    error:{firstName:false},
                    errorMessage:{}
                })
            }

        }
        if(target.name === "lastName"){
            if(!target.value.length){
                setErrorLastName({
                    error:true,
                    errorMessage:{lastName:"This field is required"}
                })
            }
            else{
                setErrorLastName({
                    error:false,
                    errorMessage:{}
                })
            }
        }
    }
    
    const handlePreferredContact = ({ target }) => {
        setPreferredContact(target?.value);
        updateForm({ preferredContact: target?.value });
    }


    const handleDemographInfo = ({ target }) =>{
        setDemographInfo({
            ...demographInfo,
            [target.name] : target.value,
        });
    }

    const handleEmergencyContact = (e) =>{
        console.log("Emergency Contact", e)
        setEmergencyContact({
            ...emergencyContact,
            [e.target.name] : e.target.value,
        })
    }

    const handleWeightAndHeightMeasure = ({ target }) => {
        setUserMeasures({
            ...measures,
            [target.name]: target.value,
        });

        updateForm({ measures: {
          ...measures,
          [target.name] : target.value
        }});
    }

    const handlePastMedical = ({ target }) => {
        setPastMedicalHistory({
            ...pastMedicalHistory,
            [target.name]: target.checked
        })

        updateForm({ pastMedicalHistory: {
          ...pastMedicalHistory,
          [target.name] : target.checked
        }});
    }

    const handlePastMedicalOthers = ({ target }) => {
        setPastMedicalOthers({
            ...pastMedicalOthers,
            [target.name] : target.value
        });
    }

    const handleFamilyMedicalMember = ({ target }) => {
        setfamilyMedicalOthers({
            ...familyMedicalOthers,
            [target.name] : target.value
        });

        updateForm({ familyMedicalOthers: {
          ...familyMedicalOthers,
          [target.name] : target.value
        }});
    }

    const handleFamilyMedicalHistory = ({ target }) => {
        setfamilyMedicalHistory({
            ...familyMedicalHistory,
            [target.name]:target.checked
        });

        updateForm({ familyMedicalHistory: {
          ...familyMedicalHistory,
          [target.name] : target.checked
        }});
    }

    const handleFamilySecondMember = ({ target }) => {
        setfamilyMedicalSecondOthers({
            ...familyMedicalSecondOthers,
            [target.name] : target.value
        });

        updateForm({ familyMedicalSecondOthers: {
          ...familyMedicalSecondOthers,
          [target.name] : target.value
        }});
    }

    const handleHaveAllergy = ({ target }) => {
        setHasAllergies({
            [target.name] : target.value
        });
        
        updateForm({ hasAllergies: {
          [target.name] : target.value
        }});
    }
    const handleAllergyName = ({ target }) => {
        setHasAllergies({
            ...hasAllergies,
            [target.name] : target.value
        });
    }

    const handleUserAllergies = ({ target }) => {
        setUserAllergies({
            ...allergies,
            [target.name]: target.checked
        });
        
        updateForm({ allergies: {
          ...allergies,
          [target.name] : target.checked
        }});
    }

    const handleCheckedSocial = ({ target }) => {
        setCheckedSocial({
            ...checkedsocial,
            [target.name] : target.checked
        });

        updateForm({ checkedsocial: {
          ...checkedsocial,
          [target.name] : target.checked
        }});
    }

    const handleSocialHistory = ({ target }) => {
        setSocialHistory({
            ...socialHistory,
            [target.name]: target.value || target.checked || '',
        });

        updateForm({ socialHistory: {
          ...socialHistory,
          [target.name] : target.value || target.checked || '',
        }});
    }

    const handleMedications = ({ target }) => {
        setMedications({
            ...medications,
            [ target.name]: target.value
        });
    }

    const handleUseTobaco = ({ target }) => {
        setUseTobacco(target.value);
        updateForm({ useTobacco: target.value });
    }
    
    const handleTravel = ({ target }) => {
        setTravelOverseas(target.value)
        updateForm({ travelOverseas: target.value });
    }

    const handleAnyMedication = ({ target }) => {
        setAnyMedication(target.value)

        updateForm({ anymedication: target.value });
    }

    const handleAnyMedicationDrug = ({ target }) => {
        setIllegalDrug(target.value)

        updateForm({ illegalDrug: target.value });
    }
    const handleAnyMedicationMonths = e => {
        setThreeMonths(e.target.value)
    }
    const handleReason = ({ target }) => {
        setReasonInfo(target.value);
    }

    const handleVeracity = e => {
        setVeracity({
            ...veracity,
            [e.target.name]: e.target.checked
        })
        setShowAlert(false)
        
    }

    //Cuando el usuario envia sus datos
    const onSubmitForm = (e) => {
        if(!veracity.veracity_checked || !veracity.veracity_third){
            setShowAlert(true)
        }
        else{   
            setButtonShow(false);
            e.preventDefault();
            let prevUser = JSON.parse(localStorage.getItem('user_info'))
            let prevUserInfo = prevUser.user
            let responseUserData = {
                birth_sex: demographInfo.birthGender,
                birthday: demographInfo.birth,
                birth_place: demographInfo.birthPlace,
                disclaimers: true,
                email: prevUserInfo.email,
                emergency_contact: {
                    name:userInfo.nameEmergencyContact,
                    relationship:userInfo.relationshipEmergencyContact,
                    phone_number:userInfo.phoneNumberEmergencyContact
                },
                ethnicity: demographInfo.ethnicity,
                first_name: userInfo.firstName,
                last_name: userInfo.lastName,
                marital_status: demographInfo.maritalStatus,
                middle_name: userInfo.middleName,
                phone: {
                    phone_number: userInfo.phoneNumber
                },
                preferred_language:'spanish',
                profile_picture: '',
                user_type: 'USER',
                username: prevUserInfo.username,

            }

            let responseMed0 = {
                weight_data: {
                    weight_unit: measures.weightMeasure,
                    weight: demographInfo.weight
                },
                height_data: {
                    height_unit: measures.heightMeasure,
                    height: demographInfo.height},
                pre_existing_conditions: {
                    highBloodPressure: pastMedicalHistory.highBloodPressure,
                    highCholesterol: pastMedicalHistory.highCholesterol,
                    heart: pastMedicalHistory.heart,
                    thyroid: pastMedicalHistory.thyroid,
                    kidney: pastMedicalHistory.kidney,
                    liver: pastMedicalHistory.liver,
                    asthma: pastMedicalHistory.asthma,
                    diabetes: pastMedicalHistory.diabetes,
                    depression: pastMedicalHistory.depression,
                    arrhythmias: pastMedicalHistory.arrhythmias,
                    panicAttack: pastMedicalHistory.panicAttack,
                    seizure: pastMedicalHistory.seizure,
                    anxiety: pastMedicalHistory.anxiety,
                    copd: pastMedicalHistory.copd,
                    psoriasis: pastMedicalHistory.psoriasis,
                    cancer: pastMedicalHistory.cancer,
                    stroke: pastMedicalHistory.stroke,
                    acidReflux: pastMedicalHistory.acidReflux,
                    skinProblems: pastMedicalHistory.skinProblems,
                    osteopenia: pastMedicalHistory.osteopenia,
                    headache: pastMedicalHistory.headache,
                    other: pastMedicalOthers.otherType,
                    cancerType: pastMedicalOthers.cancerType,
                    heartType: pastMedicalOthers.heartType,
                    liverType: pastMedicalOthers.liverType,
                    kidneyType: pastMedicalOthers.kidneyType
                },
                family_medical_history:{

                    highBloodPressureFam: familyMedicalHistory.highBloodPressureFam, 
                    highCholesterolFam: familyMedicalHistory.highCholesterolFam, 
                    heartFam: familyMedicalHistory.heartFam, 
                    thyroidFam: familyMedicalHistory.thyroidFam, 
                    kidneyFam: familyMedicalHistory.kidneyFam, 
                    liverFam: familyMedicalHistory.liverFam, 
                    asthmaFam: familyMedicalHistory.asthmaFam, 
                    diabetesFam: familyMedicalHistory.diabetesFam, 
                    depressionFam: familyMedicalHistory.depressionFam, 
                    arrhythmiasFam: familyMedicalHistory.arrhythmiasFam, 
                    panicAttackFam: familyMedicalHistory.panicAttackFam, 
                    seizureFam: familyMedicalHistory.seizureFam,
                    anxietyFam: familyMedicalHistory.anxietyFam, 
                    copdFam: familyMedicalHistory.copdFam, 
                    psoriasisFam: familyMedicalHistory.psoriasisFam, 
                    cancerFam: familyMedicalHistory.cancerFam, 
                    strokeFam: familyMedicalHistory.strokeFam, 
                    acidRefluxFam: familyMedicalHistory.acidRefluxFam, 
                    skinProblemsFam: familyMedicalHistory.skinProblemsFam,
                    osteopeniaFam: familyMedicalHistory.osteopeniaFam, 
                    headacheFam: familyMedicalHistory.headacheFam,

                    family_member_other: familyMedicalOthers.familymember,
                    family_member_other_disease: familyMedicalOthers.diseasetype,
                    second_family_other: familyMedicalSecondOthers.secondfamilymember,
                    second_family_other_disease: familyMedicalSecondOthers.seconddiseasetype,
                    third_family_other: familyMedicalSecondOthers.thirdFamilyMember,
                    third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseType,
                    fourth_family_other: familyMedicalSecondOthers.fourthFamilyMember,
                    fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseType,

                    highBloodPressure_family_member_other: familyMedicalOthers.familymemberone,
                    highBloodPressure_family_member_other_disease: familyMedicalOthers.diseasetypeone,
                    highBloodPressure_second_family_other: familyMedicalSecondOthers.secondfamilymemberone,
                    highBloodPressure_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeone,
                    highBloodPressure_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberone,
                    highBloodPressure_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeone,
                    highBloodPressure_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberone,
                    highBloodPressure_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeone,

                    highCholesterol_family_member_other: familyMedicalOthers.familymembertwo,
                    highCholesterol_family_member_other_disease_: familyMedicalOthers.diseasetypetwo,
                    highCholesterol_second_family_other: familyMedicalSecondOthers.secondfamilymembertwo,
                    highCholesterol_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypetwo,
                    highCholesterol_third_family_other: familyMedicalSecondOthers.thirdFamilyMembertwo,
                    highCholesterol_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypetwo,
                    highCholesterol_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembertwo,
                    highCholesterol_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypetwo,

                    heart_family_member_other: familyMedicalOthers.familymemberthree,
                    heart_family_member_other_disease: familyMedicalOthers.diseasetypethree,
                    heart_second_family_other: familyMedicalSecondOthers.secondfamilymemberthree,
                    heart_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypethree,
                    heart_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberthree,
                    heart_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypethree,
                    heart_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberthree,
                    heart_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypethree,

                    thyroid_family_member_other: familyMedicalOthers.familymemberfour,
                    thyroid_family_member_other_disease: familyMedicalOthers.diseasetypefour,
                    thyroid_second_family_other: familyMedicalSecondOthers.secondfamilymemberfour,
                    thyroid_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypefour,
                    thyroid_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberfour,
                    thyroid_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypefour,
                    thyroid_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberfour,
                    thyroid_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypefour,

                    kidney_family_member_other: familyMedicalOthers.familymemberfive,
                    kidney_family_member_other_disease: familyMedicalOthers.diseasetypefive,
                    kidney_second_family_other: familyMedicalSecondOthers.secondfamilymemberfive,
                    kidney_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypefive,
                    kidney_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberfive,
                    kidney_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypefive,
                    kidney_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberfive,
                    kidney_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypefive,

                    liver_family_member_other: familyMedicalOthers.familymembersix,
                    liver_family_member_other_disease: familyMedicalOthers.diseasetypesix,
                    liver_second_family_other: familyMedicalSecondOthers.secondfamilymembersix,
                    liver_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypesix,
                    liver_third_family_other: familyMedicalSecondOthers.thirdFamilyMembersix,
                    liver_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypesix,
                    liver_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembersix,
                    liver_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypesix,

                    asthma_family_member_other: familyMedicalOthers.familymemberseven,
                    asthma_family_member_other_disease: familyMedicalOthers.diseasetypeseven,
                    asthma_second_family_other: familyMedicalSecondOthers.secondfamilymemberseven,
                    asthma_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeseven,
                    asthma_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberseven,
                    asthma_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeseven,
                    asthma_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberseven,
                    asthma_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeseven,

                    diabetes_family_member_other: familyMedicalOthers.familymembereight,
                    diabetes_family_member_other_disease: familyMedicalOthers.diseasetypeeight,
                    diabetes_second_family_other: familyMedicalSecondOthers.secondfamilymembereight,
                    diabetes_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeeight,
                    diabetes_third_family_other: familyMedicalSecondOthers.thirdFamilyMembereight,
                    diabetes_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeeight,
                    diabetes_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembereight,
                    diabetes_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeeight,

                    depression_family_member_other: familyMedicalOthers.familymembernine,
                    depression_family_member_other_disease: familyMedicalOthers.diseasetypenine,
                    depression_second_family_other: familyMedicalSecondOthers.secondfamilymembernine,
                    depression_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypenine,
                    depression_third_family_other: familyMedicalSecondOthers.thirdFamilyMembernine,
                    depression_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypenine,
                    depression_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembernine,
                    depression_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypenine,

                    arrhythmias_family_member_other: familyMedicalOthers.familymemberten,
                    arrhythmias_family_member_other_disease: familyMedicalOthers.diseasetypeten,
                    arrhythmias_second_family_other: familyMedicalSecondOthers.secondfamilymemberten,
                    arrhythmias_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeten,
                    arrhythmias_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberten,
                    arrhythmias_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeten,
                    arrhythmias_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberten,
                    arrhythmias_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeten,

                    panicAttack_family_member_other: familyMedicalOthers.familymembereleven,
                    panicAttack_family_member_other_disease: familyMedicalOthers.diseasetypeeleven,
                    panicAttack_second_family_other: familyMedicalSecondOthers.secondfamilymembereleven,
                    panicAttack_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeeleven,
                    panicAttack_third_family_other: familyMedicalSecondOthers.thirdFamilyMembereleven,
                    panicAttack_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeeleven,
                    panicAttack_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembereleven,
                    panicAttack_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeeleven,

                    seizure_family_member_other: familyMedicalOthers.familymembertwelve,
                    seizure_family_member_other_disease: familyMedicalOthers.diseasetypetwelve,
                    seizure_second_family_other: familyMedicalSecondOthers.secondfamilymembertwelve,
                    seizure_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypetwelve,
                    seizure_third_family_other: familyMedicalSecondOthers.thirdFamilyMembertwelve,
                    seizure_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypetwelve,
                    seizure_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembertwelve,
                    seizure_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypetwelve,

                    anxiety_family_member_other: familyMedicalOthers.familymemberthirteen,
                    anxiety_family_member_other_disease: familyMedicalOthers.diseasetypethirteen,
                    anxiety_second_family_other: familyMedicalSecondOthers.secondfamilymemberthirteen,
                    anxiety_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypethirteen,
                    anxiety_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberthirteen,
                    anxiety_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypethirteen,
                    anxiety_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberthirteen,
                    anxiety_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypethirteen,
                
                    copd_family_member_other: familyMedicalOthers.familymemberfourteen,
                    copd_family_member_other_disease: familyMedicalOthers.diseasetypefourteen,
                    copd_second_family_other: familyMedicalSecondOthers.secondfamilymemberfourteen,
                    copd_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypefourteen,
                    copd_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberfourteen,
                    copd_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypefourteen,
                    copd_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberfourteen,
                    copd_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypefourteen,

                    psoriasis_family_member_other: familyMedicalOthers.familymemberfifteen,
                    psoriasis_family_member_other_disease: familyMedicalOthers.diseasetypefifteen,
                    psoriasis_second_family_other: familyMedicalSecondOthers.secondfamilymemberfifteen,
                    psoriasis_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypefifteen,
                    psoriasis_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberfifteen,
                    psoriasis_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypefifteen,
                    psoriasis_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberfifteen,
                    psoriasis_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypefifteen,

                    cancer_family_member_other: familyMedicalOthers.familymembersixteen,
                    cancer_family_member_other_disease: familyMedicalOthers.diseasetypesixteen,
                    cancer_second_family_other: familyMedicalSecondOthers.secondfamilymembersixteen,
                    cancer_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypesixteen,
                    cancer_third_family_other: familyMedicalSecondOthers.thirdFamilyMembersixteen,
                    cancer_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypesixteen,
                    cancer_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembersixteen,
                    cancer_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypesixteen,

                    stroke_family_member_other: familyMedicalOthers.familymemberseventeen,
                    stroke_family_member_other_disease: familyMedicalOthers.diseasetypeseventeen,
                    stroke_second_family_other: familyMedicalSecondOthers.secondfamilymemberseventeen,
                    stroke_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeseventeen,
                    stroke_third_family_other: familyMedicalSecondOthers.thirdFamilyMemberseventeen,
                    stroke_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeseventeen,
                    stroke_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMemberseventeen,
                    stroke_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeseventeen,

                    acidReflux_family_member_other: familyMedicalOthers.familymembereighteen,
                    acidReflux_family_member_other_disease: familyMedicalOthers.diseasetypeeighteen,
                    acidReflux_second_family_other: familyMedicalSecondOthers.secondfamilymembereighteen,
                    acidReflux_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypeeighteen,
                    acidReflux_third_family_other: familyMedicalSecondOthers.thirdFamilyMembereighteen,
                    acidReflux_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypeeighteen,
                    acidReflux_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembereighteen,
                    acidReflux_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypeeighteen,

                    skinProblems_family_member_other: familyMedicalOthers.familymembernighteen,
                    skinProblems_family_member_other_disease: familyMedicalOthers.diseasetypenighteen,
                    skinProblems_second_family_other: familyMedicalSecondOthers.secondfamilymembernighteen,
                    skinProblems_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypenighteen,
                    skinProblems_third_family_other: familyMedicalSecondOthers.thirdFamilyMembernighteen,
                    skinProblems_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypenighteen,
                    skinProblems_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembernighteen,
                    skinProblems_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypenighteen,

                    osteopenia_family_member_other: familyMedicalOthers.familymembertwenty,
                    osteopenia_family_member_other_disease: familyMedicalOthers.diseasetypetwenty,
                    osteopenia_second_family_other: familyMedicalSecondOthers.secondfamilymembertwenty,
                    osteopenia_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypetwenty,
                    osteopenia_third_family_other: familyMedicalSecondOthers.thirdFamilyMembertwenty,
                    osteopenia_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypetwenty,
                    osteopenia_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembertwenty,
                    osteopenia_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypetwenty,
                    
                    headache_family_member_other: familyMedicalOthers.familymembertwentyone,
                    headache_family_member_other_disease: familyMedicalOthers.diseasetypetwentyone,
                    headache_second_family_other: familyMedicalSecondOthers.secondfamilymembertwentyone,
                    headache_second_family_other_disease: familyMedicalSecondOthers.seconddiseasetypetwentyone,
                    headache_third_family_other: familyMedicalSecondOthers.thirdFamilyMembertwentyone,
                    headache_third_family_other_disease: familyMedicalSecondOthers.thirdDiseaseTypetwentyone,
                    headache_fourth_family_other: familyMedicalSecondOthers.fourthFamilyMembertwentyone,
                    headache_fourth_family_other_disease: familyMedicalSecondOthers.fourthDiseaseTypetwentyone,

                }
            }
            let responseMed1 = {
                allergies: {
                    doesHaveAllergy: hasAllergies.doesHaveAllergy,
                    drugAllergyName: hasAllergies.allergyNameone,
                    foodAllergyName: hasAllergies.allergyNametwo,
                    cosmeticAllergyName: hasAllergies.allergyNamethree,
                    plantAllergyName: hasAllergies.allergyNamefour,
                    petAllergyName: hasAllergies.allergyNamefive,
                    otherAllergyName: hasAllergies.allergyNamesix,
                    drug: allergies.drug, 
                    food: allergies.food,
                    cosmetic: allergies.cosmetic,
                    plant: allergies.plant,
                    pet: allergies.pet,
                    other: allergies.other, 
                    chills: allergies.chills,
                    cough: allergies.cough,
                    diarrhea: allergies.diarrhea,
                    dizziness: allergies.dizziness,
                    eruption: allergies.eruption,
                    fever: allergies.fever,
                    headacheAl: allergies.headacheAl,
                    nausea: allergies.nausea,
                    pruritus: allergies.pruritus,
                    throat: allergies.throat,
                    vomiting: allergies.vomiting,
                    swelling: allergies.swelling,
                    tachycardia: allergies.tachycardia,
                    otherAllergy: hasAllergies.otherAllergy
                },
                medication: {
                    doesTakeMedication: anymedication, 
                    drugName: medications.nameOfDrug, 
                    dose: medications.dose, 
                    frequencyTaken: medications.frequencytaken, 
                    nameSecondDrug: medications.nameOfSecondDrug, 
                    doseSecond: medications.dosesecond, 
                    frequencyTakenSecond: medications.frequencytakensecond,
                    nameThirdDrug: medications.nameOfThirdDrug,
                    doseThird: medications.doseThird,
                    frequencyTakenThird: medications.frequencyTakenThird,
                    nameFourthDrug: medications.nameOfFourthDrug,
                    doseFourth: medications.doseFourth,
                    frequencyTakenFourth: medications.frequencyTakenFourth,
                    nameFifthDrug: medications.nameOfFifthDrug,
                    doseFifth: medications.doseFifth,
                    frequencyTakenFifth: medications.frequencyTakenFifth
                },
                tobacco: {
                    usedTobacco: useTobacco, 
                    ageStarted: socialHistory.ageStarted, 
                    ageStopped: socialHistory.ageStopped, 
                    yearQuit: socialHistory.yearQuit, 
                    cigarrettes: checkedsocial.cigarrettes, 
                    cigarrettesPerDay: socialHistory.cigarrettesPerDay,
                    packsPerDay: socialHistory.packsPerDay, 
                    cigars: checkedsocial.cigars, 
                    cigarsPerDay: socialHistory.cigarsPerDay, 
                    pipe: checkedsocial.pipe,
                    pipePerDay: socialHistory.pipePerDay, 
                    smokelessTobaco: checkedsocial.smokelessTobaco, 
                    smokelessTobaccoPerDay: socialHistory.smokelessTobaccoPerDay
                }

            }
            let responseMed2 = {
                alcohol: {
                    drinkAlcohol: socialHistory.drinkAlcohol, 
                    yearQuitAlcohol: socialHistory.yearQuitAlcohol, 
                    numberOfDrinksCheck: socialHistory.numberOfDrinksCheck, 
                    numberOfDrinksPerDay: socialHistory.numberOfDrinksPerDay, 
                    numberOfDrinksPerWeek: socialHistory.numberOfDrinksPerWeek, 
                    numberOfDrinksPerMonth: socialHistory.numberOfDrinksPerMonth, 
                    drinkType: `
                      ${socialHistory.drinkTypeBeer ? `${t("register.cont.social.history.alcohol.type.beer")}, ` : ''}
                      ${socialHistory.drinkTypeWine ? `${t("register.cont.social.history.alcohol.type.wine")}, ` : ''}
                      ${socialHistory.drinkTypeStrong ? t("register.cont.social.history.alcohol.type.liquor") : ''}
                    `
                },
                activity_level: {activity: activityLevel},
                used_illegal_drugs: {
                    illegal_drugs: illegalDrug,
                    recreationalDrugType: socialHistory.recreationalDrugType
                },
                travel: {
                    travelOverseas: travelOverseas, 
                    countryTraveled: socialHistory.countryTraveled, 
                    cityTraveled: socialHistory.cityTraveled, 
                    periodTraveled: socialHistory.periodTraveled
                },
                reason: {
                    reason_info: reasonInfo,
                    pharmacy: pharmacy
                }

            }
            // let responseMed3 = {

            // }
            // let responseMed4 = {


            // }
            // let responseMed5 = {

            // }

            // let responseMed6 = {

            // }

            let response = {
                ...userInfo,
                ...demographInfo,
            }


            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));

            const address = `https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/`;

            var p0 = fetch(address+'user',{
                method:'PATCH',
                headers:{
                    Authorization: `Bearer ${storedSession.idToken}`, ///ALWAYS SEND THIS TOKEN
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                },
                body:JSON.stringify(responseUserData)
            })
                .then(response => response.json())
                .then(result =>{
                    console.log("RESULT UPDATE USER PROFILE",result)
                }).catch(error =>{
                    console.log("ERROR ?",error)
                })
            setUserInfo({
                firstName: '',
                middleName: '',
                lastName: ''
            })
            //Fetch 0
            var p1 = fetch(address+'user/med-info',{
                method:'PATCH',
                headers:{
                    Authorization: `Bearer ${storedSession.idToken}`,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                },
                body:JSON.stringify(responseMed0)
            })
            .then( response => response.json())
            .then(result =>{
                console.log("Update User Med Info 0", result)
            })
            .catch(error =>{
                console.log("ERROR ?",error)
            })
            // Fetch 1
            var p2 = fetch(address+'user/med-info',{
                method:'PATCH',
                headers:{
                    Authorization: `Bearer ${storedSession.idToken}`,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                },
                body:JSON.stringify(responseMed1)
            })
            .then( response => response.json())
            .then(result =>{
                console.log("Update User Med Info 1", result)
            })
            .catch(error =>{
                console.log("ERROR ?",error)
            })
            //Fetch 2
            var p3 = fetch(address+'user/med-info',{
                method:'PATCH',
                headers:{
                    Authorization: `Bearer ${storedSession.idToken}`,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                },
                body:JSON.stringify(responseMed2)
            })
            .then( response => response.json())
            .then(result =>{
                console.log("Update User Med Info 2", result)
            })
            .catch(error =>{
                console.log("ERROR ?",error)
            })
            // async function Push() {
            //     window.location.href = '/user/payment';
            // }
            // setTimeout(() => {
            //     Push()
            //   }, 22000);
            function Push2(){
                Promise.all([p0, p1, p2, p3]).then(values => {
                    console.log("Promise!!");
                    window.location.href = '/user/payment';
                })
            }
            Push2()
        }
    }

    function showContent() {
        const element1 = document.getElementById("contFamilyHistori1");
        const element2 = document.getElementById("contFamilyHistori2");
        const element3 = document.getElementById("contFamilyHistori3");
        const element4 = document.getElementById("contFamilyHistori4");
        const element5 = document.getElementById("contFamilyHistori5");
        const element6 = document.getElementById("contFamilyHistori6");
        const element7 = document.getElementById("contFamilyHistori7");
        const element8 = document.getElementById("contFamilyHistori8");
        const element9 = document.getElementById("contFamilyHistori9");
        const element10 = document.getElementById("contFamilyHistori10");
        const element11 = document.getElementById("contFamilyHistori11");
        const element12 = document.getElementById("contFamilyHistori12");
        const element13 = document.getElementById("contFamilyHistori13");
        const element14 = document.getElementById("contFamilyHistori14");
        const element15 = document.getElementById("contFamilyHistori15");
        const element16 = document.getElementById("contFamilyHistori16");
        const element17 = document.getElementById("contFamilyHistori17");
        const element18 = document.getElementById("contFamilyHistori18");
        const element19 = document.getElementById("contFamilyHistori19");
        const element20 = document.getElementById("contFamilyHistori20");
        const element21 = document.getElementById("contFamilyHistori21");
        const check1 = document.getElementById("check1");
        const check2 = document.getElementById("check2");
        const check3 = document.getElementById("check3");
        const check4 = document.getElementById("check4");
        const check5 = document.getElementById("check5");
        const check6 = document.getElementById("check6");
        const check7 = document.getElementById("check7");
        const check8 = document.getElementById("check8");
        const check9 = document.getElementById("check9");
        const check10 = document.getElementById("check10");
        const check11 = document.getElementById("check11");
        const check12 = document.getElementById("check12");
        const check13 = document.getElementById("check13");
        const check14 = document.getElementById("check14");
        const check15 = document.getElementById("check15");
        const check16 = document.getElementById("check16");
        const check17 = document.getElementById("check17");
        const check18 = document.getElementById("check18");
        const check19 = document.getElementById("check19");
        const check20 = document.getElementById("check20");
        const check21 = document.getElementById("check21");

        if (check1.checked) {element1.style.display='block';}
        else {element1.style.display='none';}

        if (check2.checked) {element2.style.display='block';}
        else {element2.style.display='none';}

        if (check3.checked) {element3.style.display='block';}
        else {element3.style.display='none';}

        if (check4.checked) {element4.style.display='block';}
        else {element4.style.display='none';}

        if (check5.checked) {element5.style.display='block';}
        else {element5.style.display='none';}

        if (check6.checked) {element6.style.display='block';}
        else {element6.style.display='none';}

        if (check7.checked) {element7.style.display='block';}
        else {element7.style.display='none';}

        if (check8.checked) {element8.style.display='block';}
        else {element8.style.display='none';}

        if (check9.checked) {element9.style.display='block';}
        else {element9.style.display='none';}

        if (check10.checked) {element10.style.display='block';}
        else {element10.style.display='none';}

        if (check11.checked) {element11.style.display='block';}
        else {element11.style.display='none';}

        if (check12.checked) {element12.style.display='block';}
        else {element12.style.display='none';}

        if (check13.checked) {element13.style.display='block';}
        else {element13.style.display='none';}

        if (check14.checked) {element14.style.display='block';}
        else {element14.style.display='none';}

        if (check15.checked) {element15.style.display='block';}
        else {element15.style.display='none';}

        if (check16.checked) {element16.style.display='block';}
        else {element16.style.display='none';}

        if (check17.checked) {element17.style.display='block';}
        else {element17.style.display='none';}

        if (check18.checked) {element18.style.display='block';}
        else {element18.style.display='none';}

        if (check19.checked) {element19.style.display='block';}
        else {element19.style.display='none';}

        if (check20.checked) {element20.style.display='block';}
        else {element20.style.display='none';}

        if (check21.checked) {element21.style.display='block';}
        else {element21.style.display='none';}
    }

    const handleControl = (name) => {
      setControls({ ...controls, [name]: true });
      updateFieldForm(name, true, 'controls', controls)
    }

    const updateFieldForm = (name, value, key, state) => {
      updateForm({ [key]:
        { ...state, [name]: value },
      });
    };

    const updateField = ({ target }, key, state) => {
      updateFieldForm(target.name, target.value, key, state);
    };

    return (  
        <>
            <Disclaimer show={modalShow} onHide={() => setModalShow(false)}/>
            <Header/>
                <form className={styles.container} 
                onSubmit={onSubmitForm}
                >

                {/* basic info */}
                <Grid container direction="column">
                    <h3 className={styles.confidential}>{t("register.description")}</h3>
                    <span className={styles.line}></span><h3 className={classes.Title}>{t("register.subtitle.basic")}</h3>

                    <Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                        
                        <Grid item xs={12} sm={6} direction="row">
                            <div><label className={styles.subTitle}>{t("register.cont.name")}</label></div>
                            <div className={classes.contOptions}>
                                <Grid xs={12} sm={4} className={classes.mr10}>
                                    <TextField type="text" className={classes.inputText} 
                                        required
                                        placeholder={t("register.cont.first")}
                                        name="firstName"
                                        value={firstName}
                                        onChange={handleUserInfo}
                                        onBlur={event => updateField(event, 'userInfo', userInfo)}
                                        error = {errorList.error.firstName}
                                        helperText = {errorList.errorMessage.firstName}
                                        InputProps={{
                                            className: classes.inputbasic,
                                            disableUnderline: true
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} className={classes.mr10}>
                                    <TextField xs={14} sm={3} type="text" className={classes.inputText} 
                                        placeholder={t("register.cont.middle")} 
                                        name="middleName"
                                        value={middleName}
                                        onChange={handleUserInfo}
                                        onBlur={event => updateField(event, 'userInfo', userInfo)}
                                        InputProps={{
                                            className: classes.inputbasic,
                                            disableUnderline: true
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} className={classes.mr10}>
                                    <TextField type="text" className={classes.inputText} xs={14} sm={3}   
                                        required
                                        placeholder={t("register.cont.last")}
                                        name="lastName"
                                        value={lastName}
                                        onChange={handleUserInfo}
                                        onBlur={event => updateField(event, 'userInfo', userInfo)}
                                        error = {errorLastName.error}
                                        helperText = {errorLastName.errorMessage.lastName}
                                        InputProps={{
                                            className: classes.inputbasic,
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3} direction="row" className={classes.contOptions}>
                            <Grid item xs={12} sm={6}>
                                <div>
                                    <label className={styles.subTitle}>{t("register.subtitle.phone")}</label>
                                </div>
                                <div item xs={12} direction="row" className={styles.names}>
                                    <TextField type="tel" className={classes.inputText} 
                                        required
                                        placeholder="(00) 000 000 *"
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        onChange={handleUserInfo}
                                        onBlur={event => updateField(event, 'userInfo', userInfo)}
                                        InputProps={{
                                            className: classes.inputbasic,
                                            disableUnderline: true,
                                            "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                display: "none"
                                            }
                                        }} 
                                    />
                                </div>                               
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} direction="column">
                            <div>
                                <label className={styles.subTitle}>{t("register.subtitle.general.contact")}</label>
                            </div>
                            <Grid direction="row" className={styles.names}>
                                <RadioGroup name="preferredcontact" row onChange={handlePreferredContact} value={preferredContact}>
                                    <FormControlLabel className={styles.subTitle}
                                        value="homeTelephone"
                                        control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.cont.home.phone")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value="mobile"
                                        control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.cont.mobile.phone")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
                {/* basic info */}
                
                {/* demographic */}
                <Grid container direction="column" >
                    <h3 className={classes.Title}>{t("register.subtitle.demographics")}</h3>
                    <Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>

                        <Grid item xs={12} sm={2} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.subtitle.date.birth")}</label>
                            <TextField type="date" required className={styles.inputText} 
                                defaultValue={t("register.cont.date")} /*"DD/MM/YYYY"*/
                                value={birth}
                                onChange = {handleDemographInfo}
                                onBlur={event => updateField(event, 'demographInfo', demographInfo)}
                                name="birth"
                                InputProps={{
                                    className: classes.inputDate,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.cont.place.birth")}</label>
                            <TextField type="text" className={classes.inputText} 
                                placeholder={t("register.cont.country")}/*"Country" */
                                value={birthPlace}
                                onChange = {handleDemographInfo}
                                onBlur={event => updateField(event, 'demographInfo', demographInfo)} 
                                name="birthPlace"
                                InputProps={{
                                    className: classes.inputbasic,
                                    disableUnderline: true
                                }}  
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={6} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.subtitle.gender")}</label>
                            <Grid direction="row">
                                <RadioGroup
                                  name="birthGender"
                                  onChange={handleDemographInfo}
                                  value={birthGender}
                                  onBlur={event => updateField(event, 'demographInfo', demographInfo)} 
                                  row
                                  style={{ marginRight: "1vw" }}
                                  className={styles.marital}
                                >
                                    <FormControlLabel className={styles.subTitle}
                                        value = "male" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.cont.gender.male")}</Typography>}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "female" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.cont.gender.female")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "unknown" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.cont.gender.unknown")}</Typography>}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                                
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.contFormLabelP}>
                                <label className={styles.subTitle}>{t("register.subtitle.ethnicity")}</label>
                                <Grid direction="row" className={styles.names}>
                                    <Grid direction="row" align-items="left">
                                        <RadioGroup
                                          name="ethnicity"
                                          onChange={handleDemographInfo}
                                          value={ethnicity}
                                          onBlur={event => updateField(event, 'demographInfo', demographInfo)} 
                                          row
                                        >
                                            <FormControlLabel className={classes.subTitle} 
                                                value="hispanic" control={<Radio color="primary"/>} 
                                                label={<Typography className={classes.subTitle}>{t("register.cont.ethnicity.hispanic")}</Typography>}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel className={classes.subTitle} 
                                                value="white" control={<Radio color="primary"/>} 
                                                label={<Typography className={classes.subTitle}>{t("register.cont.ethnicity.white")}</Typography>}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel className={classes.subTitle} 
                                                value="africanAmerican" control={<Radio color="primary"/>}
                                                label={<Typography className={classes.subTitle}>{t("register.cont.ethnicity.african")}</Typography>}
                                                labelPlacement="end" 
                                            />
                                            <FormControlLabel className={classes.subTitle}
                                                control={<Radio color="primary"/>} 
                                                label={<Typography className={classes.subTitle}>{t("register.cont.ethnicity.other")}</Typography>}
                                                labelPlacement="end"
                                                value ="other"
                                            />
                                            {ethnicity === 'other' ? <TextField
                                                onChange = {handleDemographInfo}
                                                value={ethnicity2}
                                                onBlur={event => updateField(event, 'demographInfo', demographInfo)}
                                                placeholder={t("register.cont.ethnicity.other")}/*"  Other"*/
                                                name = "ethnicity2"
                                                type="text"
                                                InputProps={{
                                                    className: classes.inputText,
                                                    disableUnderline: true
                                                }}   
                                            /> 
                                            : null }
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
     
                        <Grid item xs={12} sm={2} direction="row" className={classes.pr25}>
                            <label className={styles.subTitle}>{t("register.subtitle.weight")}</label>
                            <Grid direction="row" className={classes.selectContwh}>
                                <TextField 
                                    className={classes.inputTextwh} 
                                    defaultValue=""
                                    onChange = {handleDemographInfo}
                                    value={weight}
                                    onBlur={event => updateField(event, 'demographInfo', demographInfo)}
                                    name="weight"
                                    placeholder={0}
                                    InputProps={{
                                        className: classes.inputbasic,
                                        disableUnderline: true
                                    }}
                                />
                                {isMobile ? (
                                    <NativeSelect
                                      className={styles.nativeSelect}
                                      onChange={handleWeightAndHeightMeasure}
                                      value={weightMeasure}
                                      name="weightMeasure"
                                    >
                                      <option aria-label="None" value="" />
                                      <option value="kg">KG</option>
                                      <option value="lb">LB</option>
                                    </NativeSelect>
                                ) : (
                                    <Select
                                      onChange={handleWeightAndHeightMeasure}
                                      value={weightMeasure}
                                      displayEmpty
                                      name="weightMeasure"
                                    >
                                      <MenuItem value="kg"><em>KG</em></MenuItem>
                                      <MenuItem value="lb">LB</MenuItem>
                                    </Select>
                                )}
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2} direction="row" className={classes.pr25}>
                            <label className={styles.subTitle}>{t("register.subtitle.height")}</label>
                            <Grid direction="row" className={classes.selectContwh}>
                                <TextField 
                                    className={classes.inputTextwh} 
                                    defaultValue=""
                                    value={height}
                                    onBlur={event => updateField(event, 'demographInfo', demographInfo)}
                                    onChange = {handleDemographInfo} 
                                    name="height"
                                    placeholder={0}
                                    InputProps={{
                                    className: classes.inputbasic,
                                        disableUnderline: true
                                    }}
                                />
                                {isMobile ? (
                                  <NativeSelect
                                    className={styles.nativeSelect}
                                    onChange={handleWeightAndHeightMeasure}
                                    value={heightMeasure}
                                    name="heightMeasure"
                                  >
                                      <option aria-label="None" value="" />
                                      <option value="CM">CM</option>
                                      <option value="FT">FT</option>
                                  </NativeSelect>
                                ) : (
                                <Select
                                  onChange={handleWeightAndHeightMeasure}
                                  value={heightMeasure}
                                  displayEmpty
                                  name="heightMeasure"
                                >
                                    <MenuItem value="CM"><em>CM</em></MenuItem>
                                    <MenuItem value="FT">FT</MenuItem>
                                </Select>   
                                )}
                                                         
                            </Grid>
                            
                        </Grid>
                        
                        <Grid item xs={12} sm={6} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.subtitle.marital")}</label>
                            <Grid container direction="row" justify="left" className={styles.align}>
                                <RadioGroup
                                  name="maritalStatus"
                                  onChange={handleDemographInfo}
                                  value={maritalStatus}
                                  onBlur={event => updateField(event, 'demographInfo', demographInfo)}
                                  row
                                  className={styles.marital}
                                >
                                        <FormControlLabel className={styles.subTitle}
                                            value = "single" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.marital.1")}</Typography>}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel className={styles.subTitle}
                                            value = "married" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.marital.2")}</Typography>}
                                            labelPlacement="end" 
                                        />
                                        <FormControlLabel className={styles.subTitle}
                                            value = "partnered" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.marital.3")}</Typography>}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel className={styles.subTitle}
                                            value = "separated" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.marital.4")}</Typography>}
                                            labelPlacement="end" 
                                        />
                                        <FormControlLabel className={styles.subTitle}
                                            value = "divorced" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.marital.5")}</Typography>}
                                            labelPlacement="end" 
                                        />
                                        <FormControlLabel className={styles.subTitle}
                                            value = "widowed" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.marital.6")}</Typography>}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                            </Grid>
                        </Grid>                        
                    
                    </Grid>
                </Grid> 
                {/* demographic */}
                
                {/* emergency contact */}
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.emergency.contact")}</h3>
                    <Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                        <Grid item xs={12} sm={4} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.cont.emergency.contact.full")}</label>
                            <TextField
                                type="text"
                                required
                                className={classes.inputText}
                                defaultValue=""
                                value={nameEmergencyContact}
                                onBlur={event => updateField(event, 'userInfo', userInfo)}
                                onChange={handleUserInfo}
                                name="nameEmergencyContact"
                                placeholder={t("register.cont.emergency.contact.full")}/*"  Full Name *"*/
                                InputProps={{
                                    className: classes.inputbasic,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.cont.emergency.contact.relantionship")}</label>
                           <TextField type="text"
                                required
                                className={classes.inputText}
                                defaultValue=""
                                value={relationshipEmergencyContact}
                                onBlur={event => updateField(event, 'userInfo', userInfo)}
                                onChange={handleUserInfo}
                                name="relationshipEmergencyContact"
                                placeholder={t("register.cont.emergency.contact.relantionship")}/*"  Relationship *"*/
                                InputProps={{
                                    className: classes.inputbasic,
                                    disableUnderline: true
                                }}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={4} direction="row" className={classes.contFormLabelP}>
                            <label className={styles.subTitle}>{t("register.cont.emergency.contact.phone")}</label>
                            <TextField type="tel"
                                required
                                className={classes.inputText}
                                defaultValue=""
                                value={phoneNumberEmergencyContact}
                                onBlur={event => updateField(event, 'userInfo', userInfo)}
                                onChange={handleUserInfo}
                                name="phoneNumberEmergencyContact"
                                placeholder={t("register.cont.emergency.contact.phone")}/*"  Phone number *"*/
                                InputProps={{
                                    className: classes.inputbasic,
                                    disableUnderline: true
                                }}
                            />  
                        </Grid>
                        
                    </Grid>
                   
                </Grid> 
                {/* emergency contact */}
                
                {/* past-medical history */}
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.past.medical")}</h3>
                    <p className={styles.subTitle}>{t("register.cont.past.medical")}</p>
                    <Grid container xs={12} direction="row" justify="space-between" alignItems="baseline" wrap="wrap" className = {classes.contForm}>
                        <FormControlLabel
                            control={<Checkbox checked={highBloodPressure} onChange={handlePastMedical} name="highBloodPressure" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.1")}</Typography>}
                            className = {classes.contFormLabel}

                        />
                        <FormControlLabel
                            control={<Checkbox checked={highCholesterol} onChange={handlePastMedical} name="highCholesterol" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.2")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={heart} onChange={handlePastMedical} name="heart" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.3")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={thyroid} onChange={handlePastMedical} name="thyroid" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.4")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={kidney} onChange={handlePastMedical} name="kidney" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.5")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={liver} onChange={handlePastMedical} name="liver" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.6")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asthma} onChange={handlePastMedical} name="asthma" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.7")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={diabetes} onChange={handlePastMedical} name="diabetes" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.8")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={depression} onChange={handlePastMedical} name="depression" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.9")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={arrhythmias} onChange={handlePastMedical} name="arrhythmias" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.10")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={panicAttack} onChange={handlePastMedical} name="panicAttack" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.11")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={seizure} onChange={handlePastMedical} name="seizure" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.12")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={anxiety} onChange={handlePastMedical} name="anxiety" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.13")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={copd} onChange={handlePastMedical} name="copd" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.14")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={psoriasis} onChange={handlePastMedical} name="psoriasis" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.15")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={cancer} onChange={handlePastMedical} name="cancer" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.16")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={stroke} onChange={handlePastMedical} name="stroke" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.17")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={acidReflux} onChange={handlePastMedical} name="acidReflux" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.18")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={skinProblems} onChange={handlePastMedical} name="skinProblems" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.19")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={osteopenia} onChange={handlePastMedical} name="osteopenia" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.20")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={headache} onChange={handlePastMedical} name="headache" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.past.medical.21")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <div><Checkbox color="primary" size="small"/><input type="text" className={styles.inputText} placeholder={t("register.option.past.medical.other")}/*"Other"*//></div>
                    </Grid>
                    <Grid container xs={9} direction="row" justify="space-between" alignItems="center">
                        {pastMedicalHistory.cancer ? (
                            <div className={styles.linea}>
                                <p className={styles.subTitle}>{t("register.subtitle.cancer.type")}</p>
                                <input
                                  type="text"
                                  className={styles.inputText3}
                                  placeholder={t("register.cont.ie")} //"  i.e., skin"
                                  onChange={handlePastMedicalOthers}
                                  onBlur={event => updateField(event, 'pastMedicalOthers', pastMedicalOthers)}
                                  name="cancerType"
                                  value={cancerType}
                                />
                            </div>
                        ) : null}
                        {pastMedicalHistory.heart ? (
                            <div className={styles.linea}>
                                <p className={styles.subTitle}>{t("register.subtitle.heart.disease")}</p>
                                <input
                                  type="text"
                                  className={styles.inputText3}
                                  placeholder={t("register.cont.heart.disease")} //"  i.e., Coronary Heart disease"
                                  onChange={handlePastMedicalOthers}
                                  onBlur={event => updateField(event, 'pastMedicalOthers', pastMedicalOthers)}
                                  name="heartType"
                                  value={heartType}
                                />
                            </div>
                        ) : null}
                        {pastMedicalHistory.liver ? (
                            <div className={styles.linea}>
                                <p className={styles.subTitle}>{t("register.subtitle.liver.disease")}</p>
                                <input
                                  type="text"
                                  className={styles.inputText3}
                                  placeholder={t("register.cont.liver.disease")}/*"  i.e., Fibrosis"*/
                                  onChange={handlePastMedicalOthers}
                                  onBlur={event => updateField(event, 'pastMedicalOthers', pastMedicalOthers)}
                                  name="liverType"
                                  value={liverType}
                                />
                            </div>
                        ) : null}
                        {pastMedicalHistory.kidney ? (
                            <div className={styles.linea}>
                                <p className={styles.subTitle}>{t("register.subtitle.kidney.disease")}</p>
                                <input
                                  type="text"
                                  className={styles.inputText3}
                                  placeholder={t("register.cont.kidney.disease")}/*"  i.e., Acute Lobar Nephronia"*/
                                  onChange={handlePastMedicalOthers}
                                  onBlur={event => updateField(event, 'pastMedicalOthers', pastMedicalOthers)}
                                  name="kidneyType"
                                  value={kidneyType}
                                />
                            </div>
                        ) : null}
                    </Grid>
                    
                </Grid> 
                {/* past-medical history */}

                {/* family-medical history */}
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.family.medical")}</h3>
                    <p className={styles.subTitle}>{t("register.cont.family.medical")}</p>
                    <Grid container xs={12} direction="row" justify="space-between" alignItems="baseline" wrap="wrap" className={classes.contForm}>

                        <FormControlLabel
                            control={<Checkbox checked={highBloodPressureFam} id="check1" value="1" onChange={handleFamilyMedicalHistory} name="highBloodPressureFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.1")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={highCholesterolFam} id="check2" value="1" onChange={handleFamilyMedicalHistory} name="highCholesterolFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.2")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={heartFam} id="check3" value="1" onChange={handleFamilyMedicalHistory} name="heartFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.3")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={thyroidFam} id="check4" value="1" onChange={handleFamilyMedicalHistory} name="thyroidFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.4")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={kidneyFam} id="check5" value="1" onChange={handleFamilyMedicalHistory} name="kidneyFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.5")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={liverFam} id="check6" value="1" onChange={handleFamilyMedicalHistory} name="liverFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.6")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asthmaFam} id="check7" value="1" onChange={handleFamilyMedicalHistory} name="asthmaFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.7")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={diabetesFam} id="check8" value="1" onChange={handleFamilyMedicalHistory} name="diabetesFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.8")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={depressionFam} id="check9" value="1" onChange={handleFamilyMedicalHistory} name="depressionFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.9")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={arrhythmiasFam} id="check10" value="1" onChange={handleFamilyMedicalHistory} name="arrhythmiasFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.10")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={panicAttackFam} id="check11" value="1" onChange={handleFamilyMedicalHistory} name="panicAttackFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.11")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={seizureFam} id="check12" value="1" onChange={handleFamilyMedicalHistory} name="seizureFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.12")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={anxietyFam} id="check13" value="1" onChange={handleFamilyMedicalHistory} name="anxietyFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.13")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={copdFam}  id="check14" value="1"onChange={handleFamilyMedicalHistory} name="copdFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.14")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={psoriasisFam} id="check15" value="1" onChange={handleFamilyMedicalHistory} name="psoriasisFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.15")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={cancerFam} id="check16" value="1" onChange={handleFamilyMedicalHistory} name="cancerFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.16")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={strokeFam} id="check17" value="1" onChange={handleFamilyMedicalHistory} name="strokeFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.17")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={acidRefluxFam} id="check18" value="1" onChange={handleFamilyMedicalHistory} name="acidRefluxFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.18")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={skinProblemsFam} id="check19" value="1" onChange={handleFamilyMedicalHistory} name="skinProblemsFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.19")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={osteopeniaFam} id="check20" value="1" onChange={handleFamilyMedicalHistory} name="osteopeniaFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.20")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={headacheFam} id="check21" value="1" onChange={handleFamilyMedicalHistory} name="headacheFam" color="primary"/>}
                            label={<Typography className={classes.subTitle}>{t("register.option.family.medical.21")}</Typography>}
                            className = {classes.contFormLabel}
                        />
                    </Grid>
                    <Grid>
                        <div id="contFamilyHistori1" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.1")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select
                                              onChange={handleFamilyMedicalMember}
                                              name="familymemberone"
                                              value={familymemberone}
                                              inputProps={{ 'aria-label': 'Without label' }}
                                              style={{"margin-left":"10px", width:"150px"}}
                                            >
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeone" value={diseasetypeone} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>*/}
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberone')}/> 
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMemberone ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberone" value={secondfamilymemberone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                    <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                    <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                    <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                    <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                    <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeone" value={seconddiseasetypeone} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberone')}/>
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberone ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberone" value={thirdFamilyMemberone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeone" value={thirdDiseaseTypeone} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberone')}/> 
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberone ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberone" value={fourthFamilyMemberone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                    <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                    <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                    <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                    <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                    <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeone" value={fourthDiseaseTypeone} onChange={handleFamilySecondMember}/>*/}
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori2" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.2")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymembertwo" value={familymembertwo} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypetwo" value={diseasetypetwo} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>*/}
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembertwo')}/>       
                                        </div>
                                                            
                                    </Grid>
                                    {addOtherMembertwo ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembertwo" value={secondfamilymembertwo} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypetwo" value={seconddiseasetypetwo} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembertwo')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembertwo ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembertwo" value={thirdFamilyMembertwo} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypetwo" value={thirdDiseaseTypetwo} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembertwo')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembertwo ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                           <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembertwo" value={fourthFamilyMembertwo} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypetwo" value={fourthDiseaseTypetwo} onChange={handleFamilySecondMember}/>*/}
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            
                            </Grid>
                        </div>
                        <div id="contFamilyHistori3" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.3")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymemberthree" value={familymemberthree} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            <p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypethree" value={diseasetypethree} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberthree')}/>     
                                        </div>
                                                               
                                    </Grid>
                                    {addOtherMemberthree ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberthree" value={secondfamilymemberthree} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                          </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypethree" value={seconddiseasetypethree} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberthree')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberthree ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberthree" value={thirdFamilyMemberthree} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypethree" value={thirdDiseaseTypethree} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberthree')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberthree ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberthree" value={fourthFamilyMemberthree} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypethree" value={fourthDiseaseTypethree} onChange={handleFamilySecondMember}/>
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            
                            </Grid>
                        </div>
                        <div id="contFamilyHistori4" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.4")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymemberfour" value={familymemberfour} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypefour" value={diseasetypefour} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>*/}
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberfour')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMemberfour ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberfour" value={secondfamilymemberfour} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypefour" value={seconddiseasetypefour} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberfour')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberfour ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberfour" value={thirdFamilyMemberfour} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypefour" value={thirdDiseaseTypefour} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberfour')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberfour ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberfour" value={fourthFamilyMemberfour} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypefour" value={fourthDiseaseTypefour} onChange={handleFamilySecondMember}/>*/}
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            
                            </Grid>
                        </div>
                        <div id="contFamilyHistori5" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.5")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymemberfive" value={familymemberfive} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            <p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypefive" value={diseasetypefive} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberfive')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMemberfive ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberfive" value={secondfamilymemberfive} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypefive" value={seconddiseasetypefive} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberfive')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberfive ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberfive" value={thirdFamilyMemberfive} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypefive" value={thirdDiseaseTypefive} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addfourthMemberfive')}/>
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addfourthMemberfive ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberfive" value={fourthFamilyMemberfive} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypefive" value={fourthDiseaseTypefive} onChange={handleFamilySecondMember}/>
                                                
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            
                            </Grid>
                        </div>
                        <div id="contFamilyHistori6" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.6")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembersix" value={familymembersix} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            <p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypesix" value={diseasetypesix} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembersix')}/>      
                                        </div>
                                                             
                                    </Grid>
                                    {addOtherMembersix ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembersix" value={secondfamilymembersix} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypesix" value={seconddiseasetypesix} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembersix')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembersix ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembersix" value={thirdFamilyMembersix} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypesix" value={thirdDiseaseTypesix} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembersix')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembersix ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembersix" value={fourthFamilyMembersix} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypesix" value={fourthDiseaseTypesix} onChange={handleFamilySecondMember}/>
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            
                            </Grid>
                        </div>
                        <div id="contFamilyHistori7" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.7")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymemberseven" value={familymemberseven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeseven" value={diseasetypeseven} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberseven')}/>        
                                        </div>
                                                           
                                    </Grid>
                                    {addOtherMemberseven ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberseven" value={secondfamilymemberseven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeseven" value={seconddiseasetypeseven} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberseven')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberseven ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberseven" value={thirdFamilyMemberseven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeseven" value={thirdDiseaseTypeseven} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberseven')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberseven ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberseven" value={fourthFamilyMemberseven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeseven" value={fourthDiseaseTypeseven} onChange={handleFamilySecondMember}/>
                                                */}  
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori8" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.8")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembereight" value={familymembereight} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeeight" value={diseasetypeeight} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>*/}
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembereight')}/>      
                                        </div>
                                                             
                                    </Grid>
                                    {addOtherMembereight ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembereight" value={secondfamilymembereight} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeeight" value={seconddiseasetypeeight} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembereight')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembereight ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembereight" value={thirdFamilyMembereight} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeeight" value={thirdDiseaseTypeeight} onChange={handleFamilySecondMember}/>*/}
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembereight')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembereight ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembereight" value={fourthFamilyMembereight} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeeight" value={fourthDiseaseTypeeight} onChange={handleFamilySecondMember}/>
                                                */}
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori9" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.9")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembernine" value={familymembernine} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                           {/* <p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypenine" value={diseasetypenine} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembernine')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMembernine ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembernine" value={secondfamilymembernine} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypenine" value={seconddiseasetypenine} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembernine')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembernine ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembernine" value={thirdFamilyMembernine} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypenine" value={thirdDiseaseTypenine} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembernine')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembernine ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembernine" value={fourthFamilyMembernine} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypenine" value={fourthDiseaseTypenine} onChange={handleFamilySecondMember}/>
                                                */}  
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori10" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.10")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymemberten" value={familymemberten} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeten" value={diseasetypeten} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberten')}/>      
                                        </div>
                                                             
                                    </Grid>
                                    {addOtherMemberten ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberten" value={secondfamilymemberten} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeten" value={seconddiseasetypeten} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberten')}/>   
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberten ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberten" value={thirdFamilyMemberten} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeten" value={thirdDiseaseTypeten} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addfourthMemberten')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addfourthMemberten ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                                <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberten" value={fourthFamilyMemberten} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                    
                                                    <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeten" value={fourthDiseaseTypeten} onChange={handleFamilySecondMember}/>
                                                */}    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori11" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.11")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymembereleven" value={familymembereleven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeeleven" value={diseasetypeeleven} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembereleven')}/>       
                                        </div>
                                                            
                                    </Grid>
                                    {addOtherMembereleven ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembereleven" value={secondfamilymembereleven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeeleven" value={seconddiseasetypeeleven} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembereleven')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembereleven ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembereleven" value={thirdFamilyMembereleven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeeleven" value={thirdDiseaseTypeeleven} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembereleven')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembereleven ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembereleven" value={fourthFamilyMembereleven} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeeleven" value={fourthDiseaseTypeeleven} onChange={handleFamilySecondMember}/>
                                                */}    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori12" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.12")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembertwelve" value={familymembertwelve} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypetwelve" value={diseasetypetwelve} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembertwelve')}/>       
                                        </div>
                                                            
                                    </Grid>
                                    {addOtherMembertwelve ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembertwelve" value={secondfamilymembertwelve} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypetwelve" value={seconddiseasetypetwelve} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembertwelve')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembertwelve ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembertwelve" value={thirdFamilyMembertwelve} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypetwelve" value={thirdDiseaseTypetwelve} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembertwelve')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembertwelve ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembertwelve" value={fourthFamilyMembertwelve} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypetwelve" value={fourthDiseaseTypetwelve} onChange={handleFamilySecondMember}/>
                                                */}
                                            </div>
                                             
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori13" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.13")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymemberthirteen" value={familymemberthirteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypethirteen" value={diseasetypethirteen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberthirteen')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMemberthirteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberthirteen" value={secondfamilymemberthirteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypethirteen" value={seconddiseasetypethirteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberthirteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberthirteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberthirteen" value={thirdFamilyMemberthirteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypethirteen" value={thirdDiseaseTypethirteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberthirteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberthirteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberthirteen" value={fourthFamilyMemberthirteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypethirteen" value={fourthDiseaseTypethirteen} onChange={handleFamilySecondMember}/>
                                                */}    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori14" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.14")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymemberfourteen" value={familymemberfourteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypefourteen" value={diseasetypefourteen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberfourteen')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMemberfourteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberfourteen" value={secondfamilymemberfourteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypefourteen" value={seconddiseasetypefourteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberfourteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberfourteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberfourteen" value={thirdFamilyMemberfourteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypefourteen" value={thirdDiseaseTypefourteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberfourteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberfourteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberfourteen" value={fourthFamilyMemberfourteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypefourteen" value={fourthDiseaseTypefourteen} onChange={handleFamilySecondMember}/>
                                                */} 
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori15" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.15")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymemberfifteen" value={familymemberfifteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypefifteen" value={diseasetypefifteen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberfifteen')}/>        
                                        </div>
                                                           
                                    </Grid>
                                    {addOtherMemberfifteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberfifteen" value={secondfamilymemberfifteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypefifteen" value={seconddiseasetypefifteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberfifteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberfifteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberfifteen" value={thirdFamilyMemberfifteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypefifteen" value={thirdDiseaseTypefifteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addfourthMemberfifteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addfourthMemberfifteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberfifteen" value={fourthFamilyMemberfifteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypefifteen" value={fourthDiseaseTypefifteen} onChange={handleFamilySecondMember}/>
                                                */}    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori16" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.16")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembersixteen" value={familymembersixteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            <p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypesixteen" value={diseasetypesixteen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembersixteen')}/>        
                                        </div>
                                                           
                                    </Grid>
                                    {addOtherMembersixteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembersixteen" value={secondfamilymembersixteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypesixteen" value={seconddiseasetypesixteen} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembersixteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembersixteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembersixteen" value={thirdFamilyMembersixteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypesixteen" value={thirdDiseaseTypesixteen} onChange={handleFamilySecondMember}/>
                                                <AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembersixteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembersixteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembersixteen" value={fourthFamilyMembersixteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                <p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypesixteen" value={fourthDiseaseTypesixteen} onChange={handleFamilySecondMember}/>
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori17" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.17")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymemberseventeen" value={familymemberseventeen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeseventeen" value={diseasetypeseventeen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMemberseventeen')}/>    
                                        </div>
                                                               
                                    </Grid>
                                    {addOtherMemberseventeen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymemberseventeen" value={secondfamilymemberseventeen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeseventeen" value={seconddiseasetypeseventeen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMemberseventeen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMemberseventeen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMemberseventeen" value={thirdFamilyMemberseventeen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeseventeen" value={thirdDiseaseTypeseventeen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMemberseventeen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMemberseventeen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMemberseventeen" value={fourthFamilyMemberseventeen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeseventeen" value={fourthDiseaseTypeseventeen} onChange={handleFamilySecondMember}/>
                                                 */}   
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori18" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.18")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilyMedicalMember} name="familymembereighteen" value={familymembereighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypeeighteen" value={diseasetypeeighteen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}
                                            <AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembereighteen')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMembereighteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembereighteen" value={secondfamilymembereighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypeeighteen" value={seconddiseasetypeeighteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembereighteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembereighteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembereighteen" value={thirdFamilyMembereighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypeeighteen" value={thirdDiseaseTypeeighteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembereighteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembereighteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembereighteen" value={fourthFamilyMembereighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypeeighteen" value={fourthDiseaseTypeeighteen} onChange={handleFamilySecondMember}/>
                                                 */}
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori19" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.19")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembernighteen" value={familymembernighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypenighteen" value={diseasetypenighteen} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembernighteen')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMembernighteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembernighteen" value={secondfamilymembernighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypenighteen" value={seconddiseasetypenighteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembernighteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembernighteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembernighteen" value={thirdFamilyMembernighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypenighteen" value={thirdDiseaseTypenighteen} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembernighteen')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembernighteen ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembernighteen" value={fourthFamilyMembernighteen} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypenighteen" value={fourthDiseaseTypenighteen} onChange={handleFamilySecondMember}/>
                                                */}   
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori20" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.20")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembertwenty" value={familymembertwenty} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypetwenty" value={diseasetypetwenty} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembertwenty')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMembertwenty ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembertwenty" value={secondfamilymembertwenty} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypetwenty" value={seconddiseasetypetwenty} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembertwenty')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembertwenty ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembertwenty" value={thirdFamilyMembertwenty} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypetwenty" value={thirdDiseaseTypetwenty} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addfourthMembertwenty')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addfourthMembertwenty ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembertwenty" value={fourthFamilyMembertwenty} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypetwenty" value={fourthDiseaseTypetwenty} onChange={handleFamilySecondMember}/>
                                                */}    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>
                        <div id="contFamilyHistori21" style={{display:'none'}}>
                            <Grid container direction="row" xs={12}>
                                <Grid xs={12} sm={2}>
                                    <p className={styles.chips}>{t("register.option.family.medical.21")}</p>
                                </Grid>
                                <Grid xs={12} sm={10}>
                                    
                                    <Grid container xs={12} sm={12} direction="row" justify="flex-start" alignItems="center">
                                        <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                        <Select onChange={handleFamilyMedicalMember} name="familymembertwentyone" value={familymembertwentyone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                            
                                            <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="familymember" value={familymember} onChange={handleFamilyMedicalMember}/>*/}
                                        <div className={styles.contOthers}>
                                            {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")} </p>
                                            <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="diseasetypetwentyone" value={diseasetypetwentyone} onChange={handleFamilyMedicalMember} style={{margin:"0px"}}/>
                                            */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addOtherMembertwentyone')}/>     
                                        </div>
                                                              
                                    </Grid>
                                    {addOtherMembertwentyone ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="secondfamilymembertwentyone" value={secondfamilymembertwentyone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.subtitle.which.member")} name="secondfamilymember" value={secondfamilymember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="seconddiseasetypetwentyone" value={seconddiseasetypetwentyone} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addThirdMembertwentyone')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addThirdMembertwentyone ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="thirdFamilyMembertwentyone" value={thirdFamilyMembertwentyone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="thirdFamilyMember" value={thirdFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.subtitle.disease.type")} name="thirdDiseaseTypetwentyone" value={thirdDiseaseTypetwentyone} onChange={handleFamilySecondMember}/>
                                                */}<AddIcon className={styles.botonAdd} onClick={() => handleControl('addFourthMembertwentyone')}/>    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}
                                    {addFourthMembertwentyone ? (
                                        <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-native-simple" style={{left:"15px"}}>{t("register.history.option0")}</InputLabel>
                                            <Select onChange={handleFamilySecondMember} displayEmpty name="fourthFamilyMembertwentyone" value={fourthFamilyMembertwentyone} inputProps={{ 'aria-label': 'Without label' }} style={{"margin-left":"10px", width:"150px"}}>
                                                
                                                <MenuItem value={t("register.history.option1")}>{t("register.history.option1")}</MenuItem>
                                                <MenuItem value={t("register.history.option2")}>{t("register.history.option2")}</MenuItem>
                                                <MenuItem value={t("register.history.option3")}>{t("register.history.option3")}</MenuItem>
                                                <MenuItem value={t("register.history.option4")}>{t("register.history.option4")}</MenuItem>
                                                <MenuItem value={t("register.history.option5")}>{t("register.history.option5")}</MenuItem>
                                                <MenuItem value={t("register.history.option6")}>{t("register.history.option6")}</MenuItem>
                                            </Select>
                                            </FormControl>
                                            {/*<input type="text" className={styles.inputText} placeholder={t("register.cont.which.member")} name="fourthFamilyMember" value={fourthFamilyMember} onChange={handleFamilySecondMember}/>*/}
                                            <div className={styles.contOthers}>
                                                {/*<p className={styles.subTitle}>{t("register.subtitle.disease.type")}</p>
                                                <input type="text" className={styles.inputText} placeholder={t("register.cont.disease.type")} name="fourthDiseaseTypetwentyone" value={fourthDiseaseTypetwentyone} onChange={handleFamilySecondMember}/>*/}
                                                    
                                            </div>
                                            
                                        </Grid>
                                    ) : null}

                                </Grid>
                            </Grid>
                        </div>                    
                    </Grid>      
                </Grid> 
                {/* family-medical history */}

                {/* Allergies */}
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.allergies")}</h3>
                    <Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                        <Grid item xs={12} sm={2}>
                            <RadioGroup
                              name="doesHaveAllergy"
                              onChange={handleHaveAllergy}
                              value={doesHaveAllergy}
                              row
                            >
                                <FormControlLabel className={styles.subTitle}
                                    value = "false" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.1")}</Typography>}

                                    labelPlacement="start" 
                                />
                                <FormControlLabel className={styles.subTitle}
                                    // onChange={() => {
                                    //     setShowAllergies(true);
                                    //     handleHaveAllergy
                                    // }}
                                    value = "true" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.2")}</Typography>}

                                    labelPlacement="start" 
                                />
                            </RadioGroup>
                        </Grid>
                        {(doesHaveAllergy === "true") ? (
                            <Grid item xs={12} sm={10}>
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={3}>
                                        <Accordion className={styles.acord} expanded={drug}>
                                            <AccordionSummary className={styles.expand} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                                                <FormControlLabel
                                                    control={<Checkbox checked={drug} onChange={handleUserAllergies} name="drug" color="primary"/>}
                                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.type.name.1")}</Typography>}
                                                    className={classes.contFormLabel100}
                                                    aria-label="Acknowledge"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.details}>
                                                <input
                                                  type="text"
                                                  className={styles.inputText3}
                                                  style={{width:'100%', margin:0}}
                                                  placeholder={t("register.cont.allergies.type.name")}/*"Type in your allergy name"*/
                                                  name="allergyNameone"
                                                  value={allergyNameone}
                                                  onChange={handleAllergyName}
                                                  onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Accordion className={styles.acord} expanded={food}>
                                            <AccordionSummary className={styles.expand} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                                                <FormControlLabel
                                                    control={<Checkbox checked={food} onChange={handleUserAllergies} name="food" color="primary"/>}
                                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.type.name.2")}</Typography>}
                                                    className={classes.contFormLabel100}
                                                    aria-label="Acknowledge"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.details}>
                                                <input
                                                  type="text"
                                                  className={styles.inputText3}
                                                  style={{width:'100%', margin:0}}
                                                  placeholder={t("register.cont.allergies.type.name")}/*"Type in your allergy name"*/
                                                  name="allergyNametwo"
                                                  value={allergyNametwo}
                                                  onChange={handleAllergyName}
                                                  onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Accordion className={styles.acord} expanded={cosmetic}>
                                            <AccordionSummary className={styles.expand} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                                                <FormControlLabel
                                                    control={<Checkbox checked={cosmetic} onChange={handleUserAllergies} name="cosmetic" color="primary"/>}
                                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.type.name.3")}</Typography>}
                                                    className={classes.contFormLabel100}
                                                    aria-label="Acknowledge"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.details}>
                                                <input
                                                  type="text"
                                                  className={styles.inputText3}
                                                  style={{width:'100%', margin:0}}
                                                  placeholder={t("register.cont.allergies.type.name")}/*"Type in your allergy name"*/
                                                  name="allergyNamethree"
                                                  value={allergyNamethree}
                                                  onChange={handleAllergyName}
                                                  onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                                />
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Accordion className={styles.acord} expanded={plant}>
                                            <AccordionSummary className={styles.expand} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                                                <FormControlLabel
                                                    control={<Checkbox checked={plant} onChange={handleUserAllergies} name="plant" color="primary"/>}
                                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.type.name.4")}</Typography>}
                                                    className={classes.contFormLabel100}
                                                    aria-label="Acknowledge"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.details}>
                                                <input
                                                  type="text"
                                                  className={styles.inputText3}
                                                  style={{width:'100%', margin:0}}
                                                  placeholder={t("register.cont.allergies.type.name")}/*"Type in your allergy name"*/
                                                  name="allergyNamefour"
                                                  value={allergyNamefour}
                                                  onChange={handleAllergyName}
                                                  onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                                />
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Accordion className={styles.acord} expanded={pet}>
                                            <AccordionSummary className={styles.expand} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                                                <FormControlLabel
                                                    control={<Checkbox checked={pet} onChange={handleUserAllergies} name="pet" color="primary"/>}
                                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.type.name.5")}</Typography>}
                                                    className={classes.contFormLabel100}
                                                    aria-label="Acknowledge"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.details}>
                                                <input
                                                  type="text"
                                                  className={styles.inputText3}
                                                  style={{width:'100%', margin:0}}
                                                  placeholder={t("register.cont.allergies.type.name")}/*"Type in your allergy name"*/
                                                  name="allergyNamefive"
                                                  value={allergyNamefive}
                                                  onChange={handleAllergyName}
                                                  onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                                />
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Accordion className={styles.acord} expanded={other}>
                                            <AccordionSummary className={styles.expand} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                                                <FormControlLabel
                                                    control={<Checkbox checked={other} onChange={handleUserAllergies} name="other" color="primary"/>}
                                                    label={<Typography className={classes.subTitle}>{t("register.option.allergies.type.name.6")}</Typography>}
                                                    className={classes.contFormLabel100}
                                                    aria-label="Acknowledge"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.details}>
                                                <input
                                                  type="text"
                                                  className={styles.inputText3}
                                                  style={{width:'100%', margin:0}}
                                                  placeholder={t("register.cont.allergies.type.name")}/*"Type in your allergy name"*/
                                                  name="allergyNamesix"
                                                  value={allergyNamesix}
                                                  onChange={handleAllergyName}
                                                  onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                                />
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            
                                <Grid container direction="column">
                                    <label className={styles.subTitle}>{t("register.subtitle.allergies.reactions")}</label>
                                    <Grid container xs={12} direction="row" alignItems="baseline" wrap="wrap">
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={chills} onChange={handleUserAllergies} name="chills" size="small" color="primary"/>{t("register.option.allergies.reactions.1")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={cough} onChange={handleUserAllergies} name="cough" size="small" color="primary"/>{t("register.option.allergies.reactions.2")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={diarrhea} onChange={handleUserAllergies} name="diarrhea" size="small" color="primary"/>{t("register.option.allergies.reactions.3")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={dizziness} onChange={handleUserAllergies} name="dizziness" size="small" color="primary"/>{t("register.option.allergies.reactions.4")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={eruption} onChange={handleUserAllergies} name="eruption" size="small" color="primary"/>{t("register.option.allergies.reactions.5")}
                                            </label>    
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={fever} onChange={handleUserAllergies} name="fever" size="small" color="primary"/>{t("register.option.allergies.reactions.6")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={headacheAl} onChange={handleUserAllergies} name="headacheAl" size="small" color="primary"/>{t("register.option.allergies.reactions.7")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={nausea} onChange={handleUserAllergies} name="nausea" size="small" color="primary"/>{t("register.option.allergies.reactions.8")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={pruritus} onChange={handleUserAllergies} name="pruritus" size="small" color="primary"/>{t("register.option.allergies.reactions.9")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={throat} onChange={handleUserAllergies} name="throat"  size="small" color="primary"/>{t("register.option.allergies.reactions.10")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={vomiting} onChange={handleUserAllergies} name="vomiting" size="small" color="primary"/>{t("register.option.allergies.reactions.11")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={swelling} onChange={handleUserAllergies} name="swelling" size="small" color="primary"/>{t("register.option.allergies.reactions.12")}
                                            </label>
                                        </div>
                                        <div className={classes.contFormLabel100}>
                                            <label className={styles.subTitle}>
                                                <Checkbox checked={tachycardia} onChange={handleUserAllergies} name="tachycardia" size="small" color="primary"/>{t("register.option.allergies.reactions.13")}
                                            </label>
                                        </div>
                                        <div>
                                            <Checkbox size="small" color="primary"/>
                                            <input
                                              type="text"
                                              name="otherAllergy"
                                              className={styles.inputText}
                                              placeholder={t("register.option.allergies.reactions.14")}/*"Other"*/
                                              onChange={handleAllergyName}
                                              onBlur={event => updateField(event, 'hasAllergies', hasAllergies)}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                        ) : null}
                    </Grid>

                </Grid> 
                {/* Allergies */}


                {/* Medications */}        
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.medications")}</h3>
                    
                    <Grid container direction="column" justify="space-between">
                        <label className={styles.subTitle}>{t("register.cont.medications.1")}</label>
                        <Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                            <label className={styles.subTitleQuestion}>{t("register.cont.medications.2")}</label>
                            <RadioGroup name="medication" onChange={handleAnyMedication} value={anymedication} row>
                                <FormControlLabel className={styles.subTitle}
                                    value = "false" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.medications.1")}</Typography>}

                                    labelPlacement="start" 
                                />
                                <FormControlLabel className={styles.subTitle}
                                    value = "true" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.medications.2")}</Typography>}

                                    labelPlacement="start" 
                                />
                            </RadioGroup>
                            {(anymedication === "true") ? (
                                <Grid>
                                    <input
                                      className={styles.inputText}
                                      placeholder={t("register.option.medications.name.drug")}/*"  Name of drug"*/
                                      name="nameOfDrug"
                                      value={nameOfDrug}
                                      onChange={handleMedications}
                                      onBlur={event => updateField(event, 'medications', medications)}
                                    />
                                    <input
                                      className={styles.inputText}
                                      placeholder={t("register.option.medications.dose")}/*"  Dose"*/
                                      name="dose"
                                      value={dose}
                                      onChange={handleMedications}
                                      onBlur={event => updateField(event, 'medications', medications)}
                                    />
                                    <input
                                      className={styles.inputText4}
                                      placeholder={t("register.option.medications.frecuence")}/*"  Frequency Taken (example: once a day)"*/
                                      name="frequencytaken"
                                      value={frequencytaken}
                                      onChange={handleMedications}
                                      onBlur={event => updateField(event, 'medications', medications)}
                                    />
                                    <AddIcon 
                                        className={styles.botonAdd}
                                        onClick={() => {
                                          setAddOtherMedication(true);
                                          updateForm({ addothermedication: true });
                                        }}
                                    />
                                    
                                    {addothermedication ? (
                                            <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.name.drug")}/*"  Name of drug"*/
                                                  name="nameOfSecondDrug"
                                                  value={nameOfSecondDrug}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.dose")}/*"  Dose"*/
                                                  name="dosesecond"
                                                  value={dosesecond}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText4}
                                                  placeholder={t("register.option.medications.frecuence")}/*"  Frequency Taken (example: once a day)"*/
                                                  name="frequencytakensecond"
                                                  value={frequencytakensecond}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <AddIcon 
                                                    className={styles.botonAdd}
                                                    onClick={() => {
                                                      setAddThirdMedication(true);
                                                      updateForm({ addThirdMedication: true });
                                                    }}
                                                />
                                            </Grid>
                                        ) : null
                                    }
                                    {addThirdMedication ? (
                                            <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.name.drug")}/*"  Name of drug"*/
                                                  name="nameOfThirdDrug"
                                                  value={nameOfThirdDrug}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.dose")}/*"  Dose"*/
                                                  name="doseThird"
                                                  value={doseThird}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText4}
                                                  placeholder={t("register.option.medications.frecuence")}/*"  Frequency Taken (example: once a day)"*/
                                                  name="frequencyTakenThird"
                                                  value={frequencyTakenThird}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <AddIcon 
                                                    className={styles.botonAdd}
                                                    onClick={() => {
                                                      setAddFourthMedication(true);
                                                      updateForm({ addFourthMedication: true });
                                                    }}
                                                />
                                            </Grid>
                                        ) : null
                                    }
                                    {addFourthMedication ? (
                                            <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.name.drug")}/*"  Name of drug"*/
                                                  name="nameOfFourthDrug"
                                                  value={nameOfFourthDrug}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.dose")}/*"  Dose"*/
                                                  name="doseFourth"
                                                  value={doseFourth}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText4}
                                                  placeholder={t("register.option.medications.frecuence")}/*"  Frequency Taken (example: once a day)"*/
                                                  name="frequencyTakenFourth"
                                                  value={frequencyTakenFourth}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <AddIcon 
                                                    className={styles.botonAdd}
                                                    onClick={() => {
                                                      setAddFifthMedication(true);
                                                      updateForm({ addFifthMedication: true });
                                                    }}
                                                />
                                            </Grid>
                                        ) : null
                                    }
                                    {addFifthMedication ? (
                                            <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.name.drug")}/*"  Name of drug"*/
                                                  name="nameOfFifthDrug"
                                                  value={nameOfFifthDrug}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText}
                                                  placeholder={t("register.option.medications.dose")}/*"  Dose"*/
                                                  name="doseFifth"
                                                  value={doseFifth}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                                <input
                                                  className={styles.inputText4}
                                                  placeholder={t("register.option.medications.frecuence")}/*"  Frequency Taken (example: once a day)"*/
                                                  name="frequencyTakenFifth"
                                                  value={frequencyTakenFifth}
                                                  onChange={handleMedications}
                                                  onBlur={event => updateField(event, 'medications', medications)}
                                                />
                                            </Grid>
                                        ) : null
                                    }
                                    {/* fin */}
                                </Grid>
                            ) : null}
                        </Grid>

                        
                    </Grid>
                </Grid> 
                {/* Medications */}

                {/* Social History */}
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.social.history")}</h3>
                    <label className={styles.subTitle}>{t("register.cont.social.history.1")}</label>
                    <Grid Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                        
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} sm={2}>
                                <label className={styles.subTitle}>{t("register.cont.social.history.2")} <span className={styles.bold}>{t("register.cont.social.history.3")}</span></label>                             
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <RadioGroup
                                  item
                                  name="useTobacco"
                                  value={useTobacco}
                                  onChange={handleUseTobaco}
                                  row
                                >
                                    <FormControlLabel className={styles.subTitle}
                                        value = "false" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.tabaco.1")}</Typography>}
                                        labelPlacement="start" 
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "true" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.tabaco.2")}</Typography>}
                                        labelPlacement="start" 
                                    />
                                </RadioGroup>
                                {(useTobacco === "true") ? (
                                    <Grid spacing={4}>
                                        <input
                                          className={styles.inputText}
                                          placeholder={t("register.cont.social.history.tabaco.age.started")}/*"  Age started"*/
                                          name="ageStarted"
                                          value={ageStarted}
                                          onChange={handleSocialHistory}
                                        />
                                        <input
                                          className={styles.inputText}
                                          placeholder={t("register.cont.social.history.tabaco.age.stopped")}/*"  Age stopped"*/
                                          name="ageStopped"
                                          value={ageStopped}
                                          onChange={handleSocialHistory}
                                        />
                                        <input
                                          className={styles.inputText}
                                          placeholder={t("register.cont.social.history.tabaco.year.quit")}/*"  Year quit"*/
                                          name="yearQuit"
                                          value={yearQuit}
                                          onChange={handleSocialHistory}
                                        />
                                        <Grid container direction="row" alignItems="baseline">
                                            <Grid item xs={12} sm={12}>
                                                <label className={styles.subTitle}>{t("register.cont.social.history.tabaco.type")}</label>
                                            </Grid>
                                            <Grid Grid container direction="row" justify="flex-start">
                                                <Grid item xs={12} sm={3}>
                                                    <label className={styles.subTitle}>
                                                      <Checkbox onChange={handleCheckedSocial} name="cigarrettes" checked={cigarrettes} color="primary" size="small"/>
                                                      {t("register.cont.social.history.tabaco.cigarettes")}
                                                    </label>
                                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.tabaco.per.day")}/*"  # per day"*/ name="cigarrettesPerDay" value={cigarrettesPerDay} onChange={handleSocialHistory}/>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <label className={styles.subTitle}>Or</label>
                                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.tabaco.packs")}/*"  # packs per day"*/ name="packsPerDay" value={packsPerDay} onChange={handleSocialHistory}/>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <label className={styles.subTitle}>
                                                      <Checkbox onChange={handleCheckedSocial} name="cigars" checked={cigars} color="primary" size="small"/>
                                                      {t("register.cont.social.history.tabaco.cigars")}
                                                    </label>
                                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.tabaco.per.day")}/*"  # per day"*/ name="cigarsPerDay" value={cigarsPerDay} onChange={handleSocialHistory}/>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <label className={styles.subTitle}>
                                                      <Checkbox onChange={handleCheckedSocial} name="pipe" checked={pipe} color="primary" size="small"/>
                                                      {t("register.cont.social.history.tabaco.pipe")}
                                                    </label>
                                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.tabaco.per.day")}/*"  # per day"*/ name="pipePerDay" value={pipePerDay} onChange={handleSocialHistory}/>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <label className={styles.subTitle}>
                                                      <Checkbox onChange={handleCheckedSocial} name="smokelessTobaco" checked={smokelessTobaco} color="primary" size="small"/>
                                                      {t("register.cont.social.history.tabaco.smokeless")}
                                                    </label>
                                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.tabaco.per.day")}/*"  # per day"*/ name="smokelessTobaccoPerDay" value={smokelessTobaccoPerDay} onChange={handleSocialHistory}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>                            
                        </Grid>

                        <Grid container direction="row" justify="space-between">
                            <Grid item xs={12} sm={2}>
                                <label className={styles.subTitle}>{t("register.cont.social.history.alcohol.1")} <span className={styles.bold}>{t("register.cont.social.history.alcohol.2")}</span></label>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <RadioGroup name="drinkAlcohol" onChange={handleSocialHistory} value={drinkAlcohol} row>
                                    <FormControlLabel className={styles.subTitle}
                                        value = "never" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.alcohol.1")}</Typography>}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "socially" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.alcohol.2")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "ocasionally" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.alcohol.3")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "daily" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.alcohol.4")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                    <FormControlLabel className={styles.subTitle}
                                        value = "former" control={<Radio color="primary"/>}
                                        label={<Typography className={classes.subTitle}>{t("register.option.social.history.alcohol.5")}</Typography>}
                                        labelPlacement="end" 
                                    />
                                </RadioGroup>
                                {(drinkAlcohol === "former") ? (
                                    <input type="text" className={styles.inputText} placeholder="  Year quit" name="yearQuitAlcohol" value={yearQuitAlcohol} onChange={handleSocialHistory}/>
                                ) : null}
                                {(drinkAlcohol === "socially" || drinkAlcohol === "ocasionally" || drinkAlcohol === "daily") ? (
                                <Grid spacing={4}>
                                    <Grid container direction="row" alignItems="baseline" justify="space-between">
                                        <label className={styles.subTitle}>{t("register.cont.social.history.alcohol.number.drinks")}</label>
                                        <label className={styles.subTitle}>
                                          <Checkbox name="checkedDrinksPerDay" checked={checkedDrinksPerDay} onChange={handleSocialHistory} color="primary" size="small"/>
                                          {t("register.cont.social.history.alcohol.day")}
                                        </label>
                                        <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.alcohol.day")}/*"  # per day"*/ name="numberOfDrinksPerDay" value={numberOfDrinksPerDay} onChange={handleSocialHistory}/>
                                        <label className={styles.subTitle}>
                                          <Checkbox name="checkedDrinksPerWeek" checked={checkedDrinksPerWeek} onChange={handleSocialHistory} color="primary" size="small"/>
                                          {t("register.cont.social.history.alcohol.week")}
                                        </label>
                                        <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.alcohol.week")}/*"  # per week"*/ name="numberOfDrinksPerWeek" value={numberOfDrinksPerWeek} onChange={handleSocialHistory}/>
                                        <label className={styles.subTitle}>
                                          <Checkbox name="checkedDrinksPerMonth" checked={checkedDrinksPerMonth} onChange={handleSocialHistory} color="primary" size="small"/>
                                          {t("register.cont.social.history.alcohol.month")}
                                        </label>
                                        <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.alcohol.month")}/*"  # per month"*/ name="numberOfDrinksPerMonth" value={numberOfDrinksPerMonth} onChange={handleSocialHistory}/>
                                    </Grid>
                                    <Grid container direction="row" alignItems="baseline" justify="flex-start" onChange={handleSocialHistory}>
                                        <label className={styles.subTitle}>{t("register.cont.social.history.alcohol.type")}</label>
                                        <label className={styles.subTitle}>
                                          <Checkbox name="drinkTypeBeer" checked={drinkTypeBeer} onChange={handleSocialHistory} color="primary" size="small"/>
                                          {t("register.cont.social.history.alcohol.type.beer")}
                                        </label>
                                        <label className={styles.subTitle}>
                                          <Checkbox name="drinkTypeWine" checked={drinkTypeWine} onChange={handleSocialHistory} color="primary" size="small"/>
                                          {t("register.cont.social.history.alcohol.type.wine")}
                                        </label>
                                        <label className={styles.subTitle}>
                                          <Checkbox name="drinkTypeStrong" checked={drinkTypeStrong} onChange={handleSocialHistory} color="primary" size="small"/>
                                          {t("register.cont.social.history.alcohol.type.liquor")}
                                        </label>
                                    </Grid>
                                </Grid>
                            ) : null}
                            </Grid>
                        </Grid>
                        
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <label className={styles.subTitle}>{t("register.cont.social.history.illegal.drugs.1")} <span className={styles.bold}>{t("register.cont.social.history.illegal.drugs.2")}</span></label>
                            <RadioGroup name="illegalDrug" value={illegalDrug} onClick={handleAnyMedicationDrug} row spacing={2}>
                                <FormControlLabel className={styles.subTitle}
                                    value = "false" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.1")}</Typography>}

                                    labelPlacement="start" 
                                />
                                <FormControlLabel className={styles.subTitle}
                                    value = "true" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.2")}</Typography>}

                                    labelPlacement="start" 
                                />
                            </RadioGroup>
                            {(illegalDrug === "true") ? (
                                <Grid item3>
                                    <label className={styles.subTitle}>{t("register.cont.social.history.illegal.drugs.type")}</label>
                                    <input type="text" className={styles.inputText} placeholder=" Cannabis" value={recreationalDrugType} name="recreationalDrugType" onChange={handleSocialHistory}/>
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item xs={12} sm={2}>
                                <label className={styles.subTitle}>{t("register.cont.social.history.illegal.drugs.activity.1")} <span className={styles.bold}>{t("register.cont.social.history.illegal.drugs.activity.2")}</span></label>                           
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <RadioGroup name="activityLevel" value={activityLevel} onChange={handleSocialHistory} row>
                                    <Grid item xs={12} sm={2}>
                                        <FormControlLabel className={styles.subTitle}
                                            value = "sedentary" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.activity.1")}</Typography>}
                                            labelPlacement="end" 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel className={styles.subTitle}
                                            value = "mild" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.activity.2")}</Typography>}
                                            labelPlacement="end" 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel className={styles.subTitle}
                                            value = "regular" control={<Radio color="primary"/>}
                                            label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.activity.3")}</Typography>}
                                            labelPlacement="end" 
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <label className={styles.subTitle}>{t("register.cont.social.history.illegal.drugs.traveled.1")} <span className={styles.bold}>{t("register.cont.social.history.illegal.drugs.traveled.2")}</span></label>
                            <RadioGroup name="travelOverseas" value={travelOverseas} onChange={handleTravel} row>
                                <FormControlLabel className={styles.subTitle}
                                    value = "false" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.traveled.1")}</Typography>}
                                    labelPlacement="start" 
                                />
                                <FormControlLabel className={styles.subTitle}
                                    value = "true" control={<Radio color="primary"/>}
                                    label={<Typography className={classes.subTitle}>{t("register.option.social.history.illegal.drugs.traveled.2")}</Typography>}
                                    labelPlacement="start" 
                                />
                            </RadioGroup>
                            {(travelOverseas === "true") ? (
                                <Grid item>
                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.illegal.drugs.country")}/*"  Country"*/ name="countryTraveled" value={countryTraveled} onChange={handleSocialHistory}/>
                                    <input type="text" className={styles.inputText} placeholder={t("register.cont.social.history.illegal.drugs.city")}/*"  City"*/ name="cityTraveled" value={cityTraveled} onChange={handleSocialHistory}/> 
                                    <input type="text" className={styles.inputText3} placeholder={t("register.cont.social.history.illegal.drugs.period")}/*"  Period of stay (i.e., 2 weeks)"*/ name="periodTraveled" value={periodTraveled} onChange={handleSocialHistory}/>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                {/* Social History */}
                
                {/* Preferred pharmacy */}
                <Grid container direction="column">
                    <h3 className={classes.Title}>{t("register.subtitle.pharmacy")}</h3>
                    <Grid Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                        <label className={styles.subTitle}>{t("register.cont.pharmacy")}</label>
                        <Grid item xs={12} sm={12}>    
                            <GooglePlacesAutocomplete 
                                apiKey="AIzaSyCLKTehqTZeLktqkQBX56zo_j0PqbgbFfI"
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['us', 'mx']
                                    }
                                }}
                                onLoadFailed={(error) => (
                                    console.error("Could not inject Google script", error)
                                )}
                                selectProps ={{
                                    value: pharmacy,
                                    onChange: (search) => {
                                      setPharmacyName(search);
                                      updateForm({ pharmacy: search });
                                    }
                                }}
                            />
                        </Grid>
                        <Alert variant="warning" className={styles.alert}>{t("home.title.alert")}</Alert>
                        
                    </Grid>
                </Grid> 
                {/* Preferred pharmacy */}


                <Grid container direction="column" alignItems="baseline">
                    <h3 className={classes.Title}>{t("register.subtitle.reason")}</h3>
                    <Grid Grid container direction="row" justify="flex-start" xs={12} className={classes.border}>
                        <TextField type="text" className={classes.inputTextBig} 
                            required
                            multiline
                            rowsMax={4}
                            placeholder={t("register.cont.reason")}/*"  (i.e., I have a headache) *"*/ name="reasonInfo"
                            onChange={handleReason}
                            value={reasonInfo}
                            onBlur={({ target }) => updateForm({ reasonInfo: target.value })}
                            InputProps={{
                                className: classes.inputbasic,
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                </Grid> {/* Reason */}
                
                <Grid container direction="column" >
                    <FormGroup classes={styles.subTitle} >
                        <FormControlLabel control={<Checkbox required checked={veracity.veracity_checked} name="veracity_checked" onChange={handleVeracity} color="primary" />} label={<Typography className={classes.subTitle}>{t('register.veracity.truth')}</Typography>}/>
                        <FormControlLabel control={<Checkbox required checked={veracity.veracity_third} name="veracity_third" onChange={handleVeracity} color="primary"/>} label={<Typography className={classes.subTitle}>{t('register.veracity.third')}</Typography>}/>
                    </FormGroup>
                    

                    
                    {showAlert ? (<div class="alert alert-danger" role="alert">{t('service.cont.alert')}</div>) : null}
 
                </Grid>

                <Grid container alignItems="center" justify="center" className={classes.contBtn}>
                    {buttonShow ? (
                        <Button
                            // onClick={onSubmitForm}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                        >{t("register.button.continue")}
                        </Button>
                    ) : (
                        <CircularProgress />
                        )}
                </Grid>
            </form>
        </>
    );
}
 
export default Register;

