// src/components/authentication-button.js

import React from "react";



import LoginButton from "./login-button";
import LogoutButton from "./logout-button-1";

import { useAuth0 } from "@auth0/auth0-react";

import  { Redirect, Route, NavLink } from 'react-router-dom';

import ProtectedRoute from "../auth/protected-route";




const AuthenticationButton = () => {
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
  
  


  return isAuthenticated ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;