// src/components/login-button.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import styles from "../styles/Navbar.module.css";

const LoginButton = () => {
  const [t, i18n] =useTranslation("home");
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      className={styles.login}
      onClick={() => loginWithRedirect()}
    >
      {t('login.button.login')}
    </button>
  );
};

export default LoginButton;