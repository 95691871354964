export const getForm = () =>
  JSON.parse(localStorage.getItem('form'));

export const deleteForm = () => localStorage.removeItem('form');

export const createForm = user => localStorage.setItem('form', JSON.stringify({ user }));

export const updateForm = (value) => {
  const form = getForm();
  localStorage.setItem('form', JSON.stringify({ ...form, ...value }));
};