import React, { useEffect, useState } from 'react';
import { fetchMedInfoDoctor } from '../utils/apiCalls'

import styles from '../styles/History.module.css';
import { Link, useLocation } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import EMR from './UI/EMR';
import {useTranslation} from "react-i18next";

const DoctorTable = ({userType, auth, userid}) => {
    const [t, i18n] =useTranslation("home");

    const [modalShow, setModalShow] = useState(false);
    const [patient, setPatient] = useState()

    const { isAuthenticated, logout, login } = auth;
    const [waitingData, setWaitingData] = useState([]);
    const [waitingUsers, setWaitingUsers] = useState({});
    const [UserWaitingInfo, setUserWaitingInfo] = useState({});
    const [loading, setLoading] = useState(false);
    let storedUser = JSON.parse(localStorage.getItem('user_info'));
    const [userMedInfo, setUserMedInfo ] = useState({
        activity_level: '',
        alcohol: '',
        allergies: '',
        family_medical_history:'',
        height_data: '',
        medication: '',
        pre_existing_conditions: '',
        reason: {
            reason_info: {
                reasonInfo: ''
            }
        },
        tobacco: '',
        travel: '',
        used_illegal_drugs: '',
        weight_data: ''
    });

    const handleVisibility = (user_id) => {
        // let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
        // fetchMedInfoDoctor(storedSession.idToken, user_id).then((userMedInfo) => {
        //     setUserMedInfo(userMedInfo.patient_medical_data)
        //     console.log("user med info")
        //     console.log(userMedInfo.patient_medical_data)
        // })
        setPatient(user_id)
        console.log(patient)
        setModalShow(true)

    }
    useEffect(() => {
        if(modalShow){
            console.log(patient)
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            fetchMedInfoDoctor(storedSession.idToken, patient).then((userMedInfo) => {
                setUserMedInfo(userMedInfo.patient_medical_data)
                // console.log("user handle patient")
                // console.log(userMedInfo.patient_medical_data)
            })
           }
           else{
            console.log("modal show false")
           }
      }, [modalShow]);

    //   const handlePatient = (patient) => {
    //     let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
    //     fetchMedInfoDoctor(storedSession.idToken, patient).then((userMedInfo) => {
    //         setUserMedInfo(userMedInfo.patient_medical_data)
    //         console.log("PATIENT INFO")
    //         console.log(userMedInfo.patient_medical_data)
    //     })

    // }
    


    useEffect(() => {
        /*if (!isAuthenticated) {
            router.push('/');
        }*/
        if(false){
            window.location.href="/";
            
        }else{
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            setLoading(true);
            try {
                async function fetchWaiting(){
                    await fetch(`https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/new-cases?page=${1}&size=${100}`, {
                        method: 'GET',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        setLoading(false);
                        setWaitingData(data.items);
                    })
                    .catch( error_reason => {
                        console.log(error_reason)
                        setLoading(false);
                    })
                }
                fetchWaiting();

            } catch (error) {
                console.log("ERROR", error);
            }

        }
    },[ ]);

    const viewService = (id, order_id, pin_code, doctor_id, user_id) => {


        let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
        fetch('https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/cases/'+`${id}`,{
            method:'PATCH',
            headers:{
                Authorization: `Bearer ${storedSession.idToken}`,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            },
            body:JSON.stringify({"status": "IN_PROGRESS"})
        })
        .then( response => response.json())
        .then(result =>{
            console.log("Go to medical case");
            var newURL = "/service?id="+`${id}`+"&order_id="+`${order_id}`+"&pin_code="+`${pin_code}`+"&doctor_id="+`${doctor_id}`+"&pid="+`${user_id}`+"";
            //console.log(newURL);
            window.location.href=newURL;
            /*router.push({
                pathname: '/service',
                query:{
                    id: `${id}`,
                    order_id: `${order_id}`,
                    pin_code: `${pin_code}`,
                    doctor_id: `${doctor_id}`,
                    pid: `${user_id}`
                },
                
            })*/
        })
        .catch(error =>{
            console.log("ERROR ?",error)
        })

        
    }

    //Aqui se le debe cambiar al tipo de contacto que se va a realizar y agregar numeros o campos
    const renderBody = (userid) => {
        if (loading) {
          return (
            <tr>
              <th>- -</th>
              <th>- -</th>
              <th>- -</th>
              <th>- -</th>
              <th>- -</th>
              <th>- -</th>
            </tr>
          )
        }

        if (!waitingData.length) {
          return (
            <tr className={styles.emptyState}>
              <th colSpan="6">{t('doctor.table.emptyState')}</th>
            </tr>
          )
        }

        return waitingData.map(({ id, first_name, last_name, status, pin_code, minutes, phone, order_id, user_id}) => {
            return (
                <tr key={id} className={styles.fila}>
                    <td>{first_name} {last_name}</td>
                    <td>{pin_code}</td>
                    <td>{t('doctor.table.cont.call')}</td>
                    <td>
                        {(() => {
                            if (phone == null){
                                return(
                                    "not available"
                                )
                            }
                            else{
                                if (phone.mobile_number != null){
                                    return(
                                        phone.mobile_number
                                    )
                                }
                                if(phone.phone_number != null){
                                    return(
                                        phone.phone_number
                                    )
                                }
                                if(phone.mobile_number != null && phone.phone_number != null){
                                    return(
                                        phone.mobile_number + "<br/>" + phone.phone_number
                                    )
                                }
                            }
                        })()}
                    </td>
                    <td>{minutes.slice(0,-7)}</td>
                    <td><VisibilityIcon fontSize="medium" onClick={() => handleVisibility(user_id)}/></td>
                    <td><button className={styles.boton6} onClick={() => viewService(id, order_id, pin_code, userid, user_id )}>{t('doctor.table.cont.start')}</button></td>
                </tr>
            )
        })
    }



    //Destructuring
    const { users } = UserWaitingInfo;    
    

    return ( 
        <div className={styles.tabla2}>
            <EMR show={modalShow} userMedInfo={userMedInfo} onHide={() => setModalShow(false)}/>
            <table className={styles.a}>
              <tr>
                <th>{t('doctor.table.cont.name')}</th>
                <th>{t('doctor.table.cont.consult')}</th>
                <th>{t('doctor.table.cont.type.contact')}</th>
                <th>{t('doctor.table.cont.contact')}</th>
                <th>{t('doctor.table.cont.waiting')}</th>
                <th>{t('doctor.table.cont.emr')}</th>
              </tr>
              {renderBody(userid)}
            </table>
        </div>
     );
}
 
export default DoctorTable;