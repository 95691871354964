import React,{ useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddendumInfo from '../components/addendumInfo';
import { Grid, Button, AppBar, Toolbar, Drawer, TextField, Checkbox} from '@material-ui/core';
import { fetchMedInfoDoctor, fetchUserInfoDoctor, saveBasicInfoDataDoctor } from '../utils/apiCalls';
import { useAuth0 } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import styles from '../styles/Service.module.css';

const querystring = window.location.search;
const params = new URLSearchParams(querystring);
const userType = params.get('userType');


function Addendum() {
    const [t, i18n] =useTranslation("home");
    const { isAuthenticated, logout, login } = useAuth0;
    const [userData, setUserData] = useState();
    const [userMedInfo, setUserMedInfo ] = useState();
    const [linkShow,updateLink] = useState({
        link:"/",
        type:""
    })

    const callLogout = () => {
        logout( `${window.location.origin}/`)
    }

    const[pathHistory, setPathHistory] = useState();
    useEffect(() => {
        console.log("USER TYPE ==>",userType)
        // let user = JSON.parse(localStorage.getItem("user_info"))
        // let userData = user.user;
        // let userType = userData.user_type
        if (userType == "admin"){
            setPathHistory("/history-a")
            updateLink({link:"/history-a",type:"admin"})
        }
        else {
            setPathHistory("/history-b")
            updateLink({link:"/history-b",type:"doctor"})
        }
    }, [])

    // useEffect(() => {
    //     if (!isAuthenticated) {
    //         router.push('/');
    //       }
    //     console.log("LET SEE =>",auth)
    //     let user = router.query
    //     if(user.userType=="admin"){
    //         updateLink({link:"/history-a",type:"admin"})
    //     }
    //     else{
    //         updateLink({link:"history-b",type:"doctor"})
    //     }
    // }, [])
    //const url = `https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user/med-case/${med_case_id}`;
    const [medCaseInfo, setMedCaseInfo] = useState({
        appointmentDate: '',
        callDuration: '',
        // doctorId: '',
        // id: '',
        // order_id: '',
        // pin_code: '',
        // update: ''
    })


    //Obtenemos los datos del query string
    const querystring = window.location.search
    console.log(querystring)

    // usando el querystring, creamos un objeto del tipo URLSearchParams
    const params = new URLSearchParams(querystring)


    const [chiefComplaints, setChiefComplaints] = useState({
        titleC: '',
        descriptionC: '',
        titleC2: '',
        descriptionC2: '',
        titleC3: '',
        descriptionC3: ''
    })
    
    const [addComplaint2, setAddComplaint2] = useState(false);
    const [addComplaint3, setAddComplaint3] = useState(false);

    const [historyIllness, setHistoryIllness] = useState({
        titleH: '',
        descriptionH: '',
        titleH2: '',
        descriptionH2: '',
        titleH3: '',
        descriptionH3: ''
    })

    const [addHistory2, setAddHistory2] = useState(false);
    const [addHistory3, setAddHistory3] = useState(false);

    const [vitalSigns, setVitalSigns] = useState('');

    const [subjectiveInfo, setSubjectiveInfo] = useState({
        titleS: '',
        descriptionS: '',
        titleS2: '',
        descriptionS2: '',
        titleS3: '',
        descriptionS3: ''
    })

    const [addSubjective2, setAddSubjective2] = useState(false);
    const [addSubjective3, setAddSubjective3] = useState(false);

    const [objectiveInfo, setObjectiveInfo] = useState({
        titleO: '',
        descriptionO: '',
        titleO2: '',
        descriptionO2: '',
        titleO3: '',
        descriptionO3: ''
    })

    const [addObjective2, setAddObjective2] = useState(false);
    const [addObjective3, setAddObjective3] = useState(false);

    const [assessmentInfo, setAssessmentInfo] = useState({
        titleA: '',
        descriptionA: '',
        titleA2: '',
        descriptionA2: '',
        titleA3: '',
        descriptionA3: ''
    })

    const [addAssessment2, setAddAssessment2] = useState(false);
    const [addAssessment3, setAddAssessment3] = useState(false);

    const [planInfo, setPlanInfo] = useState({
        titleP: '',
        descriptionP: '',
        titleP2: '',
        descriptionP2: '',
        titleP3: '',
        descriptionP3: ''
    })

    const [addPlan2, setAddPlan2] = useState(false);
    const [addPlan3, setAddPlan3] = useState(false);

    const [nonPrescriptionInfo, setNonPrescriptionInfo] = useState({
        providedNon: false,
        nameNon: '',
        strengthNon: '',
        sigNon: '',
        typeNon: '',
        name2Non: '',
        strength2Non: '',
        sig2Non: '',
        type2Non: '',
        name3Non: '',
        strength3Non: '',
        sig3Non: '',
        type3Non: '',
        name4Non: '',
        strength4Non: '',
        sig4Non: '',
        type4Non: '',
        name5Non: '',
        strength5Non: '',
        sig5Non: '',
        type5Non: ''
    })

    const [showNon, setShowNon] = useState(false);

    const [addNon2, setAddNon2] = useState(false);
    const [addNon3, setAddNon3] = useState(false);
    const [addNon4, setAddNon4] = useState(false);
    const [addNon5, setAddNon5] = useState(false);


    const [prescriptionInfo, setPrescriptionInfo] = useState({
        provided: false,
        pharmacy: '',
        instructions: '',
        name: '',
        strength: '',
        sig: '',
        type: '',
        quantity: '',
        unit: '',
        refills: '',
        diagnosis: '',
        date: '',
        dispense: '',
        name2: '',
        strength2: '',
        sig2: '',
        type2: '',
        quantity2: '',
        unit2: '',
        refills2: '',
        diagnosis2: '',
        date2: '',
        dispense2: '',
        name3: '',
        strength3: '',
        sig3: '',
        type3: '',
        quantity3: '',
        unit3: '',
        refills3: '',
        diagnosis3: '',
        date3: '',
        dispense3: '',
        name4: '',
        strength4: '',
        sig4: '',
        type4: '',
        quantity4: '',
        unit4: '',
        refills4: '',
        diagnosis4: '',
        date4: '',
        dispense4: '',
        name5: '',
        strength5: '',
        sig5: '',
        type5: '',
        quantity5: '',
        unit5: '',
        refills5: '',
        diagnosis5: '',
        date5: '',
        dispense5: ''
    })

    const [veracity, setVeracity] = useState({veracity_checked: false});


    const [showPres, setShowPres] = useState(false);

    const [addPres2, setAddPres2] = useState(false);
    const [addPres3, setAddPres3] = useState(false);
    const [addPres4, setAddPres4] = useState(false);
    const [addPres5, setAddPres5] = useState(false);

    const [showAlert, setShowAlert] = useState(false);



    const handleComplaints = (e) => {
        setChiefComplaints({
            ...chiefComplaints,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, chiefComplaints)
    }

    const handleHistory = (e) => {
        setHistoryIllness({
            ...historyIllness,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, historyIllness)
    }

    const handleVital = (e) => {
        setVitalSigns(e.target.value)
        console.log("HANDLING ==>",e.target.value, vitalSigns)
    }

    const handleSubjective = (e) => {
        setSubjectiveInfo({
            ...subjectiveInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, subjectiveInfo)
    }

    const handleObjective = (e) => {
        setObjectiveInfo({
            ...objectiveInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, objectiveInfo)
    }

    const handleAssessment = (e) => {
        setAssessmentInfo({
            ...assessmentInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, assessmentInfo)
    }

    const handlePlan = (e) => {
        setPlanInfo({
            ...planInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, planInfo)
    }

    const handleNonPres = (e) => {
        setNonPrescriptionInfo({
            ...nonPrescriptionInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, nonPrescriptionInfo)
    }
    const handlePres = (e) => {
        setPrescriptionInfo({
            ...prescriptionInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value, prescriptionInfo)
    }

    const handleMedInfo = (e) => {
        setMedCaseInfo({
            ...medCaseInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==>",e.target.value,medCaseInfo)
    }

    const handleVeracity = e => {
        setVeracity({[e.target.name]:e.target.checked})
        setShowAlert(false)
        console.log("HANDLING ==> veracity",e.target.value,veracity)
    }
    var date = new Date();
    var medical_addendum= medical_addendum;

    console.log(querystring);

    function getParameterByName(querystring) {
        querystring  = querystring.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + querystring + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    var Id = getParameterByName('pin_code');
    console.log(Id);

    const finishService = e => {
        if (veracity.veracity_checked==false){
            setShowAlert(true)
        }
        else {
            console.log("Veracity True")
            let responseAddendum = {
                medical_addendum: medical_addendum
                
            }
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            fetch(`https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/cases/${Id}`,{
                method:'PATCH',
                headers:{
                    Authorization: `Bearer ${storedSession.idToken}`,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                },
                body:JSON.stringify(responseAddendum)
            })
            .then( response => response.json())
            .then(result =>{
                console.log("Updated Service", result)
                window.location.href="/doctor";
            })
            .catch(error =>{
                console.log("ERROR ?",error)
            })
        }
    }

    function changeStatusText(elementA, elementB, btn){
        elementA.toggleAttribute('readOnly');
        elementB.toggleAttribute('readOnly');
        if (btn.innerHTML === "+") {
            btn.style.backgroundColor = "#CC1F55";
            btn.innerHTML = "Edit";
        } else {
            btn.style.backgroundColor = "#3C9946";
            btn.innerHTML = "+";
        }
    }

    function changeStatusNon(ParentNon, btn){
        var node = ParentNon.querySelectorAll("input");
        for (var i=0; i<node.length; i++){
            node[i].toggleAttribute('readOnly');
        }
        if (btn.innerHTML === "+") {
            btn.style.backgroundColor = "#CC1F55";
            btn.innerHTML = "Edit";
        } else {
            btn.style.backgroundColor = "#3C9946";
            btn.innerHTML = "+";
        }
    }

    const saveService = e => {
        e.preventDefault();
        var btnclic = document.getElementById(e.target.id);
        switch(e.target.id){
            case "Historybutton":
                changeStatusText(document.getElementById('Historyinput'), document.getElementById('Historytext'), btnclic)
                setAddHistory2(true);
            break;
            case "Historybutton2":
                changeStatusText(document.getElementById('Historyinput2'), document.getElementById('Historytext2'), btnclic)
                setAddHistory3(true);
            break;
            case "Historybutton3":
                changeStatusText(document.getElementById('Historyinput3'), document.getElementById('Historytext3'), btnclic)
                setAddHistory3(true);
            break;
        }
    }

    
    let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));

    useEffect(() => {
        // if(!isAuthenticated){
        //     router.push('/');
            
        // }
        if(false){
            window.location.href="/";
            
        }
        else{

            // Token
            // let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            try {
                console.log("querystring: ", params.get('pid'));

                fetchUserInfoDoctor(storedSession.idToken, params.get('pid')).then((userData) => {
                    setUserData(userData.patient_data)
                    console.log("user data")
                    console.log(userData.patient_data)
                })

                fetchMedInfoDoctor(storedSession.idToken, params.get('pid')).then((userMedInfo) => {
                    setUserMedInfo(userMedInfo.patient_medical_data)
                    console.log("user med info")
                    console.log(userMedInfo.patient_medical_data)
                })
                 
            } catch (error) {
                console.log("ERROR", error);
            }
        }
    },[ ]);
    let userinfo = JSON.parse(localStorage.getItem('user_info'));
    let btnOrigin = {
        Complaint2: 'Complaint2',
        Complaint3: 'Complaint3'
    }
    const last_name = userinfo.user.first_name + " " + userinfo.user.last_name;
    const quejaTitle = userinfo.user.first_name + " " + userinfo.user.last_name;
    const quejaCont = userinfo.user.first_name + " " + userinfo.user.last_name;
    
        

    return (
        <>
            <AppBar position="static" color="transparent" elevation={0} className={styles.blueAppBar}>
                <Toolbar className={styles.blueToolBar}>
                    <Link to={{pathname:"/doctor",query:{userType:linkShow.type}}} >
                        <a  className={`${styles.link} ${styles.callHistoryButton}`}>{t('header.2.cont.waiting')}</a>
                    </Link>
                    <Link to={{pathname:"/progress"}} >
                        <a  className={`${styles.link} ${styles.callHistoryButton}`}>{t('header.2.cont.progress')}</a>
                    </Link>
                    <Link to={{pathname:pathHistory}} >
                        <a  className={`${styles.link} ${styles.callHistoryButton}`}>{t('header.2.cont.history')}</a>
                    </Link>
                    <span className={`${styles.logoutMobile} ${styles.logout}`}>
                        <a title="Exit"  onClick={() => callLogout()}><ExitToAppIcon className={styles.logoutIcon} style={{ fontSize: 45}}/></a>
                    </span>
                </Toolbar>  
            </AppBar>

            <Drawer variant="permanent" anchor="left"
                classes={{
                    paper: styles.blueDrawer,
                }}
            >
                <img src="/LogoBlanco.png" alt="Meditel Logo" className={styles.meditelWhiteLogo}/>
                {/*<div className={styles.userImage}><img src="/User.png" alt= "User"/></div>*/}
            </Drawer>

            <div className={styles.mainContainer}> 

                {userData && userMedInfo && <AddendumInfo userinfo={userData} userMedInfo={userMedInfo} section={"service"} saveBasicInfoData={(userinfo, userMedInfo) => {
                        saveBasicInfoDataDoctor( userinfo, userMedInfo, storedSession.idToken, 83 )
                }} />}
                
                

            </div>
        </>
    )
}

export default Addendum;
