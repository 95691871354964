import React,  { useState, useEffect, Fragment } from "react";

import Header2 from '../components/Header2';

import DoctorTable from '../components/DoctorTable';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {useTranslation} from "react-i18next";
import { NavBar, Footer, Loading } from "../components";

import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@material-ui/core";
import style from '../styles/Doctor.module.css';
import styles from '../styles/Profile.module.css';

const Doctor = () => {
    const [t, i18n] =useTranslation("home");

    const { isAuthenticated, logout, login } = useAuth0;
    const [userData, setUserData] = useState({});
    const querystring = window.location.search;

    const params = new URLSearchParams(querystring);
    const userType = params.get('userType');
    
    useEffect(() => {
        /*if (!isAuthenticated) {
            router.push('/');
        }*/
        if(false){
            window.location.href="/";
            
        }else{
            // Endpoint
            let url = 'https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/user';
            // Token
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            try {
                async function fetchUser(){
                    await fetch(url, {
                        method: 'GET',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        setUserData(data.user);
                    })
                }
                fetchUser();
            } catch (error) {
                console.log("ERROR", error);
            }
        }
    },[ ]);

    const [doctorStatus, setDoctorStatus] = useState({
        is_available: true
    });
    useEffect(() => {
        /*if (!isAuthenticated) {
            router.push('/');
        }*/
        if(false){
            window.location.href="/";
            
        }else{
            // Endpoint
            let url2 = 'https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/doctor/status';
            // Token
            let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
            //Fetch API
            try{
                async function fetchDr(){
                    const requestOptions = {
                        method: 'PUT',
                        headers:{
                            Authorization: `Bearer ${storedSession.idToken}`,
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                        },
                        body: JSON.stringify(doctorStatus)
                    };
                    const response = await fetch(url2, requestOptions);
                    const data = await response.json()
                    setDoctorStatus(data.doctors);
                }
                fetchDr();
                console.log(doctorStatus)
            } catch (error) {
                console.log("ERROR Put status", error);
            }
        }
    },[doctorStatus.is_available]);

    const [classStatus, setClassStatus] = useState({
        status:""
    })

    //Object Destructuring
    const { last_name, id } = userData;
    const { is_available } = doctorStatus;
    
    const setAvailable = () => {
        if (is_available== true){
            setDoctorStatus({
                is_available: false
            });
        }
        else{
            setDoctorStatus({
                is_available: true
            });
        }
    }

    return (
        <>
            {last_name === undefined ? (
            <div>
                <Loading />
            </div>
        ) : (
          <div className={style.container}>
            <Header2  userType={userType} page={"doctor"} />
            <Grid container direction="column" className={styles.contDoctor}>
                <Grid container direction="row" justify="flex-start" xs={12}>
                    <h1 className={style.titulo}>{t('doctor.subtitle.welcome')}{`${last_name || ''}`}</h1>        
                </Grid>
                <Grid container direction="row" justify="flex-start" xs={12}>
                    <Grid item xs={12} sm={6} direction="row">
                        {is_available ? (
                            <div className={style.status}>
                                <h2 className={style.sub}>{t('doctor.subtitle.status.available')}</h2>
                                <FiberManualRecordIcon fontSize="large" style={{ color:'#3F9947' }}/>
                            </div>
                        ) : (
                            <div className={style.status}>
                                <h2 className={style.sub}>{t('doctor.subtitle.status.unavailable')}</h2>
                                <FiberManualRecordIcon fontSize="large" style={{ color:'#B4B4B4' }}/>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} direction="row" className={styles.tableDesact}>
                        <button id="available" className={style.botonAvailable} onClick={() => setAvailable(is_available) }>{t('doctor.button.switch')}</button>
                    </Grid>
                </Grid>
                <div className={style.patientstab}>
                    <h1 className={style.patients}>{t('doctor.title.patient.line')}</h1>
                </div>
                <div className={style.positiontable}>
                <DoctorTable userType={userType} auth={useAuth0} userid={`${id}`}/>
              </div>
            </Grid>
          </div>
        )}  
        </>
    );
}

export default Doctor;
