import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from '@material-ui/core/styles';
//import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import style from '../../styles/RequestCall.module.css'
import TextField from '@material-ui/core/TextField';
import { useAuth0 } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";


function RequestCall(props) {
    const [t, i18n] =useTranslation("home");
    const useStyles = makeStyles({
        inputAnswer:{
            width: "100%",
            height: "65px",
            backgroundColor: "#F8F8F8",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            marginLeft: .2+ "rem",
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "Lucida Grande",
            disableUnderline: true,
        }

    })
    const classes = useStyles();

    const [reasonInfo, setReasonInfo] = useState(null)

    const handleReason = e => {
        setReasonInfo({
            ...reasonInfo,
            [e.target.name]:e.target.value
        })
        console.log("HANDLING ==> reason",e.target.value,reasonInfo)
    }

    //On click continue
    const onSubmitForm = (e) => {
        e.preventDefault();
        let prevUser = JSON.parse(localStorage.getItem('user_info'))
        let prevUserInfo = prevUser.user
        let responseMed = {
            reason: {
                reason_info: reasonInfo
            }
        }
        let storedSession = JSON.parse(localStorage.getItem('auth:auth0'));
        const address = `https://cekgbqhama.execute-api.us-west-2.amazonaws.com/dev/api/v1/`;
        fetch(address+'user/med-info',{
            method:'PATCH',
            headers:{
                Authorization: `Bearer ${storedSession.idToken}`,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            },
            body:JSON.stringify(responseMed)
        })
        .then( response => response.json())
        .then(result =>{
            console.log("Update User Med Info", result)
        })
        .catch(error =>{
            console.log("ERROR ?",error)
        })
        async function Push() {
            window.location.href = '/user/payment';
        }
        setTimeout(() => {
            Push()
          }, 1000);
    }
    

    return (
      <Modal
        {...props}
        size="l"
        dialogClassName={style.dialog}
        contentClassName= {style.custom}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t('request.call.title.please')}</h4>
          <form onSubmit={onSubmitForm}>
            <h4 className={style.subtitulo}><TextField multiline placeholder={t('register.cont.reason')} required name="reasonInfo" 
            InputProps={{ readOnly: false, disableUnderline: true, className: classes.inputAnswer }} 
            className={classes.inputAnswer} onChange={handleReason}/></h4>
            <div className={style.justify}>
                <Button className={style.buton} type="submit">{t('request.call.button.continue')}</Button>
                </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
  
export default RequestCall;