import React from 'react';
import Modal from "react-bootstrap/Modal";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import {useTranslation} from "react-i18next";

import style from '../../styles/Disclaimer.module.css';

function Disclaimer(props) {
    const [t, i18n] =useTranslation("home");

    return (
      <Modal
        {...props}
        contentClassName= {style.custom}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t("register.title.modal")}</h4>
          <p className={style.parrafo}>{t("register.cont.modal.1")}</p>
          <p className={style.parrafo}>{t("register.cont.modal.2")}</p>
          <p className={style.parrafo}>{t("register.cont.modal.3")}</p>
          <p className={style.parrafo}>{t("register.cont.modal.4")}</p>
          <div className={style.justify}><Button className={style.buton} onClick={props.onHide}>{t("register.button.modal")}</Button></div>
        </Modal.Body>
      </Modal>
    );
  }
  
export default Disclaimer;