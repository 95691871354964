import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from '@material-ui/core/styles';
//import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from 'react-bootstrap';
import style from '../styles/Disclaimer.module.css'
import TextField from '@material-ui/core/TextField';
import {useTranslation} from "react-i18next";

function PrivacyPolicy(props) {
    const [t, i18n] =useTranslation("home");
    const useStyles = makeStyles({
        inputAnswer:{
            width:"100%",
            height: "65px",
            backgroundColor: "#F8F8F8",
            border: "none",
            borderRadius: "6px 6px 6px 6px",
            marginLeft: .2+ "rem",
            textAlign: "center",
            fontSize: 15 + "px",
            fontFamily: "Lucida Grande",
            disableUnderline: true,
            'padding-left':"10px",
            'padding-right':"10px"
        }

    })
    const classes = useStyles();



    

    return (
      <Modal
        {...props}
        dialogClassName={style.dialog}
        contentClassName= {style.custom}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className={style.titulo}>{t('footer.cont.privacy')}</h4>
          <p>{t('privacy.1')}</p>
          <p>{t('privacy.2')}</p>
          <p>{t('privacy.3')}</p>
          <p>{t('privacy.4')}</p>
          <p>{t('privacy.5')}</p>
          <p>{t('privacy.6')}</p>
          <p>{t('privacy.7')}</p>
          <p>{t('privacy.8')}</p>
          <p>{t('privacy.9')}</p>
          <p>{t('privacy.10')}</p>
          <p>{t('privacy.11')}</p>
          <p>{t('privacy.12')}</p>
          <p>{t('privacy.13')}</p>
          <p>{t('privacy.14')}</p>
          <p>{t('privacy.15')}</p>
          <p>{t('privacy.16')}</p>
          <p>{t('privacy.17')}</p>
          <p>{t('privacy.18')}</p>
          <p>{t('privacy.19')}</p>
          <p>{t('privacy.20')}</p>
          <p>{t('privacy.21')}</p>
          <ul>
            <li>{t('privacy.21.list.a')}</li>
            <li>{t('privacy.21.list.b')}</li>
            <li>{t('privacy.21.list.c')}</li>
            <li>{t('privacy.21.list.d')}</li>
            <li>{t('privacy.21.list.e')}</li>
            <li>{t('privacy.21.list.f')}</li>
          </ul>
          <p>{t('privacy.22')}</p>
          <p>{t('privacy.23')}</p>
          <p>{t('privacy.24')}</p>
          <p>{t('privacy.25')}</p>
          <p>{t('privacy.26')}</p>
          <p>{t('privacy.27')}</p>
          <p>{t('privacy.28')}</p>
          <p>{t('privacy.29')}</p>
          <p>{t('privacy.30')}</p>
          <p>{t('privacy.31')}</p>
          <p>{t('privacy.32')}</p>
          <p>{t('privacy.33')}</p>
          <p>{t('privacy.34')}</p>
          <p>{t('privacy.35')}</p>
          <p>{t('privacy.36')}</p>
          <p>{t('privacy.37')}</p>
          <ul>
            <li>{t('privacy.37.list.a')}</li>
            <li>{t('privacy.37.list.b')}</li>
            <li>{t('privacy.37.list.c')}</li>
          </ul>
          <p>{t('privacy.38')}</p>
          <p>{t('privacy.39')}</p>
          <p>{t('privacy.40')}</p>
          <p>{t('privacy.41')}</p>
          <p>{t('privacy.42')}</p>
          <p>{t('privacy.43')}</p>
          <ul>
            <li>{t('privacy.43.list.a')}</li>
            <li>{t('privacy.43.list.b')}</li>
            <li>{t('privacy.43.list.c')}</li>
            <li>{t('privacy.43.list.d')}</li>
            <li>{t('privacy.43.list.e')}</li>
            <li>{t('privacy.43.list.f')}</li>
            <li>{t('privacy.43.list.g')}</li>
            <li>{t('privacy.43.list.h')}</li>
            <li>{t('privacy.43.list.i')}</li>
            <li>{t('privacy.43.list.j')}</li>
          </ul>
          <p>{t('privacy.44')}</p>
          <p>{t('privacy.45')}</p>
          <ul>
            <li>{t('privacy.45.list.a')}</li>
            <li>{t('privacy.45.list.b')}</li>
            <li>{t('privacy.45.list.c')}</li>
            <li>{t('privacy.45.list.d')}</li>
            <li>{t('privacy.45.list.e')}</li>
            <li>{t('privacy.45.list.f')}</li>
          </ul>
          <p>{t('privacy.46')}</p>
          <p>{t('privacy.47')}</p>
          <p>{t('privacy.48')}</p>
          <p>{t('privacy.49')}</p>
          <p>{t('privacy.50')}</p>
          <p>{t('privacy.51')}</p>
          <p>{t('privacy.52')}</p>
          <p>{t('privacy.53')}</p>
          <p>{t('privacy.54')}</p>
          <p>{t('privacy.55')}</p>
          <p>{t('privacy.56')}</p>
          <p>{t('privacy.57')}</p>
          <p>{t('privacy.58')}</p>
          <p>{t('privacy.59')}</p>
          <p>{t('privacy.60')}</p>
          <p>{t('privacy.61')}</p>
          <p>{t('privacy.62')}</p>
          <p>{t('privacy.63')}</p>
          <p>{t('privacy.64')}</p>
          <p>{t('privacy.65')}</p>
          <p>{t('privacy.66')}</p>
          <p>{t('privacy.67')}</p>
          <ul>
            <li>{t('privacy.67.list.a')}</li>
            <li>{t('privacy.67.list.b')}</li>
            <li>{t('privacy.67.list.c')}</li>
            <li>{t('privacy.67.list.d')}</li>
          </ul>
          <p>{t('privacy.68')}</p>
          <p>{t('privacy.69')}</p>
          <p>{t('privacy.70')}</p>
          <ul>
            <li>{t('privacy.70.list.a')}</li>
          </ul>
          <p>{t('privacy.71')}</p>
          <p>{t('privacy.72')}</p>
          <p>{t('privacy.73')}</p>
          <p>{t('privacy.74')}</p>
          <ul>
            <li>{t('privacy.74.list.a')}</li>
            <li>{t('privacy.74.list.b')}</li>
            <li>{t('privacy.74.list.c')}</li>
            <li>{t('privacy.74.list.d')}</li>
          </ul>
          <p>{t('privacy.75')}</p>
          <ul>
            <li>{t('privacy.75.list.a')}</li>
            <li>{t('privacy.75.list.b')}</li>
            <li>{t('privacy.75.list.c')}</li>
            <li>{t('privacy.75.list.d')}</li>
            <li>{t('privacy.75.list.e')}</li>
            <li>{t('privacy.75.list.f')}</li>
            <li>{t('privacy.75.list.g')}</li>
            <li>{t('privacy.75.list.h')}</li>
            <li>{t('privacy.75.list.i')}</li>
            <li>{t('privacy.75.list.j')}</li>
          </ul>
          <p>{t('privacy.76')}</p>
          <p>{t('privacy.77')}</p>
          <p>{t('privacy.78')}</p>
          <p>{t('privacy.79')}</p>
          <p>{t('privacy.80')}</p>
          <p>{t('privacy.81')}</p>
          <ul>
            <li>{t('privacy.81.list.a')}</li>
            <li>{t('privacy.81.list.b')}</li>
            <li>{t('privacy.81.list.c')}</li>
            <li>{t('privacy.81.list.d')}</li>
          </ul>
          <p>{t('privacy.82')}</p>
          <p>{t('privacy.83')}</p>
          <p>{t('privacy.84')}</p>
          <ul>
            <li>{t('privacy.84.list.a')}</li>
            <li>{t('privacy.84.list.b')}</li>
            <li>{t('privacy.84.list.c')}</li>
            <li>{t('privacy.84.list.d')}</li>
            <li>{t('privacy.84.list.e')}</li>
          </ul>
          <p>{t('privacy.85')}</p>
          <p>{t('privacy.86')}</p>
          <p>{t('privacy.87')}</p>
          <p>{t('privacy.88')}</p>
          <p>{t('privacy.89')}</p>
          <p>{t('privacy.90')}</p>
          <p>{t('privacy.91')}</p>
          <p>{t('privacy.92')}</p>
          <p>{t('privacy.93')}</p>
          <p>{t('privacy.94')}</p>
          <p>{t('privacy.95')}</p>
          <p>{t('privacy.96')}</p>
          <p>{t('privacy.97')}</p>
          <ul>
            <li>{t('privacy.97.list.a')}</li>
            <li>{t('privacy.97.list.b')}</li>
            <li>{t('privacy.97.list.c')}</li>
            <li>{t('privacy.97.list.d')}</li>
            <li>{t('privacy.97.list.e')}</li>
            <li>{t('privacy.97.list.f')}</li>
            <li>{t('privacy.97.list.g')}</li>
            <li>{t('privacy.97.list.h')}</li>
          </ul>
          <p>{t('privacy.98')}</p>
          <p>{t('privacy.99')}</p>
          <ul>
            <li>{t('privacy.99.list.a')}</li>
            <li>{t('privacy.99.list.b')}</li>
            <li>{t('privacy.99.list.c')}</li>
          </ul>
          <p>{t('privacy.100')}</p>
          <p>{t('privacy.101')}</p>
          <p>{t('privacy.102')}</p>
          <p>{t('privacy.103')}</p>
          <p>{t('privacy.104')}</p>
          <p>{t('privacy.105')}</p>
          <p>{t('privacy.106')}</p>
          <p>{t('privacy.107')}</p>
          <p>{t('privacy.108')}</p>
          <p>{t('privacy.109')}</p>
          <p>{t('privacy.110')}</p>
          <p>{t('privacy.111')}</p>
          <p>{t('privacy.112')}</p>
          <p>{t('privacy.113')}</p>
          <p>{t('privacy.114')}</p>
          <p>{t('privacy.115')}</p>
          <p>{t('privacy.116')}</p>
          <p>{t('privacy.117')}</p>
          <ul>
            <li>{t('privacy.117.list.a')}</li>
            <li>{t('privacy.118.list.b')}</li>
            <li>{t('privacy.119.list.c')}</li>
            <li>{t('privacy.120.list.d')}</li>
            <li>{t('privacy.121.list.e')}</li>
          </ul>
          <p>{t('privacy.122')}</p>
          <p>{t('privacy.123')}</p>
          <p>{t('privacy.124')}</p>
          <p>{t('privacy.125')}</p>
          <p>{t('privacy.126')}</p>
          <p>{t('privacy.127')}</p>
          <p>{t('privacy.128')}</p>
          <p>{t('privacy.129')}</p>
          <p>{t('privacy.130')}</p>
          <p>{t('privacy.131')}</p>
          <ul>
            <li>{t('privacy.131.list.a')}</li>
            <li>{t('privacy.131.list.b')}</li>
            <li>{t('privacy.131.list.c')}</li>
            <li>{t('privacy.131.list.d')}</li>
          </ul>
          <p>{t('privacy.132')}</p>
          <p>{t('privacy.133')}</p>
          <p>{t('privacy.134')}</p>
          <p>{t('privacy.135')}</p>
          <p>{t('privacy.136')}</p>
          <p>{t('privacy.137')}</p>
          <p>{t('privacy.138')}</p>
          <p>{t('privacy.139')}</p>
          <p>{t('privacy.140')}</p>
          <p>{t('privacy.141')}</p>
          <p>{t('privacy.142')}</p>
          <p>{t('privacy.143')}</p>
          <ul>
            <li>{t('privacy.143.list.a')}</li>
            <li>{t('privacy.143.list.b')}</li>
            <li>{t('privacy.143.list.c')}</li>
            <li>{t('privacy.143.list.d')}</li>
            <li>{t('privacy.143.list.e')}</li>
          </ul>
          <p>{t('privacy.144')}</p>
          <p>{t('privacy.145')}</p>
          <p>{t('privacy.146')}</p>
          <p>{t('privacy.147')}</p>
          <p>{t('privacy.148')}</p>
          <p>{t('privacy.149')}</p>
          <p>{t('privacy.150')}</p>
          <p>{t('privacy.151')}</p>
          <p>{t('privacy.152')}</p>
          <p>{t('privacy.153')}</p>
          <p>{t('privacy.154')}</p>
          <p>{t('privacy.155')}</p>
          <p>{t('privacy.156')}</p>

        </Modal.Body>
      </Modal>
    );
  }
  
export default PrivacyPolicy;