// src/app.js

import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { NavBar, Footer, Loading } from "./components";
import { Home, Profile, ExternalApi } from "./views";
import UserProfile from "./views/UserProfile";
import Register from "./views/register";
import Payment from "./views/payment";
import Book from "./views/book";
import Doctor from "./views/doctor";
import HistoryAdmin from "./views/history-a";
import HistoryRegular from "./views/history-a";
import Service from "./views/service";
import Addendum from "./views/addendum";
import Progress from "./views/progress";
import Callback from "./callback";
import ProtectedRoute from "./auth/protected-route";

import './styles/globals.css'

import "./app.css";

const App = () => {
  const { isLoading, isAuthenticated, getIdTokenClaims, user } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  


  return (
    <div id="app" className="d-flex flex-column h-100">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/callback" component={Callback} />
        <Route path="/user/profile" component={UserProfile} />
        <Route path="/user/register" component={Register} />
        <Route path="/book" component={Book} />
        <Route path="/doctor" component={Doctor} />
        <Route path="/history-a" component={HistoryAdmin} />
        <Route path="/history-b" component={HistoryRegular} />
        <Route path="/user/payment" component={Payment} />
        <Route path="/service" component={Service} />
        <Route path="/addendum" component={Addendum} />
        <Route path="/progress" component={Progress} />
      </Switch>
    </div>
  );
};

export default App;
