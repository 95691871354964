// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import {I18nextProvider} from "react-i18next";
import i18next from"i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

import translationHomeEn from "./translation/en/home.json"; 
import translationHomeEs from "./translation/es/home.json"; 

i18next
.use(LanguageDetector)
.init({
  fallbackLng:'en',
  debug:true,
  resources:{
    es:{
      home:translationHomeEs
    },
    en:{
      home:translationHomeEn
    }
  }
});

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById("root")
);